import { FC } from "react";
import { Row, Text } from "@hightouchio/ui";

import { commaNumber } from "src/utils/numbers";
import { IconProps } from "src/ui/icons/icon";

export const NumericValue = ({
  value,
  label,
  icon: HeaderIcon,
}: {
  value: number;
  label?: string;
  icon?: FC<IconProps>;
}) => {
  return (
    <Row fontSize="lg" alignItems="center">
      {HeaderIcon && <HeaderIcon color="text.secondary" />}
      <Text size="sm" color="text.secondary">
        {commaNumber(value)} {label}
      </Text>
    </Row>
  );
};
