import { useEffect, type FC } from "react";

import {
  Alert,
  AudienceIcon,
  CloseIcon,
  Column,
  Combobox,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  ExternalLinkIcon,
  FormField,
  IconButton,
  Paragraph,
  Radio,
  RadioGroup,
  Row,
  Tooltip,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFlags } from "launchdarkly-react-client-sdk";
import merge from "lodash/merge";
import omit from "lodash/omit";
import noop from "lodash/noop";
import { Controller, useFormContext } from "react-hook-form";
import { Link } from "src/router";

import { Card } from "src/components/card";
import { isAndOrCondition } from "src/components/explore/utils/type-guards";
import { ErrorMessage } from "src/components/explore/visual/error-message";
import { EventFilter } from "src/components/explore/visual/event-filter";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { IconBox } from "src/components/icon-box";
import { PermissionedEditableHeading } from "src/components/permission";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import type {
  JourneyNodeDetails,
  NodeDetailFormProps,
} from "src/pages/journeys/types";
import {
  JourneyNodeType,
  type EntryCohortConfig,
  type EntryEventConfig,
} from "src/types/journeys";
import {
  type Audience,
  type EventCondition,
  ConditionType,
  initialEventCondition,
} from "src/types/visual";

import { StartNodeSchema } from "./validation-schemas";
import { useFormErrorContext } from "src/contexts/form-error-context";
import { FieldError } from "src/components/field-error";

type StartFormState = {
  entryMethod: "event" | "audience";
};

export const StartForm: FC<
  NodeDetailFormProps<EntryCohortConfig | EntryEventConfig>
> = ({
  id, // id techincally lives in data too, redundant to have both :/
  data,
  onClose,
}) => {
  const { enableJourneysEventBasedEntry } = useFlags();
  const {
    isEditMode,
    numberOfPreviousRuns,
    unauthorizedTooltip,
    updateJourneyPermission,
    onUpdateNode,
  } = useGraphContext();
  const { hasValidationErrors } = useFormErrorContext();

  const form = useHightouchForm<
    JourneyNodeDetails<EntryCohortConfig | EntryEventConfig> & StartFormState
  >({
    onSubmit: (entryNodeData) => {
      if (hasValidationErrors()) {
        throw new Error("Form has validation errors");
      }

      if (entryNodeData.config.type === JourneyNodeType.EntryEvent) {
        onUpdateNode(id, entryNodeData);
      } else if (entryNodeData.config.type === JourneyNodeType.EntryCohort) {
        const config = entryNodeData.config;
        onUpdateNode(id, {
          ...entryNodeData,
          config: merge(
            {
              type: JourneyNodeType.EntryCohort,
              max_num_entries: config.max_num_entries,
            },
            omit(config, ["event_conditions", "max_simultaneous_entries"]),
          ),
        });
      }

      onClose();

      return Promise.resolve();
    },
    success: "Tile was saved",
    values: data,
    resolver: yupResolver(StartNodeSchema),
  });

  const resetConfig = () => {
    form.setValue("segment_id", null);
    form.setValue("event_relationship_id", null);
    form.setValue("config.event_conditions", {
      type: ConditionType.And,
      conditions: [],
    });
    form.setValue("config.max_simultaneous_entries", 0);
  };

  useEffect(() => {
    if (!enableJourneysEventBasedEntry) {
      form.setValue("config.type", JourneyNodeType.EntryCohort);
    }
  }, [enableJourneysEventBasedEntry]);

  const entryMethod = form.watch("config.type");

  const isEventBasedEntry =
    enableJourneysEventBasedEntry && entryMethod === JourneyNodeType.EntryEvent;

  return (
    <Form form={form}>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <Controller
            name="name"
            control={form.control}
            render={({ field, fieldState }) => (
              <Column>
                <PermissionedEditableHeading
                  isDisabled={!isEditMode}
                  permission={updateJourneyPermission}
                  unauthorizedTooltip={unauthorizedTooltip}
                  value={field.value}
                  onChange={field.onChange}
                />
                {fieldState.error?.message && (
                  <ErrorMessage fontWeight="normal">
                    {fieldState.error.message}
                  </ErrorMessage>
                )}
              </Column>
            )}
          />
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody bg="base.lightBackground">
        <Column minHeight={0} flex={1} gap={6} pb={4}>
          {enableJourneysEventBasedEntry && (
            <Card>
              <FormField
                label="Entry method"
                description="How users will enter the journey"
              >
                <Controller
                  name="config.type"
                  control={form.control}
                  render={({ field }) => (
                    <RadioGroup
                      mt={4}
                      isDisabled={!isEditMode || numberOfPreviousRuns > 0}
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                        resetConfig();
                      }}
                    >
                      <Radio
                        label="Event occurs"
                        description="Users enter the journey when they perform a specific event"
                        value={JourneyNodeType.EntryEvent}
                        tooltip={
                          numberOfPreviousRuns > 0 && isEditMode
                            ? {
                                message:
                                  "Journey entry method cannot be modified once the journey has already ran",
                              }
                            : undefined
                        }
                      />
                      <Radio
                        label="Enters audience"
                        description="Users enter the journey when they join a specific Hightouch audience"
                        value={JourneyNodeType.EntryCohort}
                        tooltip={
                          numberOfPreviousRuns > 0 && isEditMode
                            ? {
                                message:
                                  "Journey entry method cannot be modified once the journey has already ran",
                              }
                            : undefined
                        }
                      />
                    </RadioGroup>
                  )}
                />
              </FormField>
            </Card>
          )}

          {isEventBasedEntry ? <EventEntryForm /> : <AudienceEntryForm />}

          <Card>
            <Controller
              control={form.control}
              name="config.max_num_entries"
              render={({ field, fieldState }) => (
                <FormField
                  label="Re-entry"
                  description="Can users enter the journey more than once?"
                  error={fieldState.error?.message && "Re-entry is required"}
                >
                  <RadioGroup
                    isDisabled={!isEditMode}
                    orientation="vertical"
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                      if (value === 1) {
                        form.setValue("config.max_simultaneous_entries", 0);
                      }
                    }}
                  >
                    {/* TODO(samuel): reset simultaneous entries when changing this value back */}
                    <Radio label="Only one entry" value={1} />
                    <Radio label="Unlimited entries" value={-1} />
                  </RadioGroup>
                </FormField>
              )}
            />
          </Card>

          {isEventBasedEntry && form.watch("config.max_num_entries") === -1 && (
            <Card>
              <FormField
                label="Simultaneous entries"
                description="Can users be in the journey multiple times at once?"
              >
                <Controller
                  name="config.max_simultaneous_entries"
                  control={form.control}
                  render={({ field }) => (
                    <RadioGroup
                      isDisabled={!isEditMode}
                      orientation="vertical"
                      value={field.value}
                      onChange={field.onChange}
                    >
                      <Radio
                        label="User must finish the journey before starting again"
                        value={0}
                      />
                      <Radio
                        label="User can be in the journey unlimited times simultaneously"
                        value={-1}
                      />
                    </RadioGroup>
                  )}
                />
              </FormField>
            </Card>
          )}
        </Column>
      </DrawerBody>

      {isEditMode && (
        <DrawerFooter>
          <SaveButton permission={updateJourneyPermission}>Update</SaveButton>
        </DrawerFooter>
      )}
    </Form>
  );
};

const AudienceEntryForm: FC = () => {
  const { audienceOptions, audiencesLoading, isEditMode } = useGraphContext();
  const form = useFormContext<JourneyNodeDetails<EntryCohortConfig>>();

  return (
    <>
      <Card>
        <Controller
          name="segment_id"
          control={form.control}
          render={({ field, fieldState }) => (
            <FormField
              label="Entry audience"
              description="Users will enter the journey as soon as they enter this audience"
              error={fieldState.error?.message}
            >
              <Row gap={2}>
                <Combobox
                  isDisabled={!isEditMode}
                  isInvalid={Boolean(fieldState.error?.message)}
                  placeholder="Select an audience..."
                  isLoading={audiencesLoading}
                  optionAccessory={() => ({
                    type: "icon",
                    icon: () => (
                      <IconBox
                        bg="ocean.400"
                        boxSize="20px"
                        icon={<AudienceIcon />}
                        iconSize="14px"
                      />
                    ),
                  })}
                  options={audienceOptions}
                  value={field.value?.toString()}
                  width="100%"
                  optionValue={(option) => option.id.toString()}
                  optionLabel={(option) => option.name}
                  onChange={field.onChange}
                />
                {field.value && (
                  <Link href={`/audiences/${field.value}`} isExternal>
                    <Tooltip message="View audience">
                      <IconButton
                        icon={ExternalLinkIcon}
                        aria-label="Link to selected audience"
                        variant="tertiary"
                        onClick={noop}
                      />
                    </Tooltip>
                  </Link>
                )}
              </Row>
            </FormField>
          )}
        />
      </Card>
    </>
  );
};

const EventEntryForm: FC = () => {
  const { parentModel } = useGraphContext();
  const form = useFormContext<JourneyNodeDetails<EntryEventConfig>>();

  const segmentId = form.watch("segment_id");
  const segmentIdError = form.formState.errors.segment_id;
  const eventRelationshipId = form.watch("event_relationship_id");

  return (
    <>
      <Card gap={4}>
        <FormField
          label="Entry event"
          description="Users will enter the journey as soon as they perform this event"
        >
          <Column p={4} gap={4} bg="gray.100" borderRadius="md">
            <Controller
              control={form.control}
              name="config.event_conditions"
              render={({ field }) => (
                <EventFilter
                  hideFunnelCondition
                  hideOperatorFilter
                  hideWindowCondition
                  showOnlyEventOptions
                  disableEventsWithoutPrimaryKey
                  audience={{} as Audience}
                  condition={
                    segmentId && eventRelationshipId
                      ? ({
                          type: ConditionType.Event,
                          eventModelId: Number(segmentId),
                          relationshipId: Number(eventRelationshipId),
                          // Should always be true, but narrowing for ts sake
                          subconditions: isAndOrCondition(field.value)
                            ? field.value.conditions
                            : [],
                        } as unknown as EventCondition)
                      : initialEventCondition
                  }
                  parent={parentModel}
                  onChange={({
                    eventModelId,
                    relationshipId,
                    subconditions,
                  }) => {
                    form.setValue(
                      "event_relationship_id",
                      // Note: these _are_ numbers. The type says string but it is incorrect.
                      relationshipId === null
                        ? null
                        : (Number(relationshipId) as unknown as string),
                    );
                    form.setValue(
                      "segment_id",
                      // Note: these _are_ numbers. The type says string but it is incorrect.
                      eventModelId === null
                        ? null
                        : (Number(eventModelId) as unknown as string),
                    );

                    // This is an array, but will be a single element array
                    field.onChange(
                      subconditions?.[0] ?? {
                        type: ConditionType.And,
                        conditions: [],
                      },
                    );
                  }}
                />
              )}
            />
          </Column>
          <FieldError error={segmentIdError?.message} />
        </FormField>
        <Alert
          type="subtle"
          variant="inline"
          title="Only events with assigned primary keys can be used"
          message={
            <Paragraph>
              If you don’t see the event you’re looking for, ask an admin to
              assign it a primary key in the{" "}
              <Link
                isExternal
                href={`/schema-v2?source=${parentModel?.connection?.id}`}
              >
                Schema
              </Link>{" "}
              tab.
            </Paragraph>
          }
        />
      </Card>
    </>
  );
};
