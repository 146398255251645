import { FC, useCallback } from "react";

import { useNavigate } from "src/router";
import { DetailPage } from "src/components/layout";
import { PermissionedLinkButton } from "src/components/permission";
import { Table } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { openUrl } from "src/utils/urls";
import genericPlaceholder from "src/assets/placeholders/generic.svg";

import { useFunctionsQuery } from "src/graphql";

export const FunctionsOverview: FC = () => {
  const navigate = useNavigate();

  const { data } = useFunctionsQuery(
    {},
    {
      select: (data) => data.functions,
      suspense: true,
    },
  );

  const onRowClick = useCallback(
    ({ id }, event) => openUrl(`/events/functions/${id}`, navigate, event),
    [navigate],
  );

  // Unexpected case since we are using suspense, but makes TS happy
  if (!data) return null;

  return (
    <DetailPage
      heading="Event functions"
      size="full"
      actions={
        <PermissionedLinkButton
          permission={{
            v1: { resource: "workspace", grant: "update" },
            v2: {
              resource: "workspace",
              grant: "can_update",
            },
          }}
          variant="primary"
          href="new"
        >
          Create function
        </PermissionedLinkButton>
      }
    >
      <Table
        columns={[
          {
            name: "Name",
            cell: ({ name }) => {
              return (
                <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
              );
            },
          },
          {
            name: "Description",
            cell: ({ description }) => {
              return <TextWithTooltip>{description || "--"}</TextWithTooltip>;
            },
          },
          {
            name: "Connected syncs",
            cell: ({ function_resources_aggregate: { aggregate } }) => {
              return <TextWithTooltip>{aggregate?.count || 0}</TextWithTooltip>;
            },
          },
          LastUpdatedColumn,
        ]}
        data={data}
        onRowClick={onRowClick}
        placeholder={{
          image: genericPlaceholder,
          title: "No functions yet...",
          body: "Functions can be used to transform event data before syncing it to a destination.",
          error: "Functions failed to load, please try again.",
        }}
      />
    </DetailPage>
  );
};
