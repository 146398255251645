import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  AudienceIcon,
  Badge,
  BadgeProps,
  DbtIcon,
  MergeIcon,
  ModelIcon,
  SqlIcon,
  TableIcon,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { differenceBy } from "lodash";
import { useQueryClient } from "react-query";
import type { MarkOptional } from "ts-essentials";
import { v4 as uuid } from "uuid";

import { CustomQuery } from "src/components/sources/forms/custom-query";
import { useDraft } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import {
  BackgroundJobResult,
  ModelColumnInput,
  PreviewQuerySchemaQuery,
  QueryResponse,
  ResourceToPermission,
  RunCustomBackgroundQuery,
  RunCustomBackgroundQueryVariables,
  RunDbtModelBackgroundQuery,
  RunDbtModelBackgroundQueryVariables,
  RunDecisionEngineBackgroundQuery,
  RunDecisionEngineBackgroundQueryVariables,
  RunJourneyNodeBackgroundQuery,
  RunJourneyNodeBackgroundQueryVariables,
  RunLookerLookBackgroundQuery,
  RunLookerLookBackgroundQueryVariables,
  RunSigmaBackgroundQuery,
  RunSigmaBackgroundQueryVariables,
  RunSqlBackgroundQuery,
  RunSqlBackgroundQueryVariables,
  RunTableBackgroundQuery,
  RunTableBackgroundQueryVariables,
  RunVisualBackgroundQuery,
  RunVisualBackgroundQueryVariables,
  SegmentsInsertInput,
  SegmentsSetInput,
  SuccessfulQueryResponse,
  useDeleteModelColumnsMutation,
  usePreviewQuerySchemaQuery,
  useRunCustomBackgroundQuery,
  useRunDbtModelBackgroundQuery,
  useRunDecisionEngineBackgroundQuery,
  useRunJourneyNodeBackgroundQuery,
  useRunLookerLookBackgroundQuery,
  useRunSigmaBackgroundQuery,
  useRunSqlBackgroundQuery,
  useRunSqlResultQuery,
  useRunTableBackgroundQuery,
  useRunVisualBackgroundQuery,
  useUpdateAudienceSubsetsMutation,
  useUpdateQueryMutation,
  useVisualQueryBackgroundResultQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { QueryType, Sigma } from "src/types/models";
import { ColumnType, VisualQueryFilter } from "src/types/visual";
import {
  FilterableColumnFragment,
  Scalars,
  QueryColumn,
} from "../graphql/types";

export const QueryTypeDictionary: Record<QueryType, string> = {
  [QueryType.RawSql]: "SQL",
  [QueryType.Visual]: "Visual",
  [QueryType.Table]: "Table",
  [QueryType.DbtModel]: "dbt Model",
  [QueryType.LookerLook]: "Looker Look",
  [QueryType.Sigma]: "Sigma",
  [QueryType.Custom]: "Custom",
  [QueryType.JourneyNode]: "Journey",
  [QueryType.DecisionEngine]: "Decision Engine",
};

export const ASYNC_RUN_QUERY = {
  [QueryType.RawSql]: useRunSqlBackgroundQuery,
  [QueryType.Visual]: useRunVisualBackgroundQuery,
  [QueryType.Table]: useRunTableBackgroundQuery,
  [QueryType.DbtModel]: useRunDbtModelBackgroundQuery,
  [QueryType.LookerLook]: useRunLookerLookBackgroundQuery,
  [QueryType.Sigma]: useRunSigmaBackgroundQuery,
  [QueryType.Custom]: useRunCustomBackgroundQuery,
  [QueryType.JourneyNode]: useRunJourneyNodeBackgroundQuery,
  [QueryType.DecisionEngine]: useRunDecisionEngineBackgroundQuery,
};

// Error type used to signal that a column deletion error occurred, so the frontend
// can handle it specially.
export class DeleteColumnsError extends Error {
  deletedColumns: string[];

  constructor(
    message: string,
    options: ErrorOptions & { deletedColumns: string[] },
  ) {
    super(message, options);
    this.deletedColumns = options.deletedColumns;
  }
}

export const EMPTY_AUDIENCE_DEFINITION: VisualQueryFilter = { conditions: [] };

function getVisualQueryFilter(
  filter?: VisualQueryFilter,
  options?: ModelRunOptions,
): Scalars["JSONObject"] {
  if (!filter) {
    return EMPTY_AUDIENCE_DEFINITION;
  }

  if (options?.includeMergedColumns) {
    return {
      ...filter,
      additionalColumns: [
        ...(filter.additionalColumns ?? []),
        ...(options.mergedColumns ?? []).map((col) => ({
          alias: `${col.model_name}:${col.name}`,
          column: col.column_reference,
        })),
      ],
    };
  }

  return filter;
}

export const getVariables = (model: ModelState, options?: ModelRunOptions) => {
  const type = model.query_type as QueryType;
  switch (type) {
    case QueryType.RawSql: {
      const variables: RunSqlBackgroundQueryVariables = {
        sourceId: String(model.connection?.id),
        sql: model.query_raw_sql ?? "",
        modelId: Number(model.id),
      };
      return variables;
    }
    case QueryType.Visual: {
      const filter = getVisualQueryFilter(
        model.visual_query_filter || undefined,
        options,
      );

      const variables: RunVisualBackgroundQueryVariables = {
        sourceId: String(model.connection?.id),
        parentModelId:
          model.visual_query_parent_id?.toString() ??
          model.parent?.id?.toString() ??
          "",
        filter,
        audienceId: model.id?.toString(),
        subsetIds:
          options?.useDefaultSubsets || !model.subsets?.length
            ? undefined
            : model.subsets.map((subset) => subset.subset_value.id.toString()),
        useDefaultSubsets: options?.useDefaultSubsets,
        useSampledModels: options?.useSampledModels,
        includeTraitDependencies: options?.includeTraitDependencies,
        destinationId: options?.destinationId?.toString(),
      };
      return variables;
    }
    case QueryType.Table: {
      const variables: RunTableBackgroundQueryVariables = {
        sourceId: String(model.connection?.id),
        table: model.query_table_name ?? "",
        modelId: Number(model.id),
      };
      return variables;
    }
    case QueryType.DbtModel: {
      const variables: RunDbtModelBackgroundQueryVariables = {
        sourceId: String(model.connection?.id),
        dbtModelId: Number(model.query_dbt_model_id),
        modelId: Number(model.id),
      };
      return variables;
    }
    case QueryType.LookerLook: {
      const variables: RunLookerLookBackgroundQueryVariables = {
        sourceId: String(model.connection?.id),
        lookId: model.query_looker_look_id ?? "",
        modelId: Number(model.id),
      };
      return variables;
    }
    case QueryType.Sigma: {
      const variables: RunSigmaBackgroundQueryVariables = {
        sourceId: String(model.connection?.id),
        elementId: model.query_integrations?.query?.elementId ?? "",
        workbookId: model.query_integrations?.query?.workbookId ?? "",
        modelId: Number(model.id),
      };
      return variables;
    }
    case QueryType.Custom: {
      const variables: RunCustomBackgroundQueryVariables = {
        sourceId: String(model.connection?.id),
        customQuery: model.custom_query,
        modelId: Number(model.id),
      };
      return variables;
    }
    case QueryType.JourneyNode: {
      const variables: RunJourneyNodeBackgroundQueryVariables = {
        sourceId: String(model.connection?.id),
        modelId: Number(model.id),
      };
      return variables;
    }
    case QueryType.DecisionEngine: {
      const variables: RunDecisionEngineBackgroundQueryVariables = {
        sqlOverride: model.query_raw_sql,
        sourceId: String(model.connection?.id),
        modelId: Number(model.id),
      };
      return variables;
    }
    default:
      throw new Error(`Unsupported query type ${type}`);
  }
};

type InnerBackgroundQueryResult =
  | RunVisualBackgroundQuery
  | RunSqlBackgroundQuery
  | RunTableBackgroundQuery
  | RunDbtModelBackgroundQuery
  | RunLookerLookBackgroundQuery
  | RunSigmaBackgroundQuery
  | RunCustomBackgroundQuery
  | RunDecisionEngineBackgroundQuery
  | RunJourneyNodeBackgroundQuery;

function getBackgroundInnerQueryResult(
  result: InnerBackgroundQueryResult,
): BackgroundJobResult {
  return Object.values(result)[0] as BackgroundJobResult;
}

export type ModelState = {
  query_type: string | null;
  id?: string | number;
  connection?: { id: string | number | null } | null;
  primary_key?: string | null;
  visual_query_parent_id?: string | number | null;
  parent?: {
    id: string | number | null;
  } | null;
  visual_query_filter?: VisualQueryFilter | null;
  visual_query_primary_label?: string | null;
  visual_query_secondary_label?: string | null;
  query_dbt_model_id?: number | null;
  query_looker_look_id?: string | null;
  query_table_name?: string | null;
  query_raw_sql?: string | null;
  query_integrations?: Sigma | null;
  custom_query?: CustomQuery | null;
  subsets?: Array<{ subset_value: { id: string } }>;
  is_streamable?: boolean | null;
  columns?: Array<{
    name: string;
    alias: string | null;
    disable_preview: boolean;
    redacted_text: string | null;
    type: string | null;
    raw_type: string | null;
    custom_type: string | null;
    metadata?: { properties: any } | null;
  }>;
  matchboosting_enabled?: boolean | null;
};

/**
 * When previewing a model in the partner flow, raw columns are used.
 * These do not have a model_id.
 *
 * In `useUpdateQuery`, the model_id is added to the column anyways, so it's optional here.
 */
export type UpdateQueryColumnType = Omit<ModelColumnInput, "model_id"> &
  Partial<Pick<ModelColumnInput, "model_id">>;

export const getModelInputFromState = (
  state: ModelState,
  includeSubsets = true,
): SegmentsInsertInput => {
  const input: SegmentsInsertInput = {
    query_type: state.query_type,
    connection_id: String(state.connection?.id),
    query_raw_sql: state.query_raw_sql,
    query_table_name: state.query_table_name,
    query_dbt_model_id: state.query_dbt_model_id,
    query_looker_look_id: state.query_looker_look_id,
    query_integrations: state.query_integrations,
    custom_query: state.custom_query,
    visual_query_filter: state.visual_query_filter,
    is_streamable: Boolean(state.is_streamable),
    primary_key: state.primary_key,
  };

  if (includeSubsets) {
    input.subsets = state.subsets
      ? {
          data: state.subsets.map(({ subset_value: { id } }) => ({
            subset_value_id: String(id),
          })),
        }
      : undefined;
  }

  return input;
};

type ModelRunOptions = {
  columns?: Array<{
    name: string;
    alias: string | null;
    disable_preview: boolean;
    redacted_text: string | null;
  }>;
  destinationId?: string;
  useDefaultSubsets?: boolean;
  useSampledModels?: boolean;
  includeTraitDependencies?: boolean;
  onCompleted?: (
    data: {
      columns?: Omit<QueryColumn, "__typename">[];
      rows?: any[];
    },
    error?: string,
  ) => void;
  mergedColumns?: FilterableColumnFragment[];
  includeMergedColumns?: boolean;
};

export const useModelRun = (
  model: Readonly<ModelState>,
  options?: ModelRunOptions,
) => {
  // Store the function to cancel the current query with the query's key.
  const cancelQueryRef = useRef<() => Promise<void>>();

  const client = useQueryClient();
  const { featureFlags } = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [backgroundJobId, setBackgroundJobId] = useState<string>();
  const [shouldPollForResults, setShouldPollForResults] =
    useState<boolean>(false);
  const [schemaLoading, setSchemaLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [page, setPage] = useState<number | undefined>();
  const [errorAtLine, setErrorAtLine] = useState<number | undefined>();
  const [runId, setRunId] = useState<string | undefined>("");
  const [result, setResult] = useState<{
    data?: MarkOptional<
      SuccessfulQueryResponse,
      "rows" | "numRowsWithoutLimit" | "sql" | "rowsCount"
    >;
    id: string;
  }>({ id: "" });
  const [transformedSql, setTransformedSql] = useState<string | undefined>();
  const initialColumns = useMemo(
    () => options?.columns ?? model.columns ?? [],
    [options?.columns, model?.columns],
  );

  useEffect(() => {
    // If we aren't doing async pagination or the user hasn't ran the query yet.
    if (!backgroundJobId || page === undefined || backgroundJobId !== runId) {
      return;
    }

    setLoading(true);
    setShouldPollForResults(true);
  }, [page, backgroundJobId, runId]);

  const cancelQuery = async () => {
    await cancelQueryRef.current?.();
    setRunId("");
    setLoading(false);
    setSchemaLoading(false);
    setError(undefined);
    setBackgroundJobId(undefined);
    setShouldPollForResults(false);
    setResult({
      ...result,
      data: undefined,
    });

    // reset cancel function.
    cancelQueryRef.current = undefined;
  };

  const getSchema = async () => {
    if (!model.query_type) {
      return {};
    }

    setSchemaLoading(true);
    const id = uuid();
    setRunId(id);

    const variables = {
      sourceId: String(model.connection!.id),
      queryType: model.query_type,
      query: getVariables(model, options),
    };

    const queryKey = usePreviewQuerySchemaQuery.getKey(variables);
    const queryFn = usePreviewQuerySchemaQuery.fetcher(variables);

    let error: Error | undefined = undefined;
    let data: (typeof result)["data"];

    try {
      const response = await client.fetchQuery<PreviewQuerySchemaQuery>(
        queryKey,
        {
          queryFn,
          // Disable caching the query results.
          cacheTime: 0,
        },
      );
      data = {
        rows: undefined,
        columns: response.previewQuerySchema.columns,
        exceedsPreviewMax: false,
        usedSampledModels: false,
        sampledModelsRate: 0.0,
      };
    } catch (e) {
      error = e;
      setError(e.message);
      setErrorAtLine(undefined);
    }

    setResult({ id, data });
    setSchemaLoading(false);

    return { data, error };
  };

  const resetRunState = () => {
    setRunId("");
    setLoading(false);
    setSchemaLoading(false);
    setError("");
    setResult({ id: "" });
  };

  useRunSqlResultQuery(
    {
      jobId: backgroundJobId ?? "",
      page: page ?? 0,
    },
    {
      enabled: Boolean(
        shouldPollForResults &&
          backgroundJobId &&
          model.query_type !== QueryType.Visual,
      ),
      keepPreviousData: true,
      refetchInterval: 500,
      onError: (error) => {
        Sentry.captureException(error);
        handleErrorResponse(error.message, backgroundJobId || "");
        setShouldPollForResults(false);
      },
      onSuccess: (data) => {
        if (!data?.backgroundPreviewQueryResult) {
          return;
        }

        handleSuccessResponse(
          data.backgroundPreviewQueryResult,
          backgroundJobId || "",
        );
        setShouldPollForResults(false);
      },
    },
  );

  useVisualQueryBackgroundResultQuery(
    {
      jobId: backgroundJobId ?? "",
      page: page ?? 0,
      filter: model.visual_query_filter || EMPTY_AUDIENCE_DEFINITION,
      parentModelId:
        model.visual_query_parent_id?.toString() ??
        model.parent?.id?.toString() ??
        "",
    },
    {
      enabled: Boolean(
        shouldPollForResults &&
          backgroundJobId &&
          model.query_type === QueryType.Visual,
      ),
      refetchInterval: 500,
      onError: (error) => {
        Sentry.captureException(error);
        handleErrorResponse(error.message, backgroundJobId || "");
        setShouldPollForResults(false);
      },
      onSuccess: (data) => {
        if (!data.visualQueryBackgroundResult) {
          return;
        }

        handleSuccessResponse(
          data.visualQueryBackgroundResult,
          backgroundJobId || "",
        );
        setShouldPollForResults(false);
      },
    },
  );

  const handleErrorResponse = (message: string, id: string) => {
    setError(message);
    setErrorAtLine(undefined);
    setLoading(false);
    setResult({ id, data: undefined });
    setTransformedSql(undefined);
  };

  const handleSuccessResponse = (responseResult: QueryResponse, id: string) => {
    setLoading(false);
    setTransformedSql(responseResult.sql || undefined);

    if (responseResult && responseResult.__typename === "FailedQueryResponse") {
      setError(responseResult.error);
      const lineNumber = responseResult.metadata?.line;
      if (lineNumber) {
        setErrorAtLine(lineNumber);
      }
      setResult({ id, data: undefined });
    } else {
      setErrorAtLine(undefined);
      setError(undefined);
      setResult({ id, data: responseResult as SuccessfulQueryResponse });
    }
  };

  useEffect(() => {
    if (result.id === runId && options?.onCompleted) {
      if (result.data) {
        options.onCompleted(
          {
            columns: result.data?.columns?.map(({ name, type, raw_type }) => ({
              name,
              type,
              raw_type,
            })), //remove __typename,
            rows: result.data?.rows,
          },
          error,
        );
      }
    }
  }, [runId, result]);

  const runQuery = useCallback(
    async (runOptions: {
      limit: boolean;
      disableRowCounter?: boolean;
    }): Promise<void> => {
      if (!model.query_type) {
        return undefined;
      }

      setLoading(true);
      const id = uuid();
      setRunId(id);

      const commonVariables: Partial<RunVisualBackgroundQueryVariables> = {
        // NOTE: the feature flag names on the right side need to match the names
        // in the database
        disableRowCounter:
          featureFlags?.sql_row_counter_disabled || runOptions.disableRowCounter
            ? true
            : undefined,
        // In the backend we actually query for 101 rows, but only return 100,
        // so that we can tell if the result is truncated.
        limit: runOptions.limit ? 100 : undefined,
      };

      const variables = {
        ...commonVariables,
        ...getVariables(model, options),
      };

      try {
        const queryKey = ASYNC_RUN_QUERY[model.query_type].getKey(variables);
        const queryFn = ASYNC_RUN_QUERY[model.query_type].fetcher(variables);

        // set ref to cancel the current query
        cancelQueryRef.current = () => client.cancelQueries(queryKey);

        const response = await client.fetchQuery<InnerBackgroundQueryResult>(
          queryKey,
          {
            queryFn,
            // Disable caching the query results.
            cacheTime: 0,
          },
        );

        // If the cancel function has been reset then the query was cancelled.
        if (!cancelQueryRef.current) {
          return;
        }

        const responseResult = getBackgroundInnerQueryResult(response);
        setBackgroundJobId(responseResult.jobId);

        // Update the run ID here so when the response comes back, we know
        // whether it is still to the request we care about.
        setRunId(responseResult.jobId);
        setPage(0);
        setShouldPollForResults(true);
      } catch (err) {
        handleErrorResponse(err.message, id);
        Sentry.captureEvent(err);
      }
      return;
    },
    [client, featureFlags, model, options],
  );

  const rows = useMemo(() => {
    return result.data?.rows?.map((row) => aliasRow(row, initialColumns));
  }, [result.data?.rows, initialColumns]);

  const columns = useMemo(
    () =>
      aliasColumns(result.data?.columns ?? [], initialColumns)?.map(
        (column) => ({
          name: column.name,
          type: column.type as ColumnType,
          raw_type: column.raw_type,
        }),
      ),
    [result.data?.columns, initialColumns],
  );

  const useAsyncPagination = Boolean(backgroundJobId) && page !== undefined;
  const numRowsWithoutLimit = Number(result.data?.numRowsWithoutLimit);

  return {
    runQuery,
    getSchema,
    cancelQuery,
    resetRunState,
    schemaLoading,
    loading,
    error,
    errorAtLine,
    columns,
    transformedSql,
    rawColumns: result.data?.columns?.map(({ name, type, raw_type }) => ({
      name,
      type,
      raw_type,
    })),
    rows,
    numRowsWithoutLimit: Number.isNaN(numRowsWithoutLimit)
      ? null
      : numRowsWithoutLimit,
    isResultTruncated: result.data?.exceedsPreviewMax,
    asyncPagination: useAsyncPagination,
    rowsCount: Number(result.data?.rowsCount),
    page: useAsyncPagination ? page : undefined,
    setPage: useAsyncPagination ? setPage : undefined,
    usedSampledModels: result.data?.usedSampledModels,
  };
};

export const useUpdateQuery = () => {
  const { mutateAsync: updateQuery } = useUpdateQueryMutation();
  const { mutateAsync: deleteColumns } = useDeleteModelColumnsMutation();
  const { mutateAsync: updateAudienceSubsets } =
    useUpdateAudienceSubsetsMutation();
  const { updateResourceOrDraft, resourceType } = useDraft();

  const update = async ({
    model,
    columns,
    topKEnabled,
    topKSyncInterval,
    overwriteMetadata,
    isDraft,
    onUpdate,
  }: {
    model: ModelState;
    columns: Array<UpdateQueryColumnType> | undefined;
    topKEnabled?: boolean;
    topKSyncInterval?: number;
    overwriteMetadata?: boolean;
    isDraft?: boolean;
    onUpdate?: () => void;
  }) => {
    const source = model?.connection;
    const type = model?.query_type;

    const deletedColumns = differenceBy(model?.columns, columns ?? [], "name");

    const update: SegmentsSetInput = {
      ...getModelInputFromState(model, false),
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again =
      approved_draft_id: null,
    };

    // This includes columns that still exist after deletion + all new columns
    const modelColumns = columns
      ? columns.map((column) => ({
          ...column,
          model_id: String(model?.id),
          // Add top K default settings to new columns.
          // Note that this does NOT override top K settings for existing columns.
          // In the resolver, we upsert these model columns and on conflict we only update a select few columns of each row.
          top_k_enabled: topKEnabled
            ? [ColumnType.String, ColumnType.Boolean].includes(
                column.type as ColumnType,
              )
            : false,
          top_k_sync_interval: topKSyncInterval,
          custom_type:
            column.custom_type === undefined
              ? model?.columns?.find((mc) => mc.name === column.name)
                  ?.custom_type
              : undefined,
        }))
      : [];

    if (overwriteMetadata && model && columns)
      invalidateColumnMetadata(model, modelColumns);

    const updateFunc = async () => {
      if (deletedColumns.length) {
        try {
          await deleteColumns({
            modelId: String(model?.id),
            names: deletedColumns.map(({ name }) => name),
          });
        } catch (err) {
          // Throw an error and abort the update - if the column deletion fails, we don't want to continue with the
          // update because it would potentially break relationships that depend on one of the columns that were
          // removed in the update. The user should be prompted to fix this first.
          throw new DeleteColumnsError("failed to delete columns", {
            cause: err,
            deletedColumns: deletedColumns.map((c) => c.name),
          });
        }
      }

      await updateQuery({
        id: String(model?.id),
        model: update,
        columns: modelColumns,
        overwriteMetadata,
      });

      analytics.track("Model Updated", {
        model_id: model?.id,
        model_type: type,
        source_id: source?.id,
      });

      if (model.subsets) {
        const subsetsToUpsert =
          model?.subsets.map(({ subset_value: { id } }) => ({
            segment_id: String(model?.id),
            subset_value_id: id,
          })) ?? [];

        await updateAudienceSubsets({
          audience_id: String(model?.id),
          upsert_objects: subsetsToUpsert,
          upsert_subset_ids: subsetsToUpsert.map(
            ({ subset_value_id }) => subset_value_id,
          ),
        });
      }
    };

    if (updateResourceOrDraft && resourceType === ResourceToPermission.Model) {
      await updateResourceOrDraft(
        {
          _set: update,
          modelColumns,
        },
        onUpdate ?? (() => {}),
        updateFunc,
        isDraft || false,
      );
    } else {
      await updateFunc();
    }
  };

  return update;
};

const invalidateColumnMetadata = (
  model: ModelState,
  columnsInput: ModelColumnInput[],
) => {
  const { columns } = model;

  for (const column of columns || []) {
    if (!column.metadata?.properties) continue;

    for (const input of columnsInput) {
      if (column.name === input.name && !input.metadata) {
        input.metadata = {
          ...column.metadata,
          properties: null,
        };
      }
    }
  }

  return columnsInput;
};

export const aliasColumns = <
  C extends {
    name: string;
    type: string;
    raw_type: string | null;
  },
>(
  columns: Array<C>,
  modelColumns: Array<{
    name: string;
    alias: string | null;
  }>,
): Array<C> =>
  columns?.map((column) => ({
    ...column,
    name: getColumnName(column.name, modelColumns),
  }));

export const aliasRow = (
  row,
  columns: Array<{
    name: string;
    alias: string | null;
    disable_preview: boolean;
    redacted_text: string | null;
  }>,
) => {
  const keys = Object.keys(row);

  // Add preview disabled columns back to the row
  const previewDisabledColumns = columns?.filter(
    ({ disable_preview }) => disable_preview,
  );
  previewDisabledColumns?.forEach(({ name }) => keys.push(name));

  return keys.reduce((rest, key) => {
    const modelColumn = columns?.find(({ name }) => name === key);
    const alias = modelColumn?.alias;
    const previewDisabled = modelColumn?.disable_preview;
    const redactedText = modelColumn?.redacted_text;
    const value = row[key];

    const newKey = alias || key;
    const newValue = previewDisabled
      ? (redactedText ?? "<REDACTED BY HIGHTOUCH>")
      : value;

    return { [newKey]: newValue, ...rest };
  }, {});
};

export const getColumnName = (
  queryColumn: string,
  modelColumns: Array<{ name: string; alias: string | null }>,
): string => {
  const alias = modelColumns?.find(({ name }) => name === queryColumn)?.alias;
  return alias || queryColumn;
};

export const getColumnNameFromAlias = (
  alias: string,
  modelColumns: Array<{ name: string; alias: string | null }>,
): string => {
  const name = modelColumns?.find(({ alias: a }) => a === alias)?.name;
  return name || alias;
};

export const getRawModelRow = (
  row: Record<string, any>,
  modelColumns: Array<{ name: string; alias: string | null }>,
) => {
  return Object.entries(row).reduce((acc, [key, value]) => {
    return { ...acc, [getColumnNameFromAlias(key, modelColumns)]: value };
  }, {});
};

export const isTimestampColumn = (column: {
  type: string | null;
  custom_type?: string | null;
}): boolean => {
  return (
    column.type === ColumnType.Timestamp ||
    column.custom_type === ColumnType.Timestamp
  );
};

/**
 * Select and format a column description for display.
 * Priority to user defined description -> dbt -> source schema (eg. table
 * comments)
 */
export const getColumnDescription = (
  columnDescription: string | null | undefined,
  dbtColumnDescription: string | null | undefined,
  sourceColumnDescription: string | null | undefined,
): string | undefined =>
  columnDescription ||
  dbtColumnDescription ||
  sourceColumnDescription ||
  undefined;

export const QueryTypeBadge: FC<
  Readonly<{ type: QueryType; children: BadgeProps["children"] }>
> = ({ type, children }) => {
  switch (type) {
    case QueryType.RawSql:
      return (
        <Badge size="sm" icon={SqlIcon}>
          {children}
        </Badge>
      );
    case QueryType.Visual:
      return (
        <Badge size="sm" icon={AudienceIcon}>
          {children}
        </Badge>
      );
    case QueryType.DbtModel:
      return (
        <Badge size="sm" icon={DbtIcon}>
          {children}
        </Badge>
      );
    case QueryType.Table:
      return (
        <Badge size="sm" icon={TableIcon}>
          {children}
        </Badge>
      );
    case QueryType.JourneyNode:
      return (
        <Badge size="sm" icon={MergeIcon}>
          Journey
        </Badge>
      );
    default:
      return (
        <Badge size="sm" icon={ModelIcon}>
          {children}
        </Badge>
      );
  }
};
