import { FC, useCallback } from "react";

import {
  Row,
  Text,
  Column,
  StatusBadge,
  SectionHeading,
  StatusBadgeProps,
} from "@hightouchio/ui";
import { useNavigate, useOutletContext } from "src/router";

import {
  EventSyncRunFragment,
  useEventWarehouseSyncRunsQuery,
} from "src/graphql";
import {
  BasicPagination,
  Table,
  TableColumn,
  useTableConfig,
} from "src/ui/table";
import { commaNumber } from "src/utils/numbers";
import { formatDatetime, formatDuration } from "src/utils/time";
import { openUrl } from "src/utils/urls";

import { OutletContext } from ".";
import { SyncHealthAlertsForSync } from "src/pages/syncs/sync/components/sync-health-alerts";
import { MonitoredResourceType } from "@hightouch/lib/resource-monitoring/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { SyncRunStatus } from "src/utils/syncs";

const PAGE_SIZE = 10;

export const WarehouseSyncRuns: FC = () => {
  const { sync } = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const { appEventMonitoringEnabled } = useFlags();

  const onRowClick = useCallback(
    ({ id }, event) =>
      openUrl(`/events/syncs/${sync.id}/runs/${id}`, navigate, event),
    [navigate],
  );

  const { offset, limit, page, setPage } = useTableConfig({ limit: PAGE_SIZE });

  const { data, isLoading, error } = useEventWarehouseSyncRunsQuery(
    {
      input: {
        syncId: sync.id,
        limit: limit + 1, // +1 so that we can see if there's another page
        offset,
      },
    },
    {
      select: (data) => ({
        runs: data.getEventWarehouseSyncRuns.event_warehouse_sync_runs,
      }),
      refetchInterval: 5000,
      keepPreviousData: true,
    },
  );
  const runs = data?.runs;

  return (
    <Column gap={4}>
      {appEventMonitoringEnabled && (
        <SyncHealthAlertsForSync
          syncId={sync.id}
          resourceType={MonitoredResourceType.EventSync}
          onClick={() => {
            navigate(`/events/syncs/${sync.id}/alerting`);
          }}
        />
      )}
      <SectionHeading>Runs</SectionHeading>
      <Table
        data={runs}
        columns={columns}
        placeholder={placeholder}
        onRowClick={onRowClick}
        loading={isLoading}
        error={Boolean(error)}
      />
      <Row justify="flex-end" width="100%" px={4}>
        <BasicPagination
          disableNextPage={data?.runs?.length !== PAGE_SIZE + 1}
          page={page}
          setPage={setPage}
        />
      </Row>
    </Column>
  );
};

const columns: TableColumn[] = [
  {
    name: "Status",
    cell: (run) => {
      const variant = getStatusBadgeVariant(run);
      return (
        <StatusBadge variant={variant}>
          {getStatusBadgeLabel(variant)}
        </StatusBadge>
      );
    },
  },
  {
    name: "Started",
    cell: (run) => (
      <Column gap={1}>
        <Text fontWeight="medium">{formatDatetime(run.started_at)}</Text>
        {run.started_at && run.finished_at && (
          <Text color="text.secondary" size="sm">
            Duration:{" "}
            {formatDuration(
              { start: run.started_at, end: run.finished_at },
              {
                units: "short",
              },
            )}
          </Text>
        )}
      </Column>
    ),
  },
  {
    name: "Events",
    cell: (run) => (
      <Text fontWeight="medium">
        {commaNumber(run.num_planned_events ?? 0)}
      </Text>
    ),
  },
  {
    name: "Failures",
    cell: (run) => (
      <Text fontWeight="medium">{commaNumber(run.num_failed_events ?? 0)}</Text>
    ),
  },
];

const placeholder = {
  title: "No runs yet",
  body: "Runs will appear here once you start syncing data.",
  error: "Something went wrong loading your runs.",
};

export const getStatusBadgeVariant = (
  run: EventSyncRunFragment | undefined,
): StatusBadgeProps["variant"] => {
  if (!run) return "inactive";
  if (!run.finished_at) return "processing";
  if (run.error) return "error";
  if (run.num_failed_events) return "warning";
  return "success";
};

export const getEventSyncStatusFromFragment = (
  run: EventSyncRunFragment | undefined,
): SyncRunStatus => {
  if (!run || !run.started_at) return SyncRunStatus.PENDING;
  if (run.started_at && !run.finished_at) return SyncRunStatus.ACTIVE;
  if (run.error) return SyncRunStatus.FAILED;
  if (run.num_failed_events) return SyncRunStatus.WARNING;
  return SyncRunStatus.SUCCESS;
};

export const getStatusBadgeLabel = (status: StatusBadgeProps["variant"]) => {
  const statusToLabel = {
    inactive: "Pending",
    error: "Failed",
    warning: "Warning",
    processing: "Running",
    success: "Success",
  };

  return statusToLabel[status];
};
