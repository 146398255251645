import { FC, useEffect, useState } from "react";

import {
  Column,
  DeleteIcon,
  FormField,
  Heading,
  RefreshIcon,
  Row,
  Spinner,
  Text,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { Outlet, useOutletContext } from "src/router";

import { ActionBar } from "src/components/action-bar";
import dbtCloudImage from "src/components/extensions/assets/dbt-cloud.png";
import { Overview } from "src/components/extensions/overview";
import { DetailPage } from "src/components/layout";
import { PermissionedButton } from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import { ScheduleType } from "src/components/schedule/types";
import {
  DbtCredentialsQuery,
  useCreateDbtCredentialMutation,
  useDbtCredentialsQuery,
  useDeleteDbtCloudCredentialMutation,
  useTestDbtCloudCredentialQuery,
} from "src/graphql";
import { DBTIcon } from "src/ui/icons";

import { ConnectedExtension, DependentSyncsModal } from "./common";
import { Card } from "src/components/card";
import { DocsLink } from "src/components/docs-link";

export const DbtCloudOverview: FC = () => {
  return (
    <Overview
      description="When your Hightouch sync depends on a table or materialized view generated by dbt, you can schedule it to run after completion of the associated dbt job."
      icon={DBTIcon}
      image={dbtCloudImage}
      subtitle="Trigger syncs upon completion of dbt jobs"
      title="dbt Cloud"
    />
  );
};

export const DbtCloud: FC = () => {
  const {
    data: credentials,
    isLoading: loading,
    isRefetching: refetching,
    refetch: refetchCredentials,
  } = useDbtCredentialsQuery(undefined, {
    select: (data) => data.dbt_credentials?.[0],
  });

  return (
    <DetailPage
      bg="base.lightBackground"
      crumbs={[{ label: "Extensions", link: "/extensions" }]}
      title="dbt cloud - Extensions"
      header={<Heading size="xl">dbt Cloud</Heading>}
      tabs={[
        {
          path: "",
          title: "Overview",
        },
        {
          path: "configuration",
          title: "Configuration",
          size: "small",
        },
      ]}
    >
      <Outlet
        context={{
          credentials,
          loading: loading || refetching,
          refetchCredentials,
        }}
      />
    </DetailPage>
  );
};

const ConnectedDbtCloudContent: FC<{
  credentials: DbtCredentialsQuery["dbt_credentials"][0];
}> = ({ credentials }) => {
  return (
    <Column gap={3}>
      {!credentials?.account_url ? (
        <Text fontWeight="medium">
          <Text fontWeight="semibold">Subdomain: </Text>
          {credentials?.subdomain || "Not configured"}
        </Text>
      ) : (
        <Text fontWeight="medium">
          <Text fontWeight="semibold">Account URL: </Text>
          {credentials?.account_url}
        </Text>
      )}
    </Column>
  );
};

interface OutletContext {
  credentials: DbtCredentialsQuery["dbt_credentials"][0];
  loading: boolean;
  refetchCredentials: () => void;
}

type DbtCloudTestStatus = "failed" | "loading" | "success" | "error";

export const DbtCloudConfiguration: FC = () => {
  const [apiKey, setApiKey] = useState("");
  const [accountUrl, setAccountUrl] = useState("");

  const [error, setError] = useState<string | null>(null);

  const { credentials, loading, refetchCredentials } =
    useOutletContext<OutletContext>();
  const { mutateAsync: deleteExtension, isLoading: extensionDeleting } =
    useDeleteDbtCloudCredentialMutation();
  const [isDeleting, setIsDeleting] = useState(false);
  const [testStatus, setTestStatus] = useState<DbtCloudTestStatus>("loading");

  const {
    data: testResult,
    isLoading: isTesting,
    refetch: testExtension,
    isRefetching: isReTesting,
  } = useTestDbtCloudCredentialQuery(
    {},
    { select: (data) => data.testDbtCloudCredential, enabled: !!credentials },
  );

  const { toast } = useToast();

  const { mutateAsync: create, isLoading: isCreatingExtension } =
    useCreateDbtCredentialMutation();

  useEffect(() => {
    if (credentials) {
      if (isTesting || isReTesting) {
        setTestStatus("loading");
        return;
      }
      if (!testResult) {
        setTestStatus("failed");
        toast({
          id: "dbt-cloud-test",
          variant: "error",
          title: "dbt Cloud credentials test failed.",
          message:
            "Please check your API credentials on your dbt Cloud account.",
        });
      } else {
        setTestStatus("success");
      }
    }
  }, [credentials, isTesting, isReTesting]);

  const submit = async () => {
    try {
      if (credentials?.id) {
        // We don't allow updates right now
        return;
      } else {
        await create({ apiKey, accountUrl });
        toast({
          id: "dbt-cloud-configuration",
          title: "dbt Cloud connected",
          message: "You can now use dbt Cloud to trigger your syncs",
          variant: "success",
        });
        refetchCredentials();
        setApiKey("");
        setAccountUrl("");
      }
    } catch (e) {
      toast({
        id: "dbt-cloud-configuration",
        title: "Error connecting to dbt Cloud",
        message: e.message ?? "There was an error saving your configuration.",
        variant: "error",
      });
      setError("Invalid API Key");
      Sentry.captureException(e);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <PermissionProvider
      permission={{
        v2: {
          resource: "workspace",
          grant: "can_update",
        },
      }}
    >
      <Column gap={6}>
        <Row alignItems="center" justifyContent="space-between">
          <Heading>dbt Cloud configuration</Heading>
          <DocsLink name="dbt Cloud" href="extensions/dbt-cloud" />
        </Row>
        {credentials?.id ? (
          <ConnectedExtension credentials={credentials} testStatus={testStatus}>
            <ConnectedDbtCloudContent credentials={credentials} />
          </ConnectedExtension>
        ) : (
          <Card gap={6}>
            <FormField
              description="Provide your dbt cloud account URL e.g. ab123.us1.dbt.com"
              isRequired={true}
              label="dbt cloud URL"
            >
              <TextInput
                placeholder="Enter your dbt cloud URL"
                isDisabled={Boolean(credentials?.id)}
                value={accountUrl}
                onChange={(e) => setAccountUrl(e.target.value)}
              />
            </FormField>

            <FormField
              error={error ? error : undefined}
              label="dbt Cloud API token"
              isRequired={true}
            >
              <TextInput
                placeholder="Enter your dbt Cloud API token"
                isDisabled={Boolean(credentials?.id)}
                type="password"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
              />
            </FormField>
          </Card>
        )}
      </Column>
      <ActionBar>
        {credentials?.id ? (
          <>
            <PermissionedButton
              size="lg"
              permission={{
                v2: { resource: "workspace", grant: "can_update" },
              }}
              icon={RefreshIcon}
              isDisabled={isTesting || isReTesting || isDeleting}
              onClick={async () => {
                setTestStatus("loading");
                await testExtension({});
              }}
            >
              {isTesting || isReTesting ? "Testing..." : "Test connection"}
            </PermissionedButton>
            <PermissionedButton
              size="lg"
              permission={{
                v2: { resource: "workspace", grant: "can_update" },
              }}
              icon={DeleteIcon}
              isDisabled={isDeleting || isTesting}
              variant="danger"
              onClick={() => {
                setIsDeleting(true);
              }}
            >
              Disconnect
            </PermissionedButton>
          </>
        ) : (
          <PermissionedButton
            size="lg"
            permission={{
              v2: { resource: "workspace", grant: "can_update" },
            }}
            isLoading={isCreatingExtension}
            variant="primary"
            onClick={submit}
          >
            Connect
          </PermissionedButton>
        )}
      </ActionBar>
      <DependentSyncsModal
        deleteExtension={() => deleteExtension({})}
        extensionName="dbt Cloud"
        isDeleting={extensionDeleting}
        open={isDeleting}
        scheduleType={ScheduleType.DBT_CLOUD}
        onClose={() => setIsDeleting(false)}
      />
    </PermissionProvider>
  );
};
