export enum EventSourceType {
  JAVASCRIPT = "javascript",
  HTTP = "http",
  NODE = "node",
  PYTHON = "python",
  IOS = "ios",
  ANDROID = "android",
  RUBY = "ruby",
  JAVA = "java",
  GO = "go",
  PHP = "php",
  REACT_NATIVE = "react-native",
  CSHARP = "csharp",
  WEBHOOK = "webhook",
  KAFKA = "kafka",
  CONFLUENT_KAFKA = "confluent-kafka",
  PUBSUB = "pubsub",
  FLUTTER = "flutter",
}

export enum EventWarehouseDestinationType {
  BIGQUERY = "bigquery",
  DATABRICKS = "databricks",
  POSTGRES = "postgres",
  REDSHIFT = "redshift",
  S3 = "s3",
  SNOWFLAKE = "snowflake",
}

export enum EventForwardingDestinationType {
  GOOGLE_ANALYTICS = "googleAnalytics",
  HTTP_REQUEST = "webhookV2",
  BRAZE = "braze",
  AMPLITUDE = "amplitude",
  ITERABLE = "iterable",
  SFMC = "sfmc",
  KAFKA = "apacheKafka",
  CUSTOMER_IO = "customerio",
  MOENGAGE = "moengage",
  MIXPANEL = "mixpanel",
}
