import { MergedSyncRequestEventSpan } from "src/graphql";
import { SyncRequest } from "./types";
import { Alert, Column, Text } from "@hightouchio/ui";

export const PhaseError = ({
  span,
  errors,
  syncRequest,
  variant = "compact",
}: {
  span: MergedSyncRequestEventSpan;
  errors: { message: string }[];
  syncRequest: SyncRequest;
  variant?: "compact" | "card";
}) => {
  // TODO: we might want to show multiple errors in the future, for now we just grab the last one
  const lastError = errors[errors.length - 1];
  const attribution = errorAttribution(span, syncRequest);

  const title = `Error ${attribution ? `message from ${attribution}` : ""}`;

  // TODO: look up sanity error codes if error_code_details is defined on the sync_request
  // Can borrow some logic from SyncRequestErrorModal or the debugger
  const message = lastError?.message || "An unknown error occurred";

  if (variant === "card") {
    return <Alert type="error" title={title} message={message} />;
  }

  return (
    <Column backgroundColor="danger.background" p={2} gap={2} borderRadius="md">
      <Text size="sm" fontWeight="medium">
        {title}
      </Text>
      <Text size="sm" isMonospace color="text.danger">
        {message}
      </Text>
    </Column>
  );
};

const errorAttribution = (
  span: MergedSyncRequestEventSpan,
  syncRequest: SyncRequest,
) => {
  switch (span) {
    case MergedSyncRequestEventSpan.ExecuteQuery:
    case MergedSyncRequestEventSpan.GeneratePlan:
      return (
        syncRequest.sync?.segment?.connection?.definition?.name ?? "source"
      );
    case MergedSyncRequestEventSpan.ExecuteDestination:
      return syncRequest.sync?.destination?.definition?.name ?? "destination";
    default:
      return null;
  }
};
