import { FC, useState } from "react";

import {
  RelatedColumn,
  TraitColumn,
  TraitCondition,
  TraitType,
} from "@hightouch/lib/query/visual/types";
import {
  Column,
  Row,
  Heading,
  Button,
  useToast,
  Menu,
  MenuActionsButton,
  MenuList,
  MenuItem,
  IconButton,
  CloseIcon,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitErrorHandler, useForm } from "react-hook-form";

import { Drawer } from "src/components/drawer";
import { AdditionalColumn, Audience } from "src/types/visual";

import {
  TraitEnrichmentForm,
  TraitEnrichmentFormProps,
  traitEnrichmentValidationSchema,
} from "./trait-enrichment-form";
import { useRemoveAdditionalColumn, useUpdateAdditionalColumn } from "./utils";
import { ParentModel } from "src/pages/audiences/types";

type Props = {
  audience: NonNullable<Audience>;
  parentModel: NonNullable<ParentModel>;
  traitEnrichment: {
    name: string;
    conditions: TraitCondition[];
    templateId: string;
    relationshipId: string | undefined;
    index: number;
  };
  onClose: () => void;
};

export const EditTraitEnrichment: FC<Readonly<Props>> = ({
  audience,
  parentModel,
  traitEnrichment,
  onClose,
}) => {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<TraitEnrichmentFormProps>({
    resolver: yupResolver(
      traitEnrichmentValidationSchema(parentModel?.connection?.type),
    ),
    defaultValues: {
      name: traitEnrichment.name,
      template_id: traitEnrichment.templateId,
      relationship_id: traitEnrichment.relationshipId,
      conditions: traitEnrichment.conditions,

      // We don't persist the type in the trait enrichment object so we have to fetch it
      type: parentModel.traits.find((t) => t.id === traitEnrichment.templateId)
        ?.type as TraitType,
    },
  });

  const removeAdditionalColumn = useRemoveAdditionalColumn();
  const updateAdditionalColumn = useUpdateAdditionalColumn();

  const onDelete = async () => {
    await removeAdditionalColumn({
      audience,
      index: traitEnrichment.index,
      onSuccess: onClose,
    });
  };

  const onSubmit = async (data: TraitEnrichmentFormProps) => {
    setIsSubmitting(true);
    try {
      const traitColumn: TraitColumn = {
        type: "trait",
        traitDefinitionId: data.template_id,
        conditions: data.conditions,
      };

      const relatedColumn: RelatedColumn = {
        type: "related",
        path: [String(data.relationship_id)],
        column: traitColumn,
      };

      const additionalColumn: AdditionalColumn = {
        alias: data.name,
        column: relatedColumn,
      };

      await updateAdditionalColumn({
        audience,
        additionalColumn,
        index: traitEnrichment.index,
        onSuccess: onClose,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onError: SubmitErrorHandler<TraitEnrichmentFormProps> = (_errors) => {
    toast({
      id: "edit-trait-enrichment-validation",
      variant: "error",
      title: "Trait enrichment failed to be saved",
      message: "There were errors in your submission",
    });
  };

  return (
    <Drawer isLoading={false} size="xl" isOpen onClose={onClose}>
      <Row
        p={6}
        borderBottom="1px solid"
        borderBottomColor="base.border"
        alignItems="end"
        justifyContent="space-between"
      >
        <Heading>Edit trait enrichment</Heading>
        <Row gap={4}>
          <Menu>
            <MenuActionsButton />
            <MenuList>
              <MenuItem variant="danger" onClick={onDelete}>
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
          <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
        </Row>
      </Row>
      <Column flex={1} p={6} gap={6} overflowY="auto">
        <FormProvider {...form}>
          <TraitEnrichmentForm parentModel={parentModel} editConditions />
        </FormProvider>
      </Column>

      <Row
        px={6}
        py={4}
        borderTop="solid 1px"
        borderTopColor="base.border"
        justifyContent="space-between"
      >
        <Button onClick={onClose}>Cancel</Button>
        <Button
          isDisabled={!form.formState.isDirty || isSubmitting}
          isLoading={isSubmitting}
          variant="primary"
          onClick={form.handleSubmit(onSubmit, onError)}
        >
          Save changes
        </Button>
      </Row>
    </Drawer>
  );
};
