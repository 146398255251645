import { ConditionType, PredefinedMetric } from "src/types/visual";
import { AudienceFilter, ChartTab, MeasurementScope } from "./types";
import { NULL_OPTION_VALUE } from "./state/constants";

export const MAX_SELECTED_AUDIENCES_ALLOWED = 4;
export const MAX_LINES = 30;
export const placeholderContentWidthPx = "576px";

export const DEFAULT_FILTER: AudienceFilter = {
  type: ConditionType.And,
  conditions: [],
};

export const ALL_DATA_MEASURING_SELECTION = {
  id: NULL_OPTION_VALUE, // Option value can't be null
  scope: MeasurementScope.AllData,
};

export enum MetricType {
  Goal = "Goal",
  Event = "Event",
}

export const PREDEFINED_METRIC_OPTIONS = [
  {
    id: PredefinedMetric.AudienceSize as string,
    name: "Audience size",
    eventModelId: null,
    description: null,
  },
];

export const AnalyticsColors = {
  Primary: {
    unfocused: "teal.200",
    secondary: "teal.300",
    primary: "teal.500",
    hover: "teal.600",
  },
  Secondary: {
    unfocused: "warning.200",
    secondary: "warning.300",
    primary: "warning.500",
    hover: "warning.600",
  },
  Tertiary: {
    unfocused: "grass.200",
    secondary: "grass.300",
    primary: "grass.500",
    hover: "grass.600",
  },
  Fourth: {
    unfocused: "electric.200",
    secondary: "electric.300",
    primary: "electric.500",
    hover: "electric.600",
  },
  Fifth: {
    unfocused: "danger.200",
    secondary: "danger.300",
    primary: "danger.500",
    hover: "danger.600",
  },
  Sixth: {
    unfocused: "gray.200",
    secondary: "gray.300",
    primary: "gray.500",
    hover: "gray.600",
  },
} as const;

export const graphColors = [
  { color: "#4FC26B", bg: "#ECFEF5" },
  { color: "#AC61FF", bg: "#F1E7FC" },
  { color: "#E49A38", bg: "#FEF9EC" },
  { color: "#349AC6", bg: "#EBFCFF" },
  { color: "#D25046", bg: "#FFECEB" },
];

export const ChartOptions = [ChartTab.Insights, ChartTab.Funnel];
