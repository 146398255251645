import {
  EventForwardingDestinationType,
  EventSourceType,
  EventWarehouseDestinationType,
} from "./types";

export * from "../../../events/types";

export type EventSourceDefinition<T extends EventSourceType = EventSourceType> =
  {
    name: string;
    type: T;
    icon: string;
    isQueue?: boolean;
    tunneling?: boolean;
    provider?: string;
  };

export const eventSourceDefinitions: {
  [K in EventSourceType]: EventSourceDefinition<K>;
} = {
  [EventSourceType.JAVASCRIPT]: {
    name: "Javascript",
    type: EventSourceType.JAVASCRIPT,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/41ded1994468d88c982ed67098ca09f885484bb6-24x24.svg",
  },
  [EventSourceType.NODE]: {
    name: "Node",
    type: EventSourceType.NODE,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/8a264f0c250775948d1b2c7fe6b084b72b0743bd-24x24.svg",
  },
  [EventSourceType.PYTHON]: {
    name: "Python",
    type: EventSourceType.PYTHON,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/6bc1aba85bd03bfe1aafa7293bf853aa0c691910-24x24.svg",
  },
  [EventSourceType.RUBY]: {
    name: "Ruby",
    type: EventSourceType.RUBY,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/2291ffee0ac7a61f94f58dc07177f58fc1e15b2c-24x24.svg",
  },
  [EventSourceType.GO]: {
    name: "Go",
    type: EventSourceType.GO,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/8a36a22798f5330ee58e7e3999ca91430d673cfe-24x24.svg",
  },
  [EventSourceType.PHP]: {
    name: "PHP",
    type: EventSourceType.PHP,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/30fa7d72c31ac219230fe55a8a1c80cab0743343-24x24.svg",
  },
  [EventSourceType.REACT_NATIVE]: {
    name: "React Native",
    type: EventSourceType.REACT_NATIVE,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/33b1a37d49682cf3a013e13c93e61767fc9d24b1-24x24.svg",
  },
  [EventSourceType.IOS]: {
    name: "iOS",
    type: EventSourceType.IOS,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/24c0d0e0588af116d9330f9810489d7265c6f2b5-24x24.svg",
  },
  [EventSourceType.ANDROID]: {
    name: "Android",
    type: EventSourceType.ANDROID,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/ee21fd72db2115a032f8cf76454c0c522d080e81-24x24.svg",
  },
  [EventSourceType.FLUTTER]: {
    name: "Flutter",
    type: EventSourceType.FLUTTER,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/c47a8f27868777ce37fad56510e3ad2f10ca5ae8-24x24.svg",
  },
  [EventSourceType.JAVA]: {
    name: "Java",
    type: EventSourceType.JAVA,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/fb8408545cc4168d180b99f777542a2fe2eaaae0-24x24.svg",
  },
  [EventSourceType.HTTP]: {
    name: "HTTP",
    type: EventSourceType.HTTP,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/dd02f259d8f5e030d9ae906d5a3e874848a07ff7-24x24.svg",
  },
  [EventSourceType.CSHARP]: {
    name: "C#",
    type: EventSourceType.CSHARP,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/62cf4a1305c672410cc80c476581a7d57f7204f1-24x24.svg",
  },
  [EventSourceType.WEBHOOK]: {
    name: "Webhook",
    type: EventSourceType.WEBHOOK,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/ee91ca3a56229399004a10e7b711e8954f77169f-24x24.svg",
  },
  [EventSourceType.KAFKA]: {
    name: "Kafka",
    type: EventSourceType.KAFKA,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/91b7c96066398a96ce1fd25e9bef8eb67cf922fa-24x24.svg",
    isQueue: true,
    tunneling: true,
  },
  [EventSourceType.CONFLUENT_KAFKA]: {
    name: "Confluent", // confluent prefers we don't mention the apache trademarked "Kafka" in the name
    type: EventSourceType.CONFLUENT_KAFKA,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/d5e2fc793be8cd15cb602b97ca3c9278b3f7adc1-24x24.svg",
    isQueue: true,
    tunneling: true,
  },
  [EventSourceType.PUBSUB]: {
    name: "Google PubSub",
    type: EventSourceType.PUBSUB,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/79a1411b9b58c678c39aa86a682cc5892c5f6c2e-2500x2500.svg",
    isQueue: true,
    provider: "gcp",
  },
};

export const eventWarehouseDestinationDefinitions = {
  [EventWarehouseDestinationType.BIGQUERY]: {
    name: "BigQuery",
    type: EventWarehouseDestinationType.BIGQUERY,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/07855716fd9755bf69f12306aa93ca7959a29f6b-24x24.svg",
  },
  [EventWarehouseDestinationType.DATABRICKS]: {
    name: "Databricks",
    type: EventWarehouseDestinationType.DATABRICKS,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/ef36b9be793746e5911d52311f16ee79a70ae1e8-24x24.svg",
  },
  [EventWarehouseDestinationType.POSTGRES]: {
    name: "PostgreSQL",
    type: EventWarehouseDestinationType.POSTGRES,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/fdd94de6cc5dc2cef3ed74034a320383ecbc1821-24x24.svg",
  },
  [EventWarehouseDestinationType.REDSHIFT]: {
    name: "Redshift",
    type: EventWarehouseDestinationType.REDSHIFT,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/22095e21d881717862649069234fef9297222248-24x24.svg",
  },
  [EventWarehouseDestinationType.S3]: {
    name: "S3",
    type: EventWarehouseDestinationType.S3,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/8f20caff00d63874919c3099920371177154ebf5-24x24.svg",
  },
  [EventWarehouseDestinationType.SNOWFLAKE]: {
    name: "Snowflake",
    type: EventWarehouseDestinationType.SNOWFLAKE,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/fec195936f76a4e4936817bf875eb1bbfedeef80-24x24.svg",
  },
};

type EventDestinationDefinition<T extends EventForwardingDestinationType> = {
  name: string;
  type: T;
  icon: string;
};

export const eventForwardingDestinationDefinitions: {
  [K in EventForwardingDestinationType]: EventDestinationDefinition<K>;
} = {
  [EventForwardingDestinationType.GOOGLE_ANALYTICS]: {
    name: "Google Analytics",
    type: EventForwardingDestinationType.GOOGLE_ANALYTICS,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/17e9a0a824edd36beb9c53d9a4058573ac7f54fa-24x24.svg",
  },
  [EventForwardingDestinationType.HTTP_REQUEST]: {
    name: "HTTP Request",
    type: EventForwardingDestinationType.HTTP_REQUEST,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/ee91ca3a56229399004a10e7b711e8954f77169f-24x24.svg",
  },
  [EventForwardingDestinationType.BRAZE]: {
    name: "Braze",
    type: EventForwardingDestinationType.BRAZE,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/53a51b0766972911d28499cb4ab3a0ea4c4ca6c2-24x24.svg",
  },
  [EventForwardingDestinationType.AMPLITUDE]: {
    name: "Amplitude",
    type: EventForwardingDestinationType.AMPLITUDE,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/50da27aa7e26819bd62ac0bebf669ad0446fcf90-512x512.svg",
  },
  [EventForwardingDestinationType.ITERABLE]: {
    name: "Iterable",
    type: EventForwardingDestinationType.ITERABLE,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/2aa27fb73c46122438955e943fbbdc796c25ef6d-24x24.svg",
  },
  [EventForwardingDestinationType.SFMC]: {
    name: "Salesforce Marketing Cloud",
    type: EventForwardingDestinationType.SFMC,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/97f81ac985013a4ea01822cb3b9f28fce004ac87-24x24.svg",
  },
  [EventForwardingDestinationType.KAFKA]: {
    name: "Kafka",
    type: EventForwardingDestinationType.KAFKA,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/91b7c96066398a96ce1fd25e9bef8eb67cf922fa-24x24.svg",
  },
  [EventForwardingDestinationType.CUSTOMER_IO]: {
    name: "Customer.io",
    type: EventForwardingDestinationType.CUSTOMER_IO,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/42ff2109f47a92abc13ef0f2fa4f9e47d030e6ee-512x512.svg",
  },
  [EventForwardingDestinationType.MOENGAGE]: {
    name: "MoEngage",
    type: EventForwardingDestinationType.MOENGAGE,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/8f187ed8288697e7dcb16d01426f670ace5e16c9-400x400.png",
  },
  [EventForwardingDestinationType.MIXPANEL]: {
    name: "Mixpanel",
    type: EventForwardingDestinationType.MIXPANEL,
    icon: "https://cdn.sanity.io/images/pwmfmi47/production/9d01980d1d7a7c390e39eed98ce6a7e6576f7bb6-24x24.svg",
  },
};

export {
  EventSourceType,
  EventWarehouseDestinationType,
  EventForwardingDestinationType,
};
