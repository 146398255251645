import { useFlags } from "launchdarkly-react-client-sdk";

import { useEntitlementsQuery } from "src/graphql";

interface Entitlements {
  destinations: number;
  premiumDestinations: number;
  audiences: boolean;
  externalStorage: boolean;
  matchbooster: boolean;
  idr: boolean;
  events: boolean;
  analytics: boolean;
  campaigns: boolean;
}

export type BillingDestinationType = {
  count: number;
  isFreeDestination: boolean;
  isPremiumDestination: boolean;
  isInTrial: boolean;
};

export type UseEntitlementsResult = {
  isLoading: boolean;
  data: {
    destinationBillingTypes: Record<string, BillingDestinationType>;
    overage: {
      destinationOverage: boolean;
      premiumOverage: boolean;
      overage: boolean;
      firstOverageDate: string | null;
      overageLockout: boolean;
      destinationOverageText: string;
    };
    entitlements: Entitlements;
    destinationsCounts: {
      billableDestinations: number;
      freeDestinations: number;
      premiumDestinations: number;
      totalDestinations: number;
    };
  };
};

export const useEntitlements = (
  enabled: boolean,
  suspense = false,
): UseEntitlementsResult => {
  const {
    appSsLockoutOverage,
    audiencesEnabled,
    identityResolution,
    eventCollection,
    appAnalyticsLaunched,
    enableCampaignAnalytics,
  } = useFlags();
  const { data, isLoading } = useEntitlementsQuery(undefined, {
    enabled,
    suspense,
  });

  const overageInfo = data?.organization_overage_dates?.[0];
  const billingByDestinationInfo = data?.billing_by_destination || [];

  const entitlements: Entitlements = {
    destinations: 0,
    premiumDestinations: 0,
    audiences: false,
    matchbooster: false,
    ...overageInfo?.["entitlements"],
  };

  entitlements.audiences = entitlements.audiences || audiencesEnabled;
  entitlements.idr = entitlements.idr || identityResolution;
  entitlements.events = entitlements.events || eventCollection;
  entitlements.campaigns = entitlements.campaigns || enableCampaignAnalytics;
  // if campaigns is enabled, then analytics must be enabled too
  entitlements.analytics =
    entitlements.campaigns || entitlements.analytics || appAnalyticsLaunched;

  // Some destinations are grouped together for billing purposes: https://hightouch.com/docs/pricing/ss-pricing#grouped-destinations
  const destinationBillingTypes: Record<string, BillingDestinationType> = {};

  for (const destination of billingByDestinationInfo) {
    const {
      billing_type,
      is_free_destination,
      is_premium_destination,
      is_in_trial,
    } = destination;

    // Some destinations in the production database have a deprecated billing_type of "callback".
    // TODO: Do this filtering in the view definition.
    if (billing_type === "callback") {
      continue;
    }

    // Typecheck complains that the properties might be null.
    const billingType = billing_type || "";
    const isFreeDestination = is_free_destination || false;
    const isPremiumDestination = is_premium_destination || false;
    const isInTrial = is_in_trial || false;

    const currentCount = destinationBillingTypes[billingType]?.count || 0;
    const count = currentCount + 1;

    destinationBillingTypes[billingType] = {
      count,
      isFreeDestination,
      isPremiumDestination,
      isInTrial,
    };
  }

  const destinationOverage = Boolean(
    overageInfo?.["is_org_in_overage"] ||
      overageInfo?.["is_workspace_in_overage"],
  );
  const premiumOverage =
    Number(overageInfo?.num_premium_destination_types) >
    entitlements?.premiumDestinations;
  const destinationOverageText =
    "This workspace has exceeded the number of destinations allowed on its current plan.";

  const destinationBillingTypesValues = Object.values(destinationBillingTypes);

  const freeDestinations = destinationBillingTypesValues.filter(
    (d) => d.isFreeDestination,
  ).length;
  const premiumDestinations = overageInfo?.num_premium_destination_types;

  // Account for the one free destination if it is being used.
  const allPaidDestinationTypes = destinationBillingTypesValues.filter(
    ({ isInTrial, isFreeDestination }) => !isInTrial && !isFreeDestination,
  );
  let billableDestinations = overageInfo?.num_paid_destination_types;
  let totalDestinations = billableDestinations + freeDestinations;
  if (allPaidDestinationTypes.length > 0) {
    billableDestinations += 1;
    totalDestinations += 1;
  }

  return {
    isLoading,
    data: {
      destinationBillingTypes,
      overage: {
        destinationOverage,
        premiumOverage,
        overage: destinationOverage,
        firstOverageDate: overageInfo?.overage_date ?? null,
        overageLockout: Boolean(appSsLockoutOverage) && destinationOverage,
        destinationOverageText,
      },
      entitlements,
      destinationsCounts: {
        billableDestinations,
        freeDestinations,
        premiumDestinations,
        totalDestinations,
      },
    },
  };
};
