import { Navigate, Route, Routes } from "src/router";

import { PermissionedRoute } from "src/components/router/permissioned-route";
import { FormErrorProvider } from "src/contexts/form-error-context";

import { JourneyFilterType } from "./constants";
import { MemberDetailForm, NodeDetailForm, SettingsForm } from "./forms";
import Journey from "./journey";
import { JourneysLayout } from "./journeys";
import { JourneysTable } from "./journeys-table";
import { CreateJourneySyncWizard } from "./wizards";

export const JourneysRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<JourneysLayout />}>
        <Route index element={<Navigate replace to="all" />} />
        <Route
          path="all"
          element={<JourneysTable type={JourneyFilterType.ALL} />}
        />
        <Route
          path="drafts"
          element={<JourneysTable type={JourneyFilterType.DRAFTS} />}
        />
        <Route
          path="live"
          element={<JourneysTable type={JourneyFilterType.LIVE} />}
        />
        <Route
          path="off"
          element={<JourneysTable type={JourneyFilterType.OFF} />}
        />
      </Route>
      <Route path="/:id" element={<Journey />}>
        <Route
          path="settings"
          element={
            <FormErrorProvider>
              <SettingsForm />
            </FormErrorProvider>
          }
        />
        <Route path=":node_id?/member-details" element={<MemberDetailForm />} />
        <Route
          path=":node_id/new-sync"
          element={
            <PermissionedRoute
              // For permissions v2 let the wizard deal with user's source and destination permission
              permission={{ v1: { resource: "sync", grant: "create" } }}
              redirect="/journeys/:id/:node_id"
            >
              <CreateJourneySyncWizard />
            </PermissionedRoute>
          }
        />
        <Route path=":node_id/*" element={<NodeDetailForm />} />
      </Route>
    </Routes>
  );
};
