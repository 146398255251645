import { FC } from "react";

import {
  Box,
  CloseIcon,
  Column,
  Row,
  SuccessIcon,
  Text,
} from "@hightouchio/ui";
import { useNavigate } from "src/router";
import { Position } from "reactflow";

import { TextWithTooltip } from "src/components/text-with-tooltip";
import { NODE_WIDTH } from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import { HiddenHandle, MiniNodeHandle } from "src/pages/journeys/handles";
import { JourneyNodeConfig, JourneyNodeType } from "src/types/journeys";

type MiniNodeProps = {
  id: string;
  name: string;
  config: JourneyNodeConfig;
};

export const MiniNode: FC<MiniNodeProps> = ({ id, name, config }) => {
  const navigate = useNavigate();

  const { connectionSourceNodeId, hasOutgoers, unauthorized } =
    useGraphContext();

  const hasConnections = hasOutgoers(id);

  return (
    // Fill in the space until 200px
    <Column align="center" width={`${NODE_WIDTH}px`}>
      <Row
        align="center"
        p={4}
        gap={2}
        background="white"
        borderRadius="md"
        boxShadow="sm"
        border="2px solid"
        borderColor="transparent"
        width="min-content"
        maxWidth={`${NODE_WIDTH}px`}
        _hover={{
          cursor: "pointer",
          boxShadow: "md",
          borderColor: connectionSourceNodeId ? "danger.base" : "transparent",
        }}
        _active={{
          borderColor: `gray.500`,
          boxShadow: "lg",
        }}
        onClick={() => {
          navigate(id);
        }}
      >
        {config.type === JourneyNodeType.WaitUntilEventBranch && (
          <Box as={Text} fontSize="20px">
            {name === "Rule met" ? (
              <SuccessIcon color="avocado.base" />
            ) : (
              <CloseIcon color="danger.base" />
            )}
          </Box>
        )}
        <TextWithTooltip>{name}</TextWithTooltip>
        <MiniNodeHandle
          type="source"
          enabled={!unauthorized && !hasConnections}
          position={Position.Bottom}
        />
        <HiddenHandle
          type="target"
          enabled={!unauthorized}
          position={Position.Top}
        />
      </Row>
    </Column>
  );
};
