import { Edge as ReactFlowEdge, Node as ReactFlowNode } from "reactflow";

import { Schedule } from "src/components/schedule/types";
import {
  JourneyNodeConfig,
  JourneyNodeSyncMode,
  JourneyStatus,
  JourneySyncExitConfig,
  JourneyExitCriteriaConfig,
} from "src/types/journeys";
import { NullableProperties } from "src/types/utils";

export type SyncConfigDetails = {
  destination_instance_id: number;
  mode: JourneyNodeSyncMode;
  // IMPORTANT: the backend does not allow `null`, but `undefined` does not work with the radio buttons.
  // The exit config must be transformed when receiving and sending the data to the backend.
  exit_config: NullableProperties<JourneySyncExitConfig>;
};

export type JourneyNodeDetails<C extends JourneyNodeConfig> = {
  id: string;
  name: string;
  number_users: string | null;
  number_unique_users: string | null;
  // Audience ID for EntryCohort
  // Event ID for WaitUntilEvent and EntryEvent
  segment_id: string | null;
  // Relationship ID for WaitUntilEvent and EntryEvent
  event_relationship_id: string | null;

  // Journey sync config for sync nodes
  sync_configs: SyncConfigDetails[] | null;

  config: C;
};

// This is the node type that's used with react flow.
// It will need a translation step (i.e. adding position)
export type JourneyNode = ReactFlowNode<JourneyNodeDetails<JourneyNodeConfig>>;

export type JourneyGraph = {
  journey: {
    id: string;
    name: string;
    description: string | null;
    status: JourneyStatus;
    schedule: Schedule;
    exitCriteria: JourneyExitCriteriaConfig;
  };
  nodes: JourneyNode[];
  edges: ReactFlowEdge[];
};

export type NodeColor = "gray" | "cyan" | "warning" | "electric";

export type JourneyState =
  | "live"
  | "draft"
  | "draining"
  | "off"
  | "paused"
  | "resetting";

export type NodeOutletContext = {
  nodeId: string;
  onClose: () => void;
};

export type NodeDetailFormProps<C extends JourneyNodeConfig> = {
  id: string;
  data: JourneyNodeDetails<C>;
  onClose: () => void;
};

export type CreateJourneyForm = {
  name: string;
  description: string | null;
  parentModelId: number | null;
};

export type SegmentPriorityList = {
  id: string;
  name: string;
}[];

export type SplitBranch = {
  id: string;
  name: string;
  percentage: number;
};

export type JourneyRun = {
  id: string;
  status: string;
  error: string | null;
  started_at: string | null;
  finished_at: string | null;
  entry_count: string;
  unique_entry_count: string;
  exit_count: string;
  unique_exit_count: string;
  completed_count: string;
  unique_completed_count: string;
  journey_node_runs: JourneyNodeRun[];
};

export type JourneyNodeRun = {
  node_id: string;
  status: string;
  error: string | null;
  run_id?: string;
  entry_count: string;
  unique_entry_count: string;
  advanced_count: string;
  unique_advanced_count: string;
  exit_count: string;
  unique_exit_count: string;
  completed_count: string;
  unique_completed_count: string;
};

export type JourneyEventType =
  | "entered-journey"
  | "exited-journey"
  | "exited-journey-by-criteria"
  | "moved-to-node"
  | "in-progress" // UI Only == currently in node_id
  | "completed-journey" // UI Only == exited-journey
  | "advanced-from" // UI Only == moved-to-node + from_node_id
  | "entered-tile"; // UI Only == moved-to-node + to_node_id

export function isValidJourneyEventType(
  event: string,
): event is JourneyEventType {
  return (
    event === "entered-journey" ||
    event === "exited-journey" ||
    event === "exited-journey-by-criteria" ||
    event === "moved-to-node" ||
    event === "in-progress" ||
    event === "completed-journey" ||
    event === "advanced-from" ||
    event === "entered-tile"
  );
}
