import { FC } from "react";

import {
  ButtonGroup,
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Heading,
  IconButton,
  Paragraph,
  Row,
} from "@hightouchio/ui";
import noop from "lodash/noop";
import { Controller, useFormContext } from "react-hook-form";
import { useOutletContext } from "src/router";

import { QueryBuilder } from "src/components/explore";
import {
  DiscardButton,
  Form,
  SaveButton,
  useHightouchForm,
} from "src/components/form";
import { ScheduleManager } from "src/components/schedule";
import { useScheduleState } from "src/components/schedule/schedule-manager";
import { Schedule, ScheduleType } from "src/components/schedule/types";
import { useFormErrorContext } from "src/contexts/form-error-context";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import { JourneyGraph, NodeOutletContext } from "src/pages/journeys/types";
import { JourneyExitCriteriaConfig } from "src/types/journeys";

export const SettingsForm: FC = () => {
  const {
    isEditMode,
    parentModel,
    updateJourneyPermission,
    onUpdateJourneySettings,
  } = useGraphContext();
  const { onClose } = useOutletContext<NodeOutletContext>();
  const { hasValidationErrors } = useFormErrorContext();

  const parentForm = useFormContext<JourneyGraph>();
  const { validateSchedule } = useScheduleState("journey");

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with condition types
  const schedule = parentForm.watch("journey.schedule");
  const exitCriteria = parentForm.watch("journey.exitCriteria");

  const form = useHightouchForm<{
    schedule: Schedule;
    exitCriteria: JourneyExitCriteriaConfig;
  }>({
    onSubmit: ({ exitCriteria, schedule }) => {
      if (!validateSchedule(schedule)) {
        throw new Error("Invalid schedule");
      }

      // TODO(samuel): one day transition query builder to yup validation or external validation.
      // This could be bug prone, since the query builder uses a different validation
      // approach than the yup resolvers.
      if (hasValidationErrors()) {
        throw new Error("Form has validation errors");
      }

      onUpdateJourneySettings({ schedule, exitCriteria });
      onClose();

      return Promise.resolve();
    },
    success: "Settings were saved",
    onError: () => {
      // Defined `onError` to ignore sentry tracking in `useHightouchForm`
    },
    values: {
      schedule,
      exitCriteria,
    },
  });

  return (
    <Form form={form}>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <Heading size="lg">Settings</Heading>
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody bg="base.lightBackground" pb={6}>
        <Column gap={6}>
          <Column gap={1}>
            <Heading size="md">Evaluation schedule</Heading>
            <Paragraph color="text.secondary" size="sm">
              How often the journey gets evaluated. This schedule defines how
              often new members get added and when existing members get pushed
              to the next step.
            </Paragraph>
          </Column>

          <Controller
            name="schedule"
            render={({ field }) => (
              <ScheduleManager
                isDisabled={!isEditMode}
                resource="journey"
                schedule={field.value}
                setSchedule={field.onChange}
                types={[ScheduleType.INTERVAL, ScheduleType.CUSTOM]}
                includeStartAndEnd={false} // Bounded schedules are too confusing for users.
              />
            )}
          />

          <Column gap={1}>
            <Heading size="md">Exit criteria</Heading>
            <Paragraph color="text.secondary" size="sm">
              Users who satisfy the exit criteria will immediately exit the
              journey
            </Paragraph>
          </Column>

          <Controller
            name="exitCriteria"
            render={({ field }) => (
              <QueryBuilder
                filter={field.value}
                parent={parentModel}
                setConditions={isEditMode ? field.onChange : noop}
              />
            )}
          />
        </Column>
      </DrawerBody>

      {isEditMode && (
        <DrawerFooter>
          <ButtonGroup>
            <SaveButton permission={updateJourneyPermission}>
              Update settings
            </SaveButton>
            <DiscardButton />
          </ButtonGroup>
        </DrawerFooter>
      )}
    </Form>
  );
};
