import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";
import {
  StatusBadge,
  Tooltip,
  Text,
  WarningIcon,
  ErrorIcon,
  Row,
  Column,
} from "@hightouchio/ui";
import pluralize from "pluralize";
import {
  SyncHealthOrUnknown,
  SyncHealthStatus,
  UnknownSyncHealth,
} from "src/utils/syncs";
import { DraftBadge } from "src/components/drafts/draft-badge";
import { capitalize } from "lodash";
import { formatFriendlyDistanceToNow } from "src/utils/time";

export type SyncAlertMetadata = {
  severity: MonitorStatus;
  description: string;
  happenedAt: Date;
};

const alertSeverityDisplay = {
  [MonitorStatus.Warning]: {
    color: "warning.border",
    icon: <WarningIcon color="warning.border" />,
    prefix: "Warning: ",
  },
  [MonitorStatus.Unhealthy]: {
    color: "danger.500",
    icon: <ErrorIcon color="danger.500" />,
    prefix: "Critical: ",
  },
} as const;

const badgeVariants = {
  [SyncHealthStatus.Unhealthy]: "error",
  [SyncHealthStatus.Healthy]: "success",
  [SyncHealthStatus.Warning]: "warning",
  [SyncHealthStatus.Disabled]: "inactive",
  [SyncHealthStatus.Pending]: "draft",
  [UnknownSyncHealth]: "draft",
} as const;

export const SyncAlertsBadge = ({
  alerts,
  syncEnabled,
  everRun,
  health,
  isLoading,
}: {
  alerts: SyncAlertMetadata[];
  isLoading: boolean;
  syncEnabled: boolean;
  everRun: boolean;
  health: SyncHealthOrUnknown;
}): JSX.Element => {
  const isUnhealthy =
    syncEnabled &&
    (health === SyncHealthStatus.Unhealthy ||
      health === SyncHealthStatus.Warning);

  if (!syncEnabled)
    return <StatusBadge variant="inactive">Disabled</StatusBadge>;

  if (!everRun) {
    return <DraftBadge text="Never run" />;
  }

  if (!isUnhealthy)
    return <StatusBadge variant="success">No alerts</StatusBadge>;

  if (isLoading)
    return <StatusBadge variant={badgeVariants[health]}> </StatusBadge>;

  // This case shouldn't happen, but some old syncs whose statuses changed in the early days of alerting have weird properties, so don't trip on them.
  if (isUnhealthy && alerts.length === 0) {
    return (
      <StatusBadge
        variant={health === SyncHealthStatus.Warning ? "warning" : "error"}
      >
        {capitalize(health)}
      </StatusBadge>
    );
  }

  const highestAlertLevel =
    alerts.length > 0
      ? alerts.some((alert) => alert.severity === MonitorStatus.Unhealthy)
        ? MonitorStatus.Unhealthy
        : MonitorStatus.Warning
      : undefined;

  return (
    <Tooltip
      placement="right"
      message={
        <>
          <Row w="full" color="gray.400" textAlign="left">
            {pluralize("active alert", alerts.length, true)}
          </Row>
          <Column gap={2}>
            {alerts.map((alert) => (
              <Row
                key={alert.happenedAt.toISOString()}
                borderLeft="2px solid"
                borderColor={alertSeverityDisplay[alert.severity].color}
                borderRadius="0px 4px 4px 0px"
                backgroundColor="gray.800"
                px={2}
                py={1}
                gap={2}
                w="full"
                textAlign="left"
                alignItems="baseline"
              >
                <Text size="lg">
                  {alertSeverityDisplay[alert.severity].icon}
                </Text>
                <Column gap={1}>
                  <Text size="sm" fontWeight="medium" color="white">
                    {alertSeverityDisplay[alert.severity].prefix}{" "}
                    {alert.description}
                  </Text>
                  <Text color="gray.400" size="sm">
                    {formatFriendlyDistanceToNow(
                      alert.happenedAt.toISOString(),
                    )}
                  </Text>
                </Column>
              </Row>
            ))}
          </Column>
        </>
      }
    >
      <StatusBadge
        variant={
          highestAlertLevel === MonitorStatus.Unhealthy ? "error" : "warning"
        }
      >
        {pluralize("alert", alerts.length, true)}
      </StatusBadge>
    </Tooltip>
  );
};
