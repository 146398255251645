import {
  AverageTraitConfig,
  Column,
  ColumnType,
  CountDedupedTraitConfig,
  CountTraitConfig,
  FormulaTraitConfig,
  isMergedColumn,
  OrderDedupedTraitConfig,
  RawColumn,
  RawSqlTraitConfig,
  RelatedColumn,
  SumTraitConfig,
  TraitCondition,
  TraitConfig,
  TraitType,
} from "@hightouch/lib/query/visual/types";
import { Completion } from "@codemirror/autocomplete";
import { sha256 } from "js-sha256";
import * as Yup from "yup";

import {
  FilterableColumn,
  TraitDefinition,
  getInitialTraitColumn as transformTraitColumnToRelatedColumn,
} from "src/types/visual";
import { getTraitPropertyType } from "src/components/explore/visual/utils";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import { exhaustiveCheck } from "src/types/visual";

export enum CalculationMethod {
  Aggregation = "aggregation",
  Count = "count",
  Occurrence = "occurrence",
  Sql = "sql",
  Formula = "formula",
}

export const CALCULATION_METHODS = {
  [CalculationMethod.Aggregation]: {
    label: "Aggregation",
    value: CalculationMethod.Aggregation,
    description: "Calculate the sum or average",
    examples: [
      "Sum of all customer order values",
      "Average user session length",
    ],
  },
  [CalculationMethod.Count]: {
    label: "Count",
    value: CalculationMethod.Count,
    description: "The number of occurrences",
    examples: ["Orders completed", "Page views", "User sessions"],
  },
  [CalculationMethod.Occurrence]: {
    label: "Occurrence",
    value: CalculationMethod.Occurrence,
    description: "Determine the first, last, most or least frequent",
    examples: [
      "First song listened to",
      "Last product viewed",
      "Most frequent product viewed",
      "Least frequent user login",
    ],
  },
  [CalculationMethod.Sql]: {
    label: "SQL Aggregation",
    value: CalculationMethod.Sql,
    description:
      "Aggregate across related events or entities (sums, averages, counts, arrays, etc)",
    examples: [
      "Building a JSON object",
      "Building a list",
      "Grabbing a specific field out of a JSON object",
      "Custom aggregation",
    ],
  },
  [CalculationMethod.Formula]: {
    label: "SQL Formula",
    value: CalculationMethod.Formula,
    description: "Calculate based on properties and traits with SQL",
    examples: [
      "Using LTV to classify customer loyalty tiers",
      'Creates a "true" string if the user meets a series of criteria',
    ],
  },
};

export const traitTypeToCalculationMethod = {
  [TraitType.Sum]: CalculationMethod.Aggregation,
  [TraitType.Average]: CalculationMethod.Aggregation,
  [TraitType.Count]: CalculationMethod.Count,
  [TraitType.First]: CalculationMethod.Occurrence,
  [TraitType.Last]: CalculationMethod.Occurrence,
  [TraitType.LeastFrequent]: CalculationMethod.Occurrence,
  [TraitType.MostFrequent]: CalculationMethod.Occurrence,
  [TraitType.RawSql]: CalculationMethod.Sql,
  [TraitType.Formula]: CalculationMethod.Formula,
};

export const defaultTypeByCalculationMethod = {
  [CalculationMethod.Aggregation]: TraitType.Sum,
  [CalculationMethod.Count]: TraitType.Count,
  [CalculationMethod.Occurrence]: TraitType.First,
  [CalculationMethod.Sql]: TraitType.RawSql,
  [CalculationMethod.Formula]: TraitType.Formula,
};

// Returns true if validation passes
export const validateConfig = (
  type: TraitType | undefined,
  rawConfig: TraitConfig | undefined,
): boolean => {
  if (type == undefined || rawConfig == undefined) {
    return false;
  }

  switch (type) {
    case TraitType.Sum:
      return (rawConfig as SumTraitConfig).column != undefined;
    case TraitType.Average:
      return (rawConfig as AverageTraitConfig).column != undefined;
    case TraitType.Count:
      return (rawConfig as CountTraitConfig).column != undefined;
    case TraitType.MostFrequent:
    case TraitType.LeastFrequent: {
      return (rawConfig as CountDedupedTraitConfig).toSelect != undefined;
    }
    case TraitType.First:
    case TraitType.Last: {
      const config = rawConfig as OrderDedupedTraitConfig;
      return config.toSelect != undefined && config.orderBy != undefined;
    }
    case TraitType.RawSql: {
      const config = rawConfig as RawSqlTraitConfig;
      return (
        config.aggregation != undefined && config.resultingType != undefined
      );
    }
    case TraitType.Formula: {
      const config = rawConfig as FormulaTraitConfig;
      return (
        config.transformation !== undefined &&
        config.resultingType !== undefined
      );
    }
    default:
      return false;
  }
};

export const TRAIT_TYPE_LABELS = {
  [TraitType.Count]: "Count",
  [TraitType.Sum]: "Sum",
  [TraitType.Average]: "Average",
  [TraitType.RawSql]: "Raw SQL",
  [TraitType.LeastFrequent]: "Least frequent",
  [TraitType.MostFrequent]: "Most frequent",
  [TraitType.First]: "First / Min",
  [TraitType.Last]: "Last / Max",
  [TraitType.Formula]: "Formula",
};

export const TRAIT_TYPE_OPTIONS = Object.keys(TRAIT_TYPE_LABELS).map(
  (traitType) => ({
    label: TRAIT_TYPE_LABELS[traitType],
    value: traitType,
  }),
);

export const INLINE_TRAIT_TYPE_OPTIONS = Object.keys(TRAIT_TYPE_LABELS)
  .filter(
    (traitType) =>
      // RawSql and Formula require writing SQL which isn't great UX
      // with the inline trait interface we have in the audience builder.
      // Therefore, we won't support it here. Users can just create these types
      // of traits using the trait builder.
      traitType !== TraitType.RawSql && traitType !== TraitType.Formula,
  )
  .map((traitType) => ({
    label: TRAIT_TYPE_LABELS[traitType],
    value: traitType,
  }));

export const RAW_SQL_COMMON_RES_TYPES = [
  {
    value: ColumnType.Boolean,
    label: "Boolean",
  },
  {
    value: ColumnType.Number,
    label: "Number",
  },
  {
    value: ColumnType.String,
    label: "String",
  },
  {
    value: ColumnType.Timestamp,
    label: "Timestamp",
  },
  {
    value: ColumnType.Date,
    label: "Date",
  },
];

export const RAW_SQL_BIGINT_RES_TYPES = [
  {
    value: ColumnType.BigInt,
    label: "Big Integer",
  },
];

export const RAW_SQL_JSON_ARR_RES_TYPES = [
  {
    value: ColumnType.JsonArrayNumbers,
    label: "JSON Array (Numbers)",
  },
  {
    value: ColumnType.JsonArrayStrings,
    label: "JSON Array (Strings)",
  },
];

export const InjectedColumnRegexp = /{{\s*"(.*?)"\s*}}/g;

export const getReferencedColumns = (
  formulaTransformation: string,
  parentModel?: {
    filterable_audience_columns: Pick<
      FilterableColumn,
      | "alias"
      | "name"
      | "custom_type"
      | "type"
      | "column_reference"
      | "model_name"
    >[];
    traits: TraitDefinition[];
  },
): FormulaTraitConfig["referencedColumns"] => {
  const referencedColumns: FormulaTraitConfig["referencedColumns"] = [];

  if (formulaTransformation === undefined) {
    return referencedColumns;
  }

  const formattedColumns = transformModelColumnsForFormulaTrait({
    columns: parentModel?.filterable_audience_columns ?? [],
    traits: parentModel?.traits ?? [],
  });

  const matches = formulaTransformation.matchAll(InjectedColumnRegexp);
  for (const matchReference of matches) {
    const injectedAlias = matchReference?.[1];
    if (!injectedAlias) {
      continue;
    }

    // Use the aliases created via `getColumnAlias`.
    // If we find a matching alias, add the column to the results array.
    const matchedColumn = formattedColumns.find(
      ({ alias }) => alias === injectedAlias,
    );
    if (!matchedColumn) {
      continue;
    }

    referencedColumns.push({
      alias: injectedAlias,
      column: matchedColumn.columnReference,
    });
  }

  return uniqWith(referencedColumns, isEqual);
};

const hasEmptyConditions = (config: TraitConfig) => {
  return config.conditions?.every(
    (condition) => condition.conditions.length === 0,
  );
};

export const parseTraitConfig = (
  type: TraitType,
  rawConfig: TraitConfig,
): {
  aggregatedColumn?: RawColumn | RelatedColumn;
  orderByColumn?: RawColumn | RelatedColumn;
  aggregation?: string;
  transformation?: string;
  conditions?: TraitCondition[];
} => {
  switch (type) {
    case TraitType.Sum: {
      const config = rawConfig as SumTraitConfig;
      return {
        aggregatedColumn: config.column,
        conditions: config.conditions,
      };
    }
    case TraitType.Average: {
      const config = rawConfig as SumTraitConfig;
      return {
        aggregatedColumn: config.column,
        conditions: config.conditions,
      };
    }
    case TraitType.Count: {
      const config = rawConfig as CountTraitConfig;
      return {
        aggregatedColumn: config.column,
        conditions: config.conditions,
      };
    }
    case TraitType.MostFrequent:
    case TraitType.LeastFrequent: {
      const config = rawConfig as CountDedupedTraitConfig;
      return {
        aggregatedColumn: config.toSelect,
        conditions: config.conditions,
      };
    }
    case TraitType.First:
    case TraitType.Last: {
      const config = rawConfig as OrderDedupedTraitConfig;
      return {
        aggregatedColumn: config.toSelect,
        orderByColumn: config.orderBy,
        conditions: config.conditions,
      };
    }
    case TraitType.RawSql: {
      const config = rawConfig as RawSqlTraitConfig;
      return {
        aggregation: config.aggregation,
        conditions: config.conditions,
      };
    }
    case TraitType.Formula: {
      const config = rawConfig as FormulaTraitConfig;
      return {
        aggregation: config.transformation,
      };
    }
    default:
      exhaustiveCheck(type);
  }
};

export const formatTraitConfig = (
  type: TraitType,
  config: TraitConfig,
  parentModel?: {
    filterable_audience_columns: FilterableColumn[];
    traits: TraitDefinition[];
  },
): TraitConfig => {
  if (type === TraitType.Formula) {
    const formulaConfig = config as FormulaTraitConfig;
    return {
      ...formulaConfig,
      referencedColumns: getReferencedColumns(
        formulaConfig.transformation,
        parentModel,
      ),
    };
  }

  if (hasEmptyConditions(config)) {
    return {
      ...config,
      conditions: [],
    };
  }

  return config;
};

// We use the column reference to generate unique aliases that can be injected within
// the formula trait SQL.
// These aliases will be used in the generated warehouse SQL, so we have to be cognizant
// of using legal characters. The tested warehouses accept alphanumeric characters,
// we'll use those.
export const getColumnAlias = (columnReference: Column): string => {
  // SHA-256 returns 64 characters.
  // When we generate the SQL in the backend, we also append a suffix to the alias to make it unique.
  // Most warehouses have a max alias length of ~64 characters. Therefore, we should ensure that the
  // ones we generate are well below this limit.
  // Even with truncation, hexadecimal gives each character 16 possibilities, so that should
  // still be unique enough to avoid collisions with other aliases generated via this helper.

  const deepSortKeys = (_key: string, value: unknown) => {
    if (value && typeof value === "object" && !Array.isArray(value)) {
      // Sort the keys of the object
      return Object.keys(value)
        .sort()
        .reduce((sortedObj, currentKey) => {
          sortedObj[currentKey] = value[currentKey];
          return sortedObj;
        }, {});
    }
    return value;
  };

  return sha256
    .create()
    .update(
      // Objects don't have ordering, so when we stringify the object, make
      // sure the keys are ordered to in order to generate consistent strings
      JSON.stringify(columnReference, deepSortKeys),
    )
    .hex()
    .slice(0, 8);
};

export type FormulaColumnAutocompletion = Completion & {
  // Custom fields needed to decorate the edtior's Pill and referenced column resolution
  alias: string;
  columnReference: Column;
  isParent: boolean;
  isMerged: boolean;
  isTrait: boolean;
};

export const transformModelColumnsForFormulaTrait = (args: {
  columns: Pick<
    FilterableColumn,
    | "alias"
    | "name"
    | "custom_type"
    | "type"
    | "column_reference"
    | "model_name"
  >[];
  traits: TraitDefinition[];
}): FormulaColumnAutocompletion[] => {
  const { columns, traits } = args;

  const formattedColumns: {
    name: string;
    type: string;
    columnReference: Column;
    modelName?: string;
    isParent: boolean;
    isMerged: boolean;
    isTrait: boolean;
  }[] = [
    ...columns.map((c) => ({
      name: c.alias ?? c.name,
      type: c.custom_type ?? c.type,
      columnReference: c.column_reference as Column,
      modelName: c.model_name,
      isParent: !isMergedColumn(c.column_reference),
      isMerged: isMergedColumn(c.column_reference),
      isTrait: false,
    })),
    // Filter out transformed columns because they can't be referenced within a formula trait.
    // Then convert the trait definition into a related column definition.
    ...traits
      .filter((t) => t.type !== TraitType.Formula)
      .map((t) => ({
        name: t.name,
        type: getTraitPropertyType(t),
        columnReference: transformTraitColumnToRelatedColumn({
          ...t,
          // Thee traits should have empty conditions. We do some transformations
          // specifically for the audience builder, but the backend will correctly apply
          // the conditions since we look up the trait.
          // Setting non-empty conditions here is also prone to staleness since we
          // persist these colunns and hash them.
          config: { ...t.config, conditions: [] },
        }),
        isParent: false,
        isMerged: false,
        isTrait: true,
      })),
  ];

  return formattedColumns.map(
    ({
      columnReference,
      name,
      type,
      modelName,
      isParent,
      isMerged,
      isTrait,
    }): FormulaColumnAutocompletion => {
      const alias = getColumnAlias(columnReference);
      return {
        // CodeMirror autocomplete will filter out duplicate labels, so they won't all show up in the suggetions.
        // We can get into this scenario if there is a merged column with the same name as a parent model column.
        // We'll dedupe them by sufficing the label. This doesn't look great aesthetically, so we should find
        // a better workaround if possible.
        label: isMerged ? `${name} (${modelName})` : name,
        // This is the text that will be injected upon selecting an autocompletion option
        // but will be hidden from the user behind a pill. The injected text is Handlebars syntax which
        // our backend SQL generation code understands.
        apply: `{{"${alias}"}}`,
        alias,
        columnReference,
        type,
        isParent,
        isMerged,
        isTrait,
        section: {
          name: isTrait
            ? "Traits"
            : isMerged
              ? "Merged columns"
              : "Parent model",
          header: ({ name }) => {
            const el = document.createElement("div");
            el.setAttribute(
              "style",
              "padding: 8px; font-weight: var(--chakra-fontWeights-medium)",
            );
            el.appendChild(document.createTextNode(name));
            return el;
          },
        },
      };
    },
  );
};

// TODO(samuel): remove this function once audience builder is upgraded to use 'yup'.
// Have to do this to trigger the validation in the trait conditions
export const validateTraitConfig = async (
  hasValidationErrorsFn: () => boolean,
  toast: (options: any) => void,
) => {
  const validationErrors = hasValidationErrorsFn();

  if (validationErrors) {
    toast({
      id: "trait-validation-error",
      title: "Trait has validation errors",
      message: "Please fix the errors and try again.",
      variant: "error",
    });

    return Promise.reject();
  }

  return Promise.resolve();
};

export const traitNameValidator = (
  connectionSourceType: string | undefined,
) => {
  const shared = Yup.string().required("Trait name required");

  switch (connectionSourceType) {
    case "databricks":
      return shared.test(
        "validate-trait-name-characters",
        "Databricks trait names must not contain any of the following characters ' ,;{}()\\n\\t='",
        (value) => {
          return !value?.match(/[ ,;{}()\n\t=]/g);
        },
      );
    default:
      return shared;
  }
};
