import { FC, useEffect, useMemo } from "react";

import { TraitType } from "@hightouch/lib/query/visual/types";
import {
  Box,
  ChakraListItem,
  ChakraUnorderedList,
  Column,
  Combobox,
  ControlledWizardStep,
  FormField,
  Radio,
  RadioGroup,
  Select,
  Text,
  Textarea,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import orderBy from "lodash/orderBy";
import { Controller, FormProvider } from "react-hook-form";
import { Link, useNavigate } from "src/router";
import * as Yup from "yup";

import { WizardDrawer } from "src/components/drawer";
import { Form, useHightouchForm } from "src/components/form";
import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  TraitDefinitionsConstraint,
  TraitDefinitionsInsertInput,
  useCreateTraitMutation,
  useParentModelForTraitQuery,
  useParentModelsForTraitsQuery,
} from "src/graphql";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

import { CalculationSummary } from "./calculation-summary";
import { DUPLICATE_TRAIT_NAME_ERROR_MESSAGE } from "./constants";
import { TraitCalculationForm } from "./trait-calculation-form";
import {
  CALCULATION_METHODS,
  CalculationMethod,
  defaultTypeByCalculationMethod,
  formatTraitConfig,
  validateConfig,
  validateTraitConfig,
  traitNameValidator,
} from "./utils";

type Props = {
  isTemplate: boolean;
};

export const validationSchema = Yup.lazy<
  TraitDefinitionsInsertInput & { sourceType: string | undefined }
>((trait) => {
  return Yup.object()
    .shape({
      name: traitNameValidator(trait.sourceType),
      description: Yup.string().nullable(),
      parent_model_id: Yup.string().required("Parent model is required"),
      relationship_id:
        trait.type === TraitType.Formula
          ? Yup.string().nullable()
          : Yup.string().required("Related model or event is required"),
      type: Yup.string().required("Related model or event is required"),
      config: Yup.object().required(),
      trait_template_id: Yup.string().nullable(),
      is_template: Yup.boolean().required(),
      // this is used for local form state not the server
      sourceType: Yup.string(),
    })
    .required();
});

export const CreateTrait: FC<Readonly<Props>> = ({ isTemplate }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { appEnableFormulaTraits } = useFlags();

  const { hasValidationErrors } = useFormErrorContext();

  const createTrait = useCreateTraitMutation();
  const [activeStep, setActiveStep] = useWizardStepper(0);

  const form = useHightouchForm({
    defaultValues: {
      name: "",
      description: "",
      parent_model_id: undefined,
      relationship_id: undefined,
      calculation_method: CalculationMethod.Aggregation,
      type: TraitType.Count,
      config: {},
      is_template: isTemplate,
      sourceType: "",
    },
    resolver: yupResolver(validationSchema),
    onSubmit: async (formData) => {
      if (!parentModel) {
        return;
      }

      try {
        const newTrait = await createTrait.mutateAsync({
          input: {
            name: formData.name,
            description: formData.description,
            parent_model_id: formData.parent_model_id,
            relationship_id: formData.relationship_id,
            type: formData.type,
            config: formatTraitConfig(
              formData.type,
              formData.config,
              parentModel,
            ),
            is_template: isTemplate,
          },
        });

        const newTraitId = newTrait.insert_trait_definitions_one?.id;
        navigate(
          `/traits/${isTemplate ? "templates" : "active"}/${newTraitId}`,
        );
      } catch (error) {
        // Add a more helpful error message
        throw error.message.includes(
          TraitDefinitionsConstraint.TraitDefinitionsNameParentModelIdKey,
        )
          ? new Error(DUPLICATE_TRAIT_NAME_ERROR_MESSAGE)
          : error;
      }
    },
    onError: (error) => {
      if (
        error.message !== DUPLICATE_TRAIT_NAME_ERROR_MESSAGE &&
        !hasValidationErrors()
      ) {
        captureException(error);
      }
    },
  });

  const parentModelId = form.watch("parent_model_id");
  const calculationMethod = form.watch("calculation_method");
  const relationshipId = form.watch("relationship_id");
  const type = form.watch("type");
  const config = form.watch("config");
  const name = form.watch("name");

  const { data: minimalParentModels, isLoading: isMinimalParentModelsLoading } =
    useParentModelsForTraitsQuery(undefined, {
      select: (data) => data.segments,
    });

  const { data: parentModel, isLoading: isParentModelLoading } =
    useParentModelForTraitQuery(
      { parentModelId: parentModelId ?? "" },
      {
        enabled: Boolean(parentModelId),
        select: (data) => data.segments_by_pk,
      },
    );

  const relationship = useMemo(() => {
    return parentModel?.relationships.find(({ id }) => id === relationshipId);
  }, [parentModel, relationshipId]);

  useEffect(() => {
    form.setValue("sourceType", parentModel?.connection?.type || "");
  }, [parentModel?.connection?.type]);

  const onClose = () => {
    if (isTemplate) {
      navigate("/traits/templates");
    } else {
      navigate("/traits/active");
    }
  };

  const parentModelOptions =
    orderBy(
      minimalParentModels?.map((segment) => {
        const relationshipCount = segment.relationships.length;

        return {
          label: segment.name,
          description:
            relationshipCount === 0 ? "No related or event models" : undefined,
          value: segment.id,
          logo: segment.connection?.definition.icon || "",
          isDisabled: relationshipCount === 0,
        };
      }),
      ["isDisabled", "label"],
      ["asc", "asc"],
    ) ?? [];

  const handleChangeCalculationMethod = (
    calculationMethod: CalculationMethod,
  ) => {
    const values = form.getValues();

    form.reset({
      ...values,
      calculation_method: calculationMethod,
      type: defaultTypeByCalculationMethod[calculationMethod],
      config: {},
    });
  };

  const ParentModelSelect = parentModelOptions.length > 10 ? Combobox : Select;

  const parentOrRelationshipsRequired = useMemo(() => {
    if (!parentModelId) {
      return true;
    }

    if (calculationMethod === CalculationMethod.Formula) {
      return false;
    }

    const parent = minimalParentModels?.find(
      (s) => s.id.toString() === String(parentModelId),
    );
    return !parent || parent.relationships.length === 0;
  }, [minimalParentModels, parentModelId, calculationMethod]);

  const steps: ControlledWizardStep[] = [
    {
      label: "Method",
      continue: "Continue to calculation",
      isDisabled:
        !parentModel || !calculationMethod || parentOrRelationshipsRequired,
      tooltip: parentOrRelationshipsRequired
        ? "Please select a parent model with at least one relationship"
        : undefined,
      submit: () => setActiveStep(1),
      render: () => (
        <Column gap={6} width="100%">
          <Column gap={2}>
            <Text fontWeight="medium">Create a new trait</Text>
            <Text>
              A trait lets you perform a calculation on a column of a
              related/event model. It acts as a new column on a parent model and
              can be utilized in associated Audiences.{" "}
              <Link
                href={`${import.meta.env.VITE_DOCS_URL}/customer-studio/traits`}
                isExternal
              >
                Learn more.
              </Link>
            </Text>
          </Column>
          <FormField
            label="Parent model"
            tip="This trait will be available in the audiences of this parent model."
          >
            <Controller
              control={form.control}
              name="parent_model_id"
              render={({ field }) => (
                <ParentModelSelect
                  isDisabled={isMinimalParentModelsLoading}
                  isLoading={
                    isMinimalParentModelsLoading || isParentModelLoading
                  }
                  options={parentModelOptions}
                  optionAccessory={(option: any) => ({
                    type: "image",
                    url: option.logo,
                  })}
                  placeholder="Select a parent model"
                  value={field.value}
                  width="100%"
                  onChange={field.onChange}
                />
              )}
            />
          </FormField>
          <FormField label="Calculation method">
            <Controller
              control={form.control}
              name="calculation_method"
              render={({ field }) => (
                <Box
                  display="grid"
                  gridTemplateColumns="60% 40%"
                  gap={1}
                  width="100%"
                >
                  <RadioGroup
                    value={field.value}
                    onChange={(value) =>
                      handleChangeCalculationMethod(value as CalculationMethod)
                    }
                  >
                    {Object.values(CALCULATION_METHODS)
                      .filter(
                        (calculationMethod) =>
                          appEnableFormulaTraits ||
                          calculationMethod.value !== CalculationMethod.Formula,
                      )
                      .map(({ label, value, description }) => (
                        <Radio
                          key={label}
                          label={label}
                          description={description}
                          value={value}
                        />
                      ))}
                  </RadioGroup>
                  {field.value && (
                    <Column bg="base.lightBackground" p={4}>
                      <Text color="text.secondary" fontWeight="medium">
                        Examples:
                      </Text>

                      <ChakraUnorderedList>
                        {CALCULATION_METHODS[field.value]?.examples.map(
                          (tip: string) => (
                            <ChakraListItem key={tip}>{tip}</ChakraListItem>
                          ),
                        )}
                      </ChakraUnorderedList>
                    </Column>
                  )}
                </Box>
              )}
            />
          </FormField>
        </Column>
      ),
    },
    {
      label: "Calculation",
      continue: "Continue to finalize",
      isDisabled: type !== TraitType.Formula && !relationshipId,
      submit: async () => {
        try {
          await validateTraitConfig(
            () => hasValidationErrors() || !validateConfig(type, config),
            toast,
          );
          setActiveStep(2);
        } catch {
          // Don't progress if there are validation errors
        }
      },
      render: () => (
        <>
          {parentModel && (
            <FormProvider {...form}>
              <TraitCalculationForm
                hideCalculationMethod
                parentModel={parentModel}
                onPreviewValidation={() =>
                  validateTraitConfig(hasValidationErrors, toast)
                }
              />
            </FormProvider>
          )}
        </>
      ),
    },
    {
      label: "Finalize",
      continue: isTemplate ? "Save template" : "Create trait",
      isDisabled: !name,
      submit: form.submit,
      render: () => (
        <Column gap={6}>
          <Controller
            control={form.control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <FormField
                label="Name"
                error={error?.message}
                tip="Shows up in the audience query builder when selecting from a list of traits."
              >
                <TextInput
                  placeholder="Enter a name"
                  isInvalid={Boolean(error)}
                  width="100%"
                  {...field}
                />
              </FormField>
            )}
          />
          <Controller
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormField isOptional label="Description">
                <Textarea
                  placeholder="Enter a description"
                  width="100%"
                  {...field}
                />
              </FormField>
            )}
          />

          {parentModel && (
            <Column>
              <Text fontWeight="medium" mb={1}>
                Calculation summary
              </Text>
              <Box
                borderRadius="6px"
                border="1px solid"
                borderColor="base.border"
                p={4}
              >
                <CalculationSummary
                  type={type}
                  config={config}
                  parentModel={parentModel}
                  relatedModel={relationship?.to_model}
                />
              </Box>
            </Column>
          )}
        </Column>
      ),
    },
  ];

  return (
    <Form form={form}>
      <WizardDrawer
        isOpen
        isDirty={form.formState.isDirty}
        activeStep={activeStep}
        onStepChange={setActiveStep}
        steps={steps}
        title={isTemplate ? "New template" : "New trait"}
        size="xl"
        onClose={onClose}
      />
    </Form>
  );
};
