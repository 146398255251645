import {
  MergedSyncRequestEventSpan,
  MergedSyncRequestEventStatus,
  SyncRunPhasesQuery,
} from "src/graphql";
import type { ElementOf } from "ts-essentials";

import { Context } from "..";

export type SyncRunPhase = ElementOf<
  SyncRunPhasesQuery["getSyncRequestEvents"]
>;
export type SyncRequest = Context["syncRequest"];

export type SyncPhase = SyncRunPhase & {
  span: typeof MergedSyncRequestEventSpan.Sync;
};

export type GroupedPhaseData = {
  syncPhase: SyncPhase;
  topLevelErrors: { message: string }[];
  groups: {
    name: string;
    phases: SyncRunPhase[];
  }[];
};

export const EMPTY_SYNC_PHASE: SyncPhase = {
  span: MergedSyncRequestEventSpan.Sync,
  status: MergedSyncRequestEventStatus.NotStarted,
  startTime: null,
  endTime: null,
  errors: null,
  durationMillis: { self: 0, total: 0 },
};

export function isSyncPhase(phase: SyncRunPhase): phase is SyncPhase {
  return phase.span === MergedSyncRequestEventSpan.Sync;
}

export function phaseFailedWithErrors(
  phase: SyncRunPhase,
): phase is SyncRunPhase & { errors: NonNullable<SyncRunPhase["errors"]> } {
  return (
    phase.status === MergedSyncRequestEventStatus.Failed &&
    phase.errors !== null &&
    phase.errors.length > 0
  );
}
