import { ChannelType } from "@hightouch/lib/resource-monitoring/types";
import {
  Row,
  Column,
  SectionHeading,
  Text,
  Button,
  Box,
  IconButton,
  PlusIcon,
} from "@hightouchio/ui";
import { capitalize } from "lodash";
import { FC, useState } from "react";

import {
  ChannelDefinition,
  channelName,
} from "src/components/notification-channels/channel-definitions";
import {
  useWorkspaceNotificationChannelsQuery,
  useAlertingCredentialsQuery,
  WorkspaceNotificationChannelsQuery,
  useCreateWorkspaceNotificationChannelMutation,
} from "src/graphql";
import { ChannelMenu } from "./channel-menu";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useNavigate, useSearchParams } from "src/router";
import {
  ChannelData,
  CreateRecipientModal,
} from "src/components/resource-alert-triggers/create-recipient-modal";

export const RecipientChannels: FC = () => {
  const navigate = useNavigate();
  const [createChannelType, setCreateChannelType] = useState<ChannelType>();

  const { data: workspaceChannels } = useWorkspaceNotificationChannelsQuery(
    undefined,
    {
      suspense: true,
      select: (data) => data.workspace_notification_channels,
    },
  );

  const { data: alertingCredentials } = useAlertingCredentialsQuery(undefined, {
    suspense: true,
  });

  const { mutateAsync: createWorkspaceNotificationChannel } =
    useCreateWorkspaceNotificationChannelMutation();

  const onCreateRecipient = async ({ config, channelType }: ChannelData) => {
    const res = await createWorkspaceNotificationChannel({
      object: {
        channel_type: channelType,
        config,
      },
    });

    const channelId = res.insert_workspace_notification_channels_one?.id;

    if (!channelId) {
      throw new Error("Failed to create recipient");
    }

    navigate(`/alerting/recipients?channel=${channelId}`);
  };

  const requiresSetup = (channelType: ChannelType) => {
    switch (channelType) {
      case ChannelType.Slack:
        return (alertingCredentials?.slack_credentials?.length || 0) === 0;
      case ChannelType.PagerDuty:
        return (alertingCredentials?.pagerduty_credentials?.length || 0) === 0;
      default:
        return false;
    }
  };

  return (
    <Column
      borderRight="1px"
      borderColor="base.border"
      height="100%"
      width="300px"
      overflow="auto"
    >
      {Object.values(ChannelType).map((channelType) => {
        const content = (() => {
          if (requiresSetup(channelType)) {
            return <UnconnectedChannel type={channelType} />;
          }
          const channelsForType = workspaceChannels?.filter(
            (channel) => channel.channel_type === channelType,
          );
          return (
            <ConnectedChannel
              type={channelType}
              channels={channelsForType ?? []}
              onCreateRecipient={() => setCreateChannelType(channelType)}
            />
          );
        })();
        return (
          <Row
            key={channelType}
            w="100%"
            borderBottom="1px"
            borderColor="base.border"
          >
            {content}
          </Row>
        );
      })}
      <CreateRecipientModal
        isOpen={Boolean(createChannelType)}
        onClose={() => setCreateChannelType(undefined)}
        initialChannelType={createChannelType}
        onCreate={onCreateRecipient}
      />
    </Column>
  );
};

const ConnectedChannel = ({
  type,
  channels,
  onCreateRecipient,
}: {
  type: ChannelType;
  channels: WorkspaceNotificationChannelsQuery["workspace_notification_channels"];
  onCreateRecipient: () => void;
}) => {
  const [search, setSearchParams] = useSearchParams();
  const definition = ChannelDefinition[type];

  return (
    <Column gap={2} flex={1} px={2} py={4} minW={0}>
      <Row align="center" gap={2} justify="space-between">
        <Row gap={2} align="center">
          <Box as={definition.icon} w="24px" h="24px" />
          <SectionHeading>
            {definition.label || capitalize(type)}
          </SectionHeading>
        </Row>
        <Row gap={2}>
          <ChannelMenu channelType={type} />
          <IconButton
            size="sm"
            icon={PlusIcon}
            aria-label="Add recipient"
            onClick={onCreateRecipient}
          />
        </Row>
      </Row>
      {channels.length > 0 && (
        <Column pl={6}>
          {channels.map((channel) => {
            const name = channelName(channel);
            const isSelected = search.get("channel") === channel.id;
            return (
              <Row
                as="button"
                key={channel.id}
                p={2}
                borderRadius="md"
                bg={isSelected ? "gray.100" : undefined}
                onClick={() => setSearchParams({ channel: channel.id })}
                _hover={{
                  bg: "gray.100",
                }}
              >
                <TextWithTooltip>{name}</TextWithTooltip>
              </Row>
            );
          })}
        </Column>
      )}
    </Column>
  );
};

const UnconnectedChannel = ({ type }: { type: ChannelType }) => {
  const definition = ChannelDefinition[type];
  const navigate = useNavigate();

  return (
    <Row
      bg="gray.50"
      justify="space-between"
      gap={4}
      align="center"
      flex={1}
      px={2}
      py={4}
    >
      <Row gap={2} align="center">
        <definition.icon />
        <Text color="text.secondary" fontWeight="medium" size="lg">
          {capitalize(type)}
        </Text>
      </Row>
      <Button
        size="sm"
        onClick={() => {
          if (type === ChannelType.Slack) {
            window.location.href = `${
              import.meta.env.VITE_API_BASE_URL
            }/slack-alerts/oauth`;
          } else {
            navigate(`/alerting/recipients/${type}/manage`);
          }
        }}
      >
        {type === ChannelType.Slack ? "Authorize..." : "Setup..."}
      </Button>
    </Row>
  );
};
