import { FC, useMemo } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Form as FormkitForm } from "src/formkit/components/form";
import { ProcessFormNode } from "src/formkit/formkit";
import { ProviderSection } from "src/components/sources/setup/providers";
import {
  SharedFormMethodProps,
  Tunnel,
} from "src/components/sources/setup/form-method";
import { Section } from "src/formkit/components";
/**
 * Forked from packages/app/src/components/sources/setup/form-method.tsx.
 */
export const FormMethod: FC<Readonly<SharedFormMethodProps>> = ({
  definition,
  config,
  setupMethods,
  tunnelId,
  setTunnelId,
  credentialId,
  setCredentialId,
}) => {
  const { sourceSnowflakeEnableTunnel } = useFlags();
  const matchingDestination = setupMethods?.find(
    (o) => o.key === config?.methodKey,
  );

  const Form = useMemo(
    () =>
      matchingDestination?.form && (
        <FormkitForm compact>
          <ProcessFormNode node={matchingDestination.form} />
        </FormkitForm>
      ),
    [matchingDestination?.key],
  );

  const CredentialsForm = useMemo(
    () =>
      matchingDestination?.credentialsForm && (
        <FormkitForm compact>
          <ProcessFormNode node={matchingDestination.credentialsForm} />
        </FormkitForm>
      ),
    [matchingDestination?.key],
  );

  const showTunneling =
    // Does the destination form support tunneling?
    matchingDestination?.tunneling &&
    // If the destination is Snowflake, check that the user has the matching feature flag.
    (definition.type !== "snowflake" ||
      (definition.type === "snowflake" && sourceSnowflakeEnableTunnel));

  return (
    <>
      {showTunneling && (
        <Section
          heading="Choose your connection type"
          subheading={`Hightouch can connect directly to ${definition.name} if it's exposed to the internet. However, if you need to open a connection within a private network or VPC, you will need to set up an SSH tunnel.`}
        >
          <Tunnel
            name={definition.name}
            value={tunnelId}
            onChange={setTunnelId}
          />
        </Section>
      )}
      {matchingDestination?.provider && (
        <Section heading="Configure your credentials">
          <ProviderSection
            credentialId={credentialId}
            provider={matchingDestination.provider ?? definition.name}
            setCredentialId={setCredentialId}
          />
        </Section>
      )}
      <Section heading={`Configure your ${definition.name} destination`}>
        {Form}
      </Section>
      <Section heading={`Provide your ${definition.name} credentials`}>
        {CredentialsForm}
      </Section>
    </>
  );
};
