import {
  Badge,
  Column,
  InformationIcon,
  Row,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { syncRunStatusOrUnknownStatus } from "src/utils/syncs";
import { SyncRequest } from "./types";
import { SyncRunStatusIndicator } from "src/components/syncs/sync-run-status-indicator";
import { syncRunStatusDurationText } from "src/pages/syncs/sync/components/utils";
import { MetadataBar, MetadataLabel } from "src/components/metadata-bar";
import * as time from "src/utils/time";

const RESYNC_REASON_LABELS: Record<string, string> = {
  "explicit-request": "Triggered by user",
  "added-fields": "Fields were added",
  "changed-config": "Configuration was changed",
  "changed-mappings": "Mappings changed",
  "changed-source-types": "Source types changed",
  "changed-primary-key": "Model primary key was changed",
  "retry-failed-resync": "Retrying failed resync",
  "archive-mode-resync": "Resyncing for archive mode",
  "next-diff-mode-override": "Next sync run override",
} as const;

export const RunStatusHeader = ({
  syncRequest,
}: {
  syncRequest: SyncRequest;
}) => {
  const resyncReason = syncRequest.resync_reason;
  const status = syncRunStatusOrUnknownStatus(syncRequest.status_computed);

  return (
    <Row alignItems="center" gap={2}>
      <Row gap={1} alignItems="flex-end">
        <SyncRunStatusIndicator
          status={status}
          completionRatio={syncRequest.completion_ratio}
          labelProps={{
            size: "lg",
            fontWeight: "medium",
          }}
        />
        <Text color="text.secondary" size="lg">
          {syncRunStatusDurationText(
            status,
            syncRequest.created_at,
            syncRequest.finished_at,
          )}
        </Text>
      </Row>
      <Badge>Run ID: {syncRequest.id}</Badge>
      {resyncReason && (
        <Tooltip message={RESYNC_REASON_LABELS[resyncReason] ?? resyncReason}>
          <Badge>
            Resync <InformationIcon />
          </Badge>
        </Tooltip>
      )}
    </Row>
  );
};

export const LegacyMetadataRunStatusHeader = ({
  syncRequest,
}: {
  syncRequest: SyncRequest;
}) => {
  const resyncReason = syncRequest.resync_reason;

  return (
    <MetadataBar>
      <Column>
        <MetadataLabel>Run status</MetadataLabel>
        <SyncRunStatusIndicator
          status={syncRunStatusOrUnknownStatus(syncRequest.status_computed)}
          completionRatio={syncRequest.completion_ratio || null}
        />
      </Column>
      <Column>
        <MetadataLabel>Started at</MetadataLabel>
        <Text>{time.formatDatetime(syncRequest.created_at)}</Text>
      </Column>
      {syncRequest.finished_at && (
        <Column>
          <MetadataLabel>Duration</MetadataLabel>
          <Text textTransform="capitalize">
            {time.diff(syncRequest.created_at, syncRequest.finished_at)}
          </Text>
        </Column>
      )}
      <Column>
        <MetadataLabel>Run ID</MetadataLabel>
        <Text>{syncRequest.id}</Text>
      </Column>
      {resyncReason && (
        <Column>
          <MetadataLabel>Resync reason</MetadataLabel>
          <Text>{RESYNC_REASON_LABELS[resyncReason] ?? resyncReason}</Text>
        </Column>
      )}
    </MetadataBar>
  );
};
