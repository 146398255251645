import {
  Button,
  Checkbox,
  EditableDescription,
  EditableHeading,
  EditableText,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  TooltipProps,
} from "@hightouchio/ui";

import { LinkButton } from "src/router";
import {
  PermissionProps,
  ResourceToPermission as V1ResourceToPermission,
} from "./types";
import { V2PermissionProps, V2ResourceToPermission } from "./types-v2";
import {
  OrganizationPermissionInput,
  useOrganizationPermission,
} from "./use-organization-permissions";
import {
  ResourcePermissionInput,
  useResourcePermission,
} from "./use-resource-permission";
import { DeleteButton } from "src/components/delete-button";

interface Props<
  V2Resource extends V2ResourceToPermission,
  V1Resource extends V1ResourceToPermission,
> {
  placement?: TooltipProps["placement"];
  permission?: ResourcePermissionInput<V2Resource, V1Resource>;
  organizationPermission?: OrganizationPermissionInput;
  /**
   * Tooltip for the component, always shown
   */
  tooltip?: string | boolean;
  /**
   * Tooltip to show in the event permissions are insufficient
   * @default "You do not have permission to perform this action"
   */
  unauthorizedTooltip?: string;
}

// WrappedComponent must forwardRef and pass through all props
export function withPermission<P extends { isDisabled?: boolean }>(
  WrappedComponent: React.ComponentType<P>,
) {
  function HOC<
    V2Resource extends V2ResourceToPermission,
    V1Resource extends V1ResourceToPermission,
  >({
    permission,
    organizationPermission,
    tooltip,
    unauthorizedTooltip,
    placement = "auto",
    ...props
  }: Props<V2Resource, V1Resource> & P) {
    const resourcePermission = useResourcePermission(permission);
    const orgPermission = useOrganizationPermission(organizationPermission);

    if (permission || organizationPermission) {
      const isPermitted = permission
        ? resourcePermission.isPermitted
        : orgPermission;

      const defaultUnauthorizedTooltip = permissionTooltipCopy(
        permission,
        organizationPermission,
      );

      return (
        <Tooltip
          isDisabled={
            tooltip ? false : resourcePermission.isLoading ? true : isPermitted
          }
          message={
            isPermitted
              ? String(tooltip)
              : (unauthorizedTooltip ?? defaultUnauthorizedTooltip)
          }
          placement={placement}
        >
          <WrappedComponent
            {...(props as P)}
            isDisabled={!isPermitted || props.isDisabled}
          />
        </Tooltip>
      );
    }

    if (tooltip) {
      return (
        <Tooltip message={String(tooltip)}>
          <WrappedComponent {...(props as P)} />
        </Tooltip>
      );
    }
    return <WrappedComponent {...(props as P)} />;
  }

  HOC.displayName = `WithPermission(${
    WrappedComponent?.displayName || WrappedComponent?.name || "Component"
  })`;

  return HOC;
}

const permissionTooltipCopy = (
  permission:
    | ResourcePermissionInput<V2ResourceToPermission, V1ResourceToPermission>
    | undefined,
  organizationPermission: OrganizationPermissionInput | undefined,
): string => {
  if (permission) {
    return resourcePermissionCopy(permission);
  }

  if (organizationPermission) {
    return organizationPermissionCopy(organizationPermission);
  }

  return "You do not have permission to perform this action";
};

const resourcePermissionCopy = (
  permission: ResourcePermissionInput<
    V2ResourceToPermission,
    V1ResourceToPermission
  >,
): string => {
  const { action, resource, hasId } =
    // Try to use v2 grant to generate copy first, otherwise fallback to v1
    "v2" in permission
      ? {
          action: v2GrantAction[permission.v2.grant],
          resource: permission.v2.resource,
          hasId: "id" in permission.v2,
        }
      : {
          action: v1GrantAction[permission.v1.grant],
          resource: permission.v1.resource,
          hasId: "id" in permission.v1,
        };

  // Special case when the resource is a workspace, it helps make the action a little more clear
  return resource === "workspace"
    ? "You need to be a workspace admin to perform this action"
    : `You do not have permission to ${action} ${
        hasId ? "this" : "a"
      } ${resource}`;
};

const organizationPermissionCopy = ({
  resource,
}: OrganizationPermissionInput): string => {
  return `You need to be ${
    resource === "organization" ? "an" : "a"
  } ${resource} admin to perform this action`;
};

const v2GrantAction: Record<
  V2PermissionProps<V2ResourceToPermission>["grant"],
  string
> = {
  can_read: "read",
  can_update: "update",
  can_delete: "delete",
  can_approve: "approve",
  can_preview: "preview",
  can_test: "test",
  can_debug: "debug",
  can_run: "run",
  can_create: "create",
};

const v1GrantAction: Record<
  PermissionProps<V1ResourceToPermission>["grant"],
  string
> = {
  create: "create",
  read: "read",
  update: "update",
  delete: "delete",
  approve: "approve",
  start: "start",
  testrow: "test",
  debugger: "debug",
  enable: "enable",
  preview: "preview",
};

export const PermissionedButton = withPermission(Button);
export const PermissionedLinkButton = withPermission(LinkButton);
export const PermissionedMenuItem = withPermission(MenuItem);
export const PermissionedIconButton = withPermission(IconButton);
export const PermissionedSwitch = withPermission(Switch);
export const PermissionedEditableHeading = withPermission(EditableHeading);
export const PermissionedEditableDescription =
  withPermission(EditableDescription);
export const PermissionedEditableText = withPermission(EditableText);
export const PermissionedCheckbox = withPermission(Checkbox);
export const PermissionedSelect = withPermission(Select);
export const PermissionedDeleteButton = withPermission(DeleteButton);
