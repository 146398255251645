import { FC, MouseEvent, ReactElement, ReactNode, useCallback } from "react";

import { Text, Box, BoxProps, SystemStyleObject } from "@hightouchio/ui";

import { OrderBy } from "src/graphql";

import { SortIcon } from "./sortIcon";
import { TableColumn } from "./table";

export type HeaderCellProps = {
  children: ReactNode;
  onClick?: (event: MouseEvent) => void;
  sortDirection?: OrderBy | null;
  top?: number | string;
  isSortMenu?: boolean;
  isDense?: boolean;
  sx?: SystemStyleObject;
  alignFirstHeader?: boolean;
  backgroundColor?: string;
};

export type TableCellProps<Row extends Record<string, any>> = {
  column: TableColumn<Row>;
  row: Row;
  onClick?: (row: Row, event: MouseEvent) => void;
  height?: string;
  isDense?: boolean;
  selected: boolean;
  rowIndex: number;
};

export const Cell: FC<
  Readonly<
    { children?: ReactNode; onClick?: (event: MouseEvent) => void } & BoxProps
  >
> = ({ children, ...props }) => {
  return (
    <Box
      as="td"
      display="flex"
      alignItems="center"
      px={4}
      borderBottom="1px"
      {...props}
      borderColor={props.borderColor ?? "gray.100"}
    >
      {typeof children === "string" ? (
        <Text isTruncated>{children}</Text>
      ) : (
        children
      )}
    </Box>
  );
};

export function TableCell<RowData extends Record<string, any>>({
  column: { cell, cellSx = {}, divider, disabled, whitespace },
  row,
  height,
  isDense,
  rowIndex,
  selected,
}: Readonly<TableCellProps<RowData>>): ReactElement {
  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (disabled) {
        event.stopPropagation();
      }
    },
    [disabled],
  );

  return (
    <Cell
      onClick={handleClick}
      minHeight={height}
      whiteSpace={whitespace || "nowrap"}
      p={isDense ? 2 : undefined}
      borderLeft={isDense || divider ? "1px" : undefined}
      borderColor={isDense ? "base.border !important" : undefined}
      sx={{
        "&:last-of-type": { borderRight: isDense ? "1px" : undefined },
        ...cellSx,
      }}
    >
      {cell(row, rowIndex, selected)}
    </Cell>
  );
}

export const HeaderCell: FC<HeaderCellProps> = ({
  children,
  onClick,
  sortDirection,
  top,
  isSortMenu,
  isDense,
  sx = {},
  alignFirstHeader = false,
  backgroundColor = "white",
  ...props
}) => {
  const isSticky = typeof top !== "undefined";
  return (
    <Box
      as="th"
      alignItems="center"
      display="flex"
      flexWrap="nowrap"
      whiteSpace="nowrap"
      fontWeight="semibold"
      bg={backgroundColor}
      fontSize="sm"
      py={3}
      pos={isSticky ? "sticky" : undefined}
      zIndex={isSticky ? 1 : undefined}
      top={top}
      color={isDense ? "text.primary" : "text.secondary"}
      textTransform={isDense ? undefined : "uppercase"}
      borderLeft={isDense ? "1px" : undefined}
      borderTop={isDense ? "1px" : undefined}
      borderBottom={isDense ? "1px" : "2px"}
      sx={{
        ...(!alignFirstHeader && {
          "&:first-of-type": {
            pl: 8,
          },
        }),

        "&:last-of-type": {
          pr: isSortMenu ? 0 : 8,
          borderRight: isDense ? "1px" : undefined,
        },
        "&:hover > .sort-icon": {
          transition: "background fill stroke 150ms ease-in",
          svg: {
            fill: "text.secondary",
          },
        },

        cursor: onClick ? "pointer" : "auto",

        ...sx,
      }}
      onClick={onClick}
      px={isDense ? "8px !important" : isSortMenu ? 0 : 4}
      borderColor="base.border !important"
      {...props}
    >
      {children}
      {onClick && (
        <SortIcon className="sort-icon" sortDirection={sortDirection} />
      )}
    </Box>
  );
};
