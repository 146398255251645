import { FC } from "react";

import type {
  JourneyEventType,
  JourneyNode,
  JourneyNodeRun,
} from "src/pages/journeys/types";
import { IconBox } from "src/components/icon-box";
import { Heading, MergeIcon, Text, Row, Column } from "@hightouchio/ui";
import { JourneyNodeType } from "src/types/journeys";
import { commaNumber } from "src/utils/numbers";
import { JourneyRun } from "src/pages/journeys/types";
import { NODE_WITH_BRANCHES_LIBRARY_OPTIONS } from "src/pages/journeys/node-library";

const eventMapToHeaderCopy: Record<JourneyEventType, string> = {
  "in-progress": "are currently in",
  "entered-journey": "entered journey",
  "completed-journey": "completed journey",
  "exited-journey": "completed journey", // Same as 'completed-journey'
  "exited-journey-by-criteria": "exited by exit rule from",
  "moved-to-node": "advanced from", // Used for typechecking
  "entered-tile": "entered into",
  "advanced-from": "advanced from",
};

const getJourneySubheaderCopy = (options: {
  eventType?: JourneyEventType;
  nodeName?: string;
  isPreview: boolean;
}): string => {
  const { eventType, nodeName, isPreview } = options;
  const eventSubheader = eventMapToHeaderCopy[eventType || ""];
  if (!eventSubheader) return ""; // Return early if no valid event type

  let subheader =
    (isPreview ? "Preview of users who " : "Users who ") + eventSubheader;

  if (nodeName) {
    subheader += ` "${nodeName}" tile`;
  }

  return subheader;
};

const NodeTypeToIcon: FC<{ nodeType?: JourneyNodeType }> = ({
  nodeType,
}): JSX.Element => {
  const tileMetadata = NODE_WITH_BRANCHES_LIBRARY_OPTIONS.find(
    ({ type }) => type === nodeType,
  );

  if (nodeType == null || tileMetadata == null) {
    return (
      <IconBox
        hasGradient={false}
        bg="none"
        sx={{ color: "gray.600" }}
        icon={<MergeIcon />}
        iconSize="24px"
      />
    );
  }

  return (
    <IconBox
      bg={`${tileMetadata?.color ?? "gray"}.400`}
      icon={tileMetadata.icon}
    />
  );
};
const getAssociatedCountForNodeEvent = (options: {
  event?: JourneyEventType;
  latestJourneyRun?: JourneyRun;
  currentNode?: JourneyNode;
  currentNodeStats?: JourneyNodeRun;
}): number => {
  if (options.latestJourneyRun == null) {
    return 0;
  } else if (options.event == null) {
    return Number(options.latestJourneyRun.entry_count);
  }

  switch (options.event) {
    case "entered-journey":
      return Number(options.latestJourneyRun.entry_count);
    case "exited-journey":
    case "completed-journey": // We should only see the above
      return Number(options.latestJourneyRun.completed_count);
    case "exited-journey-by-criteria":
      return Number(options.latestJourneyRun.exit_count);
    case "advanced-from":
      return Number(options.currentNodeStats?.advanced_count || 0);
    case "entered-tile":
      return Number(options.currentNodeStats?.entry_count || 0);
    case "in-progress":
      return Number(options.currentNode?.data.number_users || 0);
    default:
      return 0;
  }
};

export const MemberDetailHeader: FC<{
  loading?: boolean;
  latestJourneyRun?: JourneyRun;
  event?: JourneyEventType;
  currentNode?: JourneyNode;
  currentNodeStats?: JourneyNodeRun;
}> = ({ event, latestJourneyRun, currentNode, currentNodeStats, loading }) => {
  const subheading = getJourneySubheaderCopy({
    eventType: event,
    nodeName: currentNode?.data?.name,
    // We hardcap this member drawer to 100 rows/users (for performance reasons),
    // even if there are more rows in the table (no pagination).
    // If there are more than 100, we're denoting it's a "preview"/subset to the user,
    // but if there are <= 100 rows, we can show all rows!
    isPreview: Number(currentNodeStats) > 100,
  });

  const currentUserCount = getAssociatedCountForNodeEvent({
    event,
    latestJourneyRun,
    currentNode,
    currentNodeStats,
  });

  return (
    <Row align="center" justify="space-between" gap={2}>
      <NodeTypeToIcon nodeType={currentNode?.data?.config?.type} />
      <Column px={2}>
        <Heading>
          {loading
            ? "Calculating..."
            : ` ${commaNumber(Number(currentUserCount))} users`}
        </Heading>
        <Text size="md" fontWeight="medium" color="text.tertiary">
          {subheading}
        </Text>
      </Column>
    </Row>
  );
};
