import { routes as monitoringRoutes } from "src/pages/alerting";
import { routes as destinationRoutes } from "src/pages/destinations";
import { routes as sourceRoutes } from "src/pages/sources";
import { routes as modelRoutes } from "src/pages/models";
import { routes as syncRoutes } from "src/pages/syncs";
import { routes as audienceRoutes } from "src/pages/audiences/routes";
import { routes as analyticsRoutes } from "src/pages/analytics/routes";

/**
 * New style of routes.
 * TODO: migrate routes over to this new pattern
 */
export const jsonSlugRoutes = [
  ...monitoringRoutes,
  ...modelRoutes,
  ...sourceRoutes,
  ...destinationRoutes,
  ...syncRoutes,
  ...audienceRoutes,
  ...analyticsRoutes,
];
