import { useMemo } from "react";

import { ConfirmationDialog, Paragraph } from "@hightouchio/ui";

import { Form, useHightouchForm } from "src/components/form";
import { useUpdateEventMutation } from "src/graphql";
import {
  SchemaField,
  SchemaFieldForm,
  SchemaObjectType,
} from "src/pages/schema/types";
import { isTimestampColumn } from "src/utils/models";

import { ColumnField } from "./column-field";

export const EventPrimaryKeyForm: SchemaFieldForm = ({
  object,
  hasUpdatePermission,
}) => {
  const { mutateAsync: updateEvent } = useUpdateEventMutation();

  const form = useHightouchForm({
    onSubmit: async ({ primary_key }) => {
      await updateEvent({
        id: object.id,
        set: {
          primary_key,
        },
      });
    },
    values: {
      primary_key: object.event?.primary_key ?? null,
    },
  });

  const primaryKey = form.watch("primary_key");

  return (
    <Form form={form}>
      <EventPrimaryKeyField
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting}
      />
      <ConfirmationDialog
        isOpen={primaryKey != object.event?.primary_key}
        title="Are you sure?"
        confirmButtonText="Confirm"
        variant="danger"
        onConfirm={async () => {
          await form.submit();
        }}
        onClose={() => {
          form.reset({
            primary_key: object.event?.primary_key ?? null,
          });
        }}
      >
        <Paragraph>
          This will update the primary key for this event. For any journeys that
          use this event for entry, this update could result in unexpected
          duplicate events in the journey and duplicate messages sent.
        </Paragraph>
      </ConfirmationDialog>
    </Form>
  );
};

const EventPrimaryKeyField: SchemaField = (props) => {
  const object = useMemo(
    () =>
      ({
        ...props.object,
        columns: props.object?.columns?.filter(isTimestampColumn),
      }) as SchemaObjectType,
    [props.object],
  );

  return (
    <ColumnField
      isOptional
      name="primary_key"
      label="Primary key"
      tip="Required if you want to use this event to trigger Hightouch journeys"
      {...props}
      object={props.object ? object : undefined}
    />
  );
};
