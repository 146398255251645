import { Buffer } from "buffer";

import { useEffect, useMemo, FC } from "react";

import {
  Alert,
  FormField,
  Radio,
  RadioGroup as HightouchUiRadioGroup,
  useToast,
  Spinner,
} from "@hightouchio/ui";
import { capitalize, upperCase } from "lodash";
import isEmpty from "lodash/isEmpty";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import {
  AuthorizeConnection,
  WebhookAuthorizeButton,
} from "src/components/authorize-connection";
import { SelectCredential } from "src/components/credentials";
import { TunnelSelect } from "src/components/tunnel-select";
import { usePermissionContext } from "src/components/permission/permission-context";
import { Form as FormkitForm } from "src/formkit/components/form";
import {
  FormkitDestination,
  FormkitProvider,
  useFormkitContext,
} from "src/formkit/components/formkit-context";
import { graphQLFetch } from "src/formkit/hooks";
import { ProcessFormNode } from "src/formkit/formkit";
import {
  DestinationDefinitionFragment,
  useFormkitDestinationDefinitionQuery,
  useFormkitDestinationValidationQuery,
} from "src/graphql";
import { FieldError } from "src/components/field-error";
import {
  ConnectionHandlers,
  OAuthConnectionTable,
} from "./oauth-connection-table";
import { DestinationMethod } from "@hightouch/core/server/graphql/types";
import { Section } from "src/formkit/components";
import { shouldWrapInSection } from "src/formkit/utils";

interface Context {
  credentialId?: string;
  destination?: FormkitDestination;
  destinationDefinition: DestinationDefinitionFragment;
  isSetup: boolean;
}

interface Props {
  config: Record<string, any> | undefined;
  credentialId: string | undefined;
  definition: DestinationDefinitionFragment;
  destination?: FormkitDestination | null;
  setConfig: (config: Record<string, any>) => void;
  setCredentialId: (credentialId: string) => void;
  isSetup: boolean;
  disableAuthMethod?: boolean;
  onSubmit?: (config: any) => Promise<void>;
  error?: any;
  onConnectClick?(defintion: DestinationDefinitionFragment): void;
  connectionHandlers?: ConnectionHandlers;
}

export const SetupForm: FC<Props> = ({
  config,
  setConfig,
  destination,
  credentialId,
  setCredentialId,
  definition,
  ...props
}) => {
  const client = useQueryClient();
  const { toast } = useToast();
  const { data, isLoading } = useFormkitDestinationDefinitionQuery({
    type: definition?.type,
  });
  //Legacy Destination Config is configuration that does NOT use new formkit format with multiple authentication methods
  const setupMethods = data?.formkitDestinationDefinition?.filter((method) => {
    return !method.isDeprecated || config?.methodKey === method.key;
  });
  const isLegacyDestinationConfiguration =
    setupMethods?.length == 1 && setupMethods[0]?.key === null;

  const handleValidate = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<any> => {
    event.preventDefault();
    if (setupMethods?.length === 0) {
      if (typeof props.onSubmit === "function") {
        return await props.onSubmit(data);
      }

      return;
    }

    const errors = await validate(config, context);
    if (typeof errors === "object" && Object.keys(errors).length) {
      methods.clearErrors();
      Object.entries(errors).forEach(([key, message]) => {
        methods.setError(key, { message: String(message) });
      });

      toast({
        id: "save-destination-config",
        title: "Destination configuration error",
        variant: "error",
      });
    }
    return errors;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const errors = await handleValidate(event);
    if (isEmpty(errors) && typeof props.onSubmit === "function") {
      await props.onSubmit(data);
    }
  };

  const validate = async (config, context) => {
    if (!config) {
      return;
    }

    const response = await client.fetchQuery({
      queryFn: useFormkitDestinationValidationQuery.fetcher({
        type: definition.type,
        config,
        context,
      }),
      queryKey: useFormkitDestinationValidationQuery.getKey({
        ...config,
        ...context,
      }),
    });

    return response.formkitDestinationValidation;
  };

  const context: Context = {
    destination: destination ?? undefined,
    destinationDefinition: definition,
    isSetup: props.isSetup,
    credentialId,
  };

  const methods = useForm();

  useEffect(() => {
    if (config && Object.keys(config).length) {
      methods.reset(config, { keepDefaultValues: true });
    }
  }, [Boolean(config)]);

  useEffect(() => {
    //Autoset authentication method if only one.
    const initialSetupMethod = setupMethods?.[0];
    if (
      !config?.methodKey &&
      !isLegacyDestinationConfiguration &&
      setupMethods?.length === 1 &&
      initialSetupMethod
    ) {
      setConfig((prev) => ({ ...prev, methodKey: initialSetupMethod.key }));
    }
  }, [config, definition.type, setupMethods?.length]);

  useEffect(() => {
    //Watches fields and sets config.
    const fieldWatcher = methods.watch((value) => {
      //Have to spread existing config as tunnel is with config, but not part of form.
      setConfig((o) => ({ ...o, ...value }));
    });
    return () => fieldWatcher.unsubscribe();
  }, [methods.watch]);

  const setTunnel = (tunnel) => {
    methods.setValue("tunnel", tunnel ?? null);
  };

  const permission = usePermissionContext();

  if (!setupMethods || isLoading) {
    return <Spinner m="auto" size="lg" />;
  }

  return (
    <>
      <FormkitProvider {...context} validate={validate}>
        <FormProvider {...methods}>
          {setupMethods.length > 1 && (
            <Section>
              <FormField label="Authentication method">
                <Controller
                  control={methods.control}
                  name="methodKey"
                  render={({ field }) => {
                    return (
                      <HightouchUiRadioGroup
                        isDisabled={
                          permission.unauthorized || props.disableAuthMethod
                        }
                        orientation="vertical"
                        value={config?.methodKey}
                        onChange={field.onChange}
                      >
                        {setupMethods.map((option) => (
                          <Radio
                            key={option.key}
                            label={option.label || capitalize(option.key || "")}
                            value={option.key || ""}
                          />
                        ))}
                      </HightouchUiRadioGroup>
                    );
                  }}
                  rules={{ required: true }}
                />
              </FormField>
            </Section>
          )}
          <SelectedMethodForm
            config={config || {}}
            credentialId={credentialId}
            definition={definition}
            handleValidate={handleValidate}
            id={destination?.id}
            isLegacyDestinationConfiguration={isLegacyDestinationConfiguration}
            isSetup={props.isSetup}
            onConnectClick={props.onConnectClick}
            setConfig={setConfig}
            setCredentialId={setCredentialId}
            setTunnel={setTunnel}
            setupMethods={setupMethods}
            connectionHandlers={props.connectionHandlers}
          />
          <FieldError error={props.error} />
          <form hidden id="destination-form" onSubmit={handleSubmit} />
        </FormProvider>
      </FormkitProvider>
    </>
  );
};

/**
 * Should always match the backend.
 * https://github.com/hightouchio/hightouch/blob/b5348475c8060af182fdd6988128e043eca4078a/packages/backend/destinations/shared-oauth.ts#L143
 */
export const StateEncoder = {
  encode: (state: Record<string, any>): string => {
    return Buffer.from(JSON.stringify(state)).toString("base64");
  },
  decode(state: string) {
    return JSON.parse(Buffer.from(state, "base64").toString());
  },
};

export type MethodProps = {
  config: Record<string, any>;
  credentialId?: string;
  definition: DestinationDefinitionFragment;
  handleValidate: (event: React.FormEvent<HTMLFormElement>) => Promise<any>;
  id?: string;
  isLegacyDestinationConfiguration: boolean;
  isSetup: boolean;
  onConnectClick?: (defintion: DestinationDefinitionFragment) => void;
  setConfig?: (config: Record<string, any>) => void;
  setCredentialId?: any;
  setTunnel: (config?: Record<string, any>) => void;
  setupMethods: Record<string, any>;
  setMethod?: (destinationMethod: DestinationMethod | undefined) => void;
  connectionHandlers?: ConnectionHandlers;
};

const getClientCredentialAuth = (context, config) => {
  return async () => {
    const data = await graphQLFetch({
      destinationId: context?.destination?.id,
      query: `
      query FormkitDestinationHandler($input: FormkitSourceHandlerInput!) {
        formkitDestinationHandler(input: $input)
      }
    `,
      modelId: context?.model?.id,
      variables: {
        input: {
          handler: "clientCredentialAuth",
          variables: { config },
          context: {
            type: context.destinationDefinition?.type,
            workspaceId: context.workspaceId,
          },
        },
      },
    });

    if (isEmpty(data.errors)) {
      window.location.href = data;
    }
  };
};

const SelectedMethodForm: FC<Readonly<MethodProps>> = ({
  config,
  definition,
  handleValidate,
  id,
  onConnectClick,
  setupMethods,
  ...props
}) => {
  const context = useFormkitContext();
  const matchingAuthMethod = useMemo(() => {
    if (props.isLegacyDestinationConfiguration) {
      return setupMethods?.[0];
    }
    if (config?.methodKey === "googleOauth") {
      return setupMethods?.find(
        (o) => o.key === "googleOauth" || o.key === "oauth",
      );
    }
    const matchingAuthMethod = setupMethods?.find(
      (o) => o.key === config?.methodKey,
    );
    return matchingAuthMethod;
  }, [config?.methodKey, setupMethods, props.isLegacyDestinationConfiguration]);

  const Form = useMemo(() => {
    if (!matchingAuthMethod?.form) {
      return null;
    }

    // Handle incorrect form definitions that contain fields at the top level.
    if (shouldWrapInSection(matchingAuthMethod.form)) {
      return (
        <FormkitForm>
          <Section>
            <ProcessFormNode
              node={matchingAuthMethod.form}
              context={definition}
            />
          </Section>
        </FormkitForm>
      );
    }
    return (
      <FormkitForm>
        <ProcessFormNode node={matchingAuthMethod.form} context={definition} />
      </FormkitForm>
    );
  }, [matchingAuthMethod?.key]);

  if (matchingAuthMethod?.method === "form") {
    return (
      <>
        {matchingAuthMethod?.tunneling && (
          <Section>
            <TunnelSelect
              optional
              value={config.tunnel}
              onChange={props.setTunnel}
            />
          </Section>
        )}

        {matchingAuthMethod?.provider && (
          <Section>
            <FormField
              label={`${upperCase(matchingAuthMethod.provider)} Credentials`}
            >
              <SelectCredential
                provider={matchingAuthMethod.provider}
                value={props.credentialId}
                onChange={(value) => props.setCredentialId(value)}
              />
            </FormField>
          </Section>
        )}

        {Form}
      </>
    );
  } else if (matchingAuthMethod?.method === "oauth") {
    if (!id) {
      if (matchingAuthMethod?.isPreOAuthForm && matchingAuthMethod?.form) {
        const { callbackUrl, methodKey, ...restProps } = config;
        const onAuth =
          config.grantType === "client_credentials" ||
          config.grantType === "password"
            ? getClientCredentialAuth(context, config)
            : async (e) => {
                const errors = await handleValidate(e);
                if (isEmpty(errors)) {
                  window.location.href = `${import.meta.env.VITE_API_BASE_URL}${
                    matchingAuthMethod.url
                  }/${definition.type}?state=${StateEncoder.encode({
                    config,
                  })}`;
                }
              };
        return (
          <>
            {Form}
            <WebhookAuthorizeButton
              icon={matchingAuthMethod?.authIcon || definition.icon}
              isDisabled={!Object.values(restProps).some((el) => el)}
              name={matchingAuthMethod?.authLabel || definition.name}
              onAuthorize={onAuth}
            />
          </>
        );
      }
      return (
        <AuthorizeConnection
          href={`${import.meta.env.VITE_API_BASE_URL}${
            matchingAuthMethod.url
          }/${definition.type}?state=${StateEncoder.encode({
            config,
          })}`}
          icon={matchingAuthMethod?.authIcon || definition.icon}
          name={matchingAuthMethod?.authLabel || definition.name}
          onAuthorize={() => {
            if (onConnectClick) {
              onConnectClick(definition);
            }
          }}
        />
      );
    } else {
      let redirectUrl = `${import.meta.env.VITE_API_BASE_URL}${
        matchingAuthMethod.url
      }/${definition.type}/${id}?state=${StateEncoder.encode({ config })}`;
      if (
        config.grantType === "client_credentials" ||
        config.grantType === "password"
      ) {
        redirectUrl = `${
          import.meta.env.VITE_APP_BASE_URL
        }/destinations/${context?.destination?.id}?reauthorized=true`;
      }
      return (
        <>
          {props.isSetup && (
            <Alert
              variant="inline"
              message={`Your ${definition.name} account is now connected to Hightouch.`}
              title="Authorization successful"
              type="success"
            />
          )}
          {!matchingAuthMethod?.isPreOAuthForm &&
            matchingAuthMethod?.form &&
            Form}
          {!props.isSetup && (
            <>
              {matchingAuthMethod?.isPreOAuthForm &&
                matchingAuthMethod?.form &&
                Form}
              {props.connectionHandlers && (
                <OAuthConnectionTable
                  config={config}
                  destinationId={id.toString()}
                  destinationIcon={
                    matchingAuthMethod?.authIcon || definition.icon
                  }
                  destinationName={
                    matchingAuthMethod?.authLabel || definition.name
                  }
                  showTestConnection={definition?.testConnection}
                  credentialId={props.credentialId}
                  redirectUrl={redirectUrl}
                  onAuthorize={async () => {
                    if (
                      config.grantType === "client_credentials" ||
                      config.grantType === "password"
                    ) {
                      const onAuth = getClientCredentialAuth(context, config);
                      await onAuth();
                      return;
                    }
                    if (onConnectClick) {
                      onConnectClick(definition);
                    }
                  }}
                  connectionHandlers={props.connectionHandlers}
                />
              )}
            </>
          )}
        </>
      );
    }
  }
  return null;
};
