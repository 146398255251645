// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Navigate, Route, Routes } from "react-router-dom";

import { PermissionedRoute } from "src/components/router/permissioned-route";
import { EventsRouter } from "src/events";
import { CreateAudienceObject } from "src/pages/audiences/setup/create-object";
import { CreateSyncTemplate } from "src/pages/audiences/setup/create-sync-template";
import { AudienceObject } from "src/pages/audiences/setup/object";
import { AudienceObjectQuery } from "src/pages/audiences/setup/object-query";
import { SetupAudiences } from "src/pages/audiences/setup/setup-audiences";
import { SyncTemplateRouter } from "src/pages/audiences/setup/sync-template";
import { CampaignsRoutes } from "src/pages/campaigns";
import { Copilot } from "src/pages/copilot";
import { DecisionEngineRoutes } from "src/pages/decision-engines";
import { ExtensionsRouter } from "src/pages/extensions";
import { GovernanceRoutes } from "src/pages/governance";
import { Home } from "src/pages/home";
import { IdentityResolutionRoutes } from "src/pages/identity-resolution";
import { JourneysRoutes } from "src/pages/journeys";
import { MetricsRoutes } from "src/pages/metrics/index";
import { MiscRoutes } from "src/pages/misc";
import { NotFound } from "src/pages/not-found";
import { OrganizationRoutes } from "src/pages/organizations";
import { PriorityListsRouter } from "src/pages/priority-lists";
import { SchemaPage } from "src/pages/schema";
import { SequenceRoutes } from "src/pages/sequences";
import { SettingsRouter } from "src/pages/settings";
import { Setup } from "src/pages/setup";
import { Traits } from "src/pages/traits/traits";

/**
 * These need to be migrated to the new style of routes
 * @deprecated
 */
export const SlugRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="onboarding/*" element={<Setup />} />
      <Route path="home" element={<Home />} />
      <Route path="sequences/*" element={<SequenceRoutes />} />
      <Route path="extensions/*" element={<ExtensionsRouter />} />
      <Route path="settings/*" element={<SettingsRouter />} />
      <Route
        path="schema/sync-templates/new"
        element={
          <PermissionedRoute
            permission={{
              v1: { resource: "sync_template", grant: "create" },
            }}
            redirect="/schema/sync-templates"
          >
            <CreateSyncTemplate />
          </PermissionedRoute>
        }
      />
      <Route
        path="metrics/*"
        element={
          <PermissionedRoute
            permission={{
              v1: { resource: "audience_schema", grant: "create" },
            }}
            redirect="/home"
          >
            <MetricsRoutes />
          </PermissionedRoute>
        }
      />
      <Route
        path="schema/sync-templates/:sync_template_id"
        element={<SyncTemplateRouter />}
      />
      <Route
        path="schema/:object/:id/query"
        element={<AudienceObjectQuery />}
      />
      <Route
        path="schema/:object/new"
        element={
          <PermissionedRoute
            permission={{
              v1: { resource: "audience_schema", grant: "create" },
            }}
            redirect="/schema/:object"
          >
            <CreateAudienceObject />
          </PermissionedRoute>
        }
      />
      <Route path="schema/:object/:id" element={<AudienceObject />} />
      <Route path="schema/*" element={<SetupAudiences />} />
      <Route
        path="schema/"
        element={<Navigate to="/schema/parent-models" replace />}
      />
      <Route path="schema-v2/*" element={<SchemaPage />} />
      <Route path="priority-lists/*" element={<PriorityListsRouter />} />
      <Route path="copilot" element={<Copilot />} />
      <Route path="campaigns/*" element={<CampaignsRoutes />} />
      <Route path="traits/*" element={<Traits />} />
      <Route path="idr/*" element={<IdentityResolutionRoutes />} />
      <Route path="events/*" element={<EventsRouter />} />
      <Route path="organization/*" element={<OrganizationRoutes />} />
      <Route path="journeys/*" element={<JourneysRoutes />} />
      <Route path="ai/*" element={<DecisionEngineRoutes />} />
      <Route path="governance/*" element={<GovernanceRoutes />} />
      <Route path="misc/*" element={<MiscRoutes />} />
    </Routes>
  );
};
