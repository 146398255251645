import {
  Alert,
  Button,
  ButtonGroup,
  Column,
  Dialog,
  FormField,
  Radio,
  RadioGroup,
  TextInput,
} from "@hightouchio/ui";
import { Controller } from "react-hook-form";

import { Form, useHightouchForm } from "src/components/form";
import { useCreateDecisionEngineMessageMutation } from "src/graphql";
import { OutletContext } from "src/pages/decision-engines";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";
import { ChannelOptionsMap } from "src/pages/decision-engines/utils";
import { useNavigate, useOutletContext, useParams } from "src/router";
import { getKeyValueObject } from "src/utils/key-value";
import { TemplateSelect } from "./message/components/template-select";
import { orderBy } from "lodash";

export const AddMessage = () => {
  const { flowId } = useParams<{ flowId: string }>();
  const { engine } = useOutletContext<OutletContext>();
  const navigate = useNavigate();

  const createMessage = useCreateDecisionEngineMessageMutation();

  const enabledChannels = orderBy(
    engine.channels.filter((channel) => channel.enabled),
    ["type"],
    ["asc"],
  );

  const form = useHightouchForm({
    onSubmit: async (data) => {
      const decision_engine_channel_id = channel?.id;
      const response = await createMessage.mutateAsync({
        flowId: flowId!,
        message: {
          decision_engine_channel_id,
          name: data.name,
          config: data.config,
          variables: data.variables,
          tags: getKeyValueObject(data.tags),
          collections: {
            data: data.collections
              .filter(({ enabled }) => enabled)
              .map(({ id, item_count }) => ({
                decision_engine_collection_id: id,
                item_count,
              })),
          },
        },
        flowMessageConfig: data.flowMessageConfig,
      });
      if (
        response.createDecisionEngineMessage.__typename ===
        "DecisionEngineError"
      ) {
        throw new Error(response.createDecisionEngineMessage.error);
      } else {
        navigate(
          `/ai/flows/${flowId}/messages/${
            (response.createDecisionEngineMessage as any)?.id
          }`,
        );
      }
    },
    defaultValues: {
      decision_engine_channel_id: enabledChannels?.[0]?.id,
      name: "",
      config: {
        baseMessageId: "",
      },
      flowMessageConfig: {},
      variables: [],
      collections:
        engine.collections?.map((collection) => ({
          id: collection.id,
          enabled: false,
          name: collection.collection.name,
          item_count: 1,
        })) ?? [],
      tags: [],
    },
  });

  const onClose = () => {
    navigate(`/ai/flows/${flowId}`);
  };

  const { watch } = form;
  const channelId = watch("decision_engine_channel_id");
  const channel = engine.channels.find((channel) => channel.id === channelId);

  return (
    <Dialog
      maxHeight="80vh"
      width="xl"
      isOpen={true}
      onClose={onClose}
      variant="form"
      title="New action"
      actions={
        <ButtonGroup>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={form.submit}
            isLoading={createMessage.isLoading}
          >
            Create action
          </Button>
        </ButtonGroup>
      }
    >
      <Form form={form}>
        <Column gap={6} maxW="lg">
          {enabledChannels.length > 1 && (
            <Controller
              name="decision_engine_channel_id"
              render={({ field }) => (
                <FormField label="Channel">
                  <RadioGroup {...field} orientation="vertical">
                    {enabledChannels.map((channel) => (
                      <Radio
                        key={channel.id}
                        value={channel.id}
                        label={ChannelOptionsMap[channel.type]?.label ?? ""}
                        description={
                          getChannelDefinition(
                            channel.destination.type,
                          ).getChannelDescription(channel.config) ||
                          channel.destination.definition.name
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormField>
              )}
            />
          )}
          <TemplateSelect
            destination={channel!.destination}
            channel={channel!}
          />
          <FormField
            label="Action name"
            description="Choose a descriptive name for this action"
          >
            <Controller
              name="name"
              render={({ field }) => (
                <TextInput {...field} placeholder="Enter a name..." />
              )}
            />
          </FormField>

          {createMessage.data?.createDecisionEngineMessage.__typename ===
            "DecisionEngineError" && (
            <Alert
              title="Unable to create action"
              variant="inline"
              type="error"
              message={createMessage.data.createDecisionEngineMessage.error}
            />
          )}
        </Column>
      </Form>
    </Dialog>
  );
};
