import { FC, useEffect, useState } from "react";

import {
  Alert,
  Box,
  Button,
  Column,
  FormField,
  Paragraph,
  Radio,
  RadioGroup,
  Select,
  TextInput,
  useToast,
  CodeSnippet,
  Heading,
  Row,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Controller } from "react-hook-form";
import { useQueryClient } from "react-query";
import { number, object, string } from "yup";

import { ActionBar } from "src/components/action-bar";
import { SelectCredential } from "src/components/credentials";
import { FeatureFull } from "src/components/feature-gates";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { useUser } from "src/contexts/user-context";
import {
  useCreateExternalBucketMutation,
  useExternalBucketsQuery,
  useTestStorageQuery,
  useUpdateExternalBucketMutation,
} from "src/graphql";
import AWS_REGION_OPTIONS from "src/utils/aws-region-options";
import { newPylonMessage } from "src/lib/pylon";
import { DocsLink } from "src/components/docs-link";
import { Card } from "src/components/card";

enum StorageType {
  "S3" = "s3",
  "GCP" = "gcp",
  "Azure" = "azure",
}

type StorageConfig =
  | {
      type: StorageType.S3;
      config: {
        bucket: string;
        region: string;
      };
      credentialId: number;
    }
  | {
      type: StorageType.GCP;
      config: {
        projectId: string;
        bucketName: string;
      };
      credentialId: number;
    }
  | {
      type: StorageType.Azure;
      config: {
        account: string;
        containerName: string;
      };
      credentialId: number;
    };

const useTestStorageConnection = () => {
  const client = useQueryClient();
  const [testLoading, setTestLoading] = useState<boolean>(false);

  const runTest = async ({ type, config, credentialId }: StorageConfig) => {
    setTestLoading(true);

    const variables = { type, config: JSON.stringify(config), credentialId };

    try {
      const { testStorageConnection } = await client.fetchQuery(
        useTestStorageQuery.getKey(variables),
        {
          queryFn: useTestStorageQuery.fetcher(variables),
        },
      );
      setTestLoading(false);
      return { success: Boolean(testStorageConnection), error: "" };
    } catch (error) {
      setTestLoading(false);
      return { success: false, error: error.message };
    }
  };

  return { runTest, testLoading };
};

export const Storage: FC = () => {
  const { data: bucketData } = useExternalBucketsQuery(undefined, {
    suspense: true,
  });
  const { externalStorageEnabled } = useFlags();
  const { workspace } = useUser();

  const bucket = bucketData?.external_buckets?.[0];
  const config = bucket?.config;
  const credential = bucket?.cloud_credential;
  const [type, setType] = useState<StorageType>(StorageType.S3);

  useEffect(() => {
    if (bucket) {
      setType(bucket?.type as StorageType);
    }
  }, [bucket]);

  return (
    <FeatureFull
      enabled={Boolean(
        bucket ||
          workspace?.organization?.plan?.sku === "business_tier" ||
          externalStorageEnabled,
      )}
      featureDetails={{
        bullets: [
          "Choose from either Amazon S3 or Google Cloud Storage to store your data",
          "Leverage change data capture and sync observability in a secure way",
          "Recommended for businesses that want to control where their SaaS is deployed",
        ],
        description:
          "Hightouch can send the data needed to power your syncs into a custom storage solution you own. This gives you full control over where data resides while still enabling efficient syncing and live debugging.",
        pitch: "Use your own storage tool to store data of your sync results",
        image: {
          src: "https://cdn.sanity.io/images/pwmfmi47/production/5b0fa75732f9506a5dfdb441a1b7ee75ecb9cdc0-910x752.webp",
        },
      }}
      featureName="custom external storage"
    >
      <Column gap={6}>
        {config && (
          <Alert
            message="Our team can take care of this for you."
            title="Looking to change your storage location?"
            type="info"
            variant="inline"
            actions={
              <Button
                variant="secondary"
                onClick={() =>
                  newPylonMessage("Hi, I'd like to change my storage location.")
                }
              >
                Contact support
              </Button>
            }
          />
        )}

        <Column>
          <Row align="center" justify="space-between">
            <Heading mt={config ? 8 : 0}>Storage</Heading>
            <DocsLink
              href={`${import.meta.env.VITE_DOCS_URL}/security/storage`}
              name="external storage"
            />
          </Row>
          <Paragraph mt={1}>
            Configure Hightouch to store all customer data at rest within your
            external storage bucket.
          </Paragraph>
        </Column>

        <Card gap={6}>
          <FormField label="Cloud storage provider">
            <RadioGroup
              isDisabled={Boolean(config)}
              orientation="vertical"
              value={type}
              onChange={(newType) => setType(newType as StorageType)}
            >
              <Radio label="Amazon S3" value={StorageType.S3} />
              <Radio label="Google Cloud Storage" value={StorageType.GCP} />
              <Radio label="Azure Blob Storage" value={StorageType.Azure} />
            </RadioGroup>
          </FormField>

          {type === StorageType.Azure ? (
            <AzureForm config={config} credential={credential} />
          ) : type === StorageType.GCP ? (
            <GCPForm config={config} credential={credential} />
          ) : (
            <S3Form config={config} credential={credential} />
          )}
        </Card>

        {!config && (
          <Alert
            message="Switching to self-hosted storage interrupts the change data capture (CDC) for active syncs. After configuring the new storage location, you'll need to trigger a full resync or reset CDC for any syncs that have previously run using Hightouch-managed storage. Before making the switch, ensure that all your syncs meet the prerequisites for a full resync."
            title="Enabling self-hosted storage may disrupt your existing syncs"
            type="warning"
            variant="inline"
            actions={
              <Button
                variant="secondary"
                onClick={() =>
                  newPylonMessage(
                    "Hi, I have existing running syncs and I need help migrating to self-hosted storage.",
                  )
                }
              >
                Contact support
              </Button>
            }
          />
        )}
      </Column>
    </FeatureFull>
  );
};

const S3Form: FC<
  Readonly<{
    config: any;
    credential: { id: number; stripped_config?: any } | null | undefined;
  }>
> = ({ config, credential }) => {
  const { toast } = useToast();
  const { workspace } = useUser();

  const [testError, setTestError] = useState<string>("");
  const { runTest, testLoading } = useTestStorageConnection();

  const validationSchema = object().shape({
    bucket: string().required("A bucket is required"),
    region: string().required("A region is required"),
    credentialId: number().required("A credential ID is required"),
  });

  const form = useHightouchForm({
    onSubmit: async ({ bucket, region, credentialId }) => {
      const newConfig = {
        bucket,
        region,
      };

      const { success, error } = await runTest({
        type: StorageType.S3,
        config: newConfig,
        credentialId,
      });

      if (success) {
        if (config) {
          await updateExternalBucket({
            workspaceId: workspace?.id,
            append: {
              config: newConfig,
            },
            set: {
              type: StorageType.S3,
              credential_id: credentialId,
            },
          });
        } else {
          await createExternalBucket({
            object: {
              type: StorageType.S3,
              credential_id: credentialId,
              config: newConfig,
            },
          });
        }
      } else {
        setTestError(error);
        throw new Error("Failed to connect to storage");
      }
    },
    success: "Amazon S3 configuration saved",
    resolver: yupResolver(validationSchema),
    values: { ...config, credentialId: credential?.id },
  });

  const { mutateAsync: createExternalBucket } =
    useCreateExternalBucketMutation();
  const { mutateAsync: updateExternalBucket } =
    useUpdateExternalBucketMutation();

  const handleTestConnection = async () => {
    setTestError("");
    const { bucket, region, credentialId } = form.getValues();

    const { success, error } = await runTest({
      type: StorageType.S3,
      config: { bucket, region },
      credentialId,
    });

    if (success) {
      toast({
        id: "s3-test",
        title: "Connection successful",
        variant: "success",
      });
    } else {
      setTestError(error);
    }
  };

  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Form form={form}>
      <Controller
        control={control}
        name="region"
        render={({ field, fieldState: { error } }) => {
          return (
            <FormField
              error={error ? String(error.message) : undefined}
              label="Region"
            >
              <Select
                isDisabled={!!config}
                isInvalid={Boolean(errors.region)}
                optionLabel={(option) => option.label}
                optionValue={(option) => option.value}
                options={AWS_REGION_OPTIONS}
                placeholder="Select region..."
                value={field.value}
                onChange={field.onChange}
              />
            </FormField>
          );
        }}
      />

      <Controller
        control={control}
        name="bucket"
        render={({ field, fieldState: { error } }) => {
          return (
            <FormField
              error={error ? String(error.message) : undefined}
              label="Bucket name"
            >
              <TextInput
                isDisabled={Boolean(config)}
                isInvalid={Boolean(error)}
                placeholder="my-bucket"
                value={field.value ?? ""}
                onChange={field.onChange}
              />
            </FormField>
          );
        }}
      />

      <Controller
        control={control}
        name="credentialId"
        render={({ field, fieldState: { error } }) => {
          return (
            <FormField
              error={error ? String(error.message) : undefined}
              label="AWS credentials"
            >
              <SelectCredential
                isInvalid={Boolean(error)}
                provider="aws"
                value={field.value}
                onChange={field.onChange}
              />
            </FormField>
          );
        }}
      />

      {testError && (
        <Box maxWidth="100%" width="md">
          <Alert
            message={testError}
            title="Connection test failed with the following error from Amazon S3"
            type="error"
            variant="inline"
            onDismiss={() => setTestError("")}
          />
        </Box>
      )}

      <ActionBar>
        <FormActions />
        <Button
          size="lg"
          isLoading={testLoading}
          onClick={() => handleTestConnection()}
        >
          Test configuration
        </Button>
      </ActionBar>
    </Form>
  );
};

const GCPForm: FC<
  Readonly<{
    config: any;
    credential: { id: number; stripped_config?: any } | null | undefined;
  }>
> = ({ config, credential }) => {
  const { toast } = useToast();
  const { workspace } = useUser();
  const [testError, setTestError] = useState<string>("");
  const { runTest, testLoading } = useTestStorageConnection();

  const validationSchema = object().shape({
    bucketName: string().required("A bucket is required"),
    projectId: string().required("A project ID is required"),
    credentialId: number().required("A credential ID is required"),
  });

  const form = useHightouchForm({
    onSubmit: async ({ bucketName, projectId, credentialId }) => {
      const newConfig = { bucketName, projectId };

      const { success, error } = await runTest({
        type: StorageType.GCP,
        config: newConfig,
        credentialId,
      });

      if (success) {
        if (config) {
          await updateExternalBucket({
            workspaceId: workspace?.id,
            append: {
              config: newConfig,
            },
            set: {
              type: StorageType.GCP,
              credential_id: credentialId,
            },
          });
        } else {
          await createExternalBucket({
            object: {
              type: StorageType.GCP,
              config: newConfig,
              credential_id: credentialId,
            },
          });
        }
      } else {
        setTestError(error);
        throw new Error("Failed to connect to storage");
      }
    },
    success: "Google Cloud Storage configuration saved",
    resolver: yupResolver(validationSchema),
    defaultValues: config
      ? {
          ...config,
          credentialId: credential?.id,
        }
      : undefined,
  });

  const { mutateAsync: createExternalBucket } =
    useCreateExternalBucketMutation();
  const { mutateAsync: updateExternalBucket } =
    useUpdateExternalBucketMutation();

  const handleTestConnection = async () => {
    setTestError("");
    const { bucketName, projectId, credentialId } = form.getValues();

    const { success, error } = await runTest({
      type: StorageType.GCP,
      config: { bucketName, projectId },
      credentialId,
    });

    if (success) {
      toast({
        id: "gcp-test",
        title: "Connection successful",
        variant: "success",
      });
    } else {
      setTestError(error);
    }
  };

  const { control } = form;

  return (
    <Form form={form}>
      <Controller
        control={control}
        name="projectId"
        render={({ field, fieldState: { error } }) => {
          return (
            <FormField
              error={error ? String(error.message) : undefined}
              label="Project ID"
            >
              <TextInput
                isDisabled={Boolean(config)}
                isInvalid={Boolean(error)}
                value={field.value}
                onChange={field.onChange}
              />
            </FormField>
          );
        }}
      />

      <Controller
        control={control}
        name="bucketName"
        render={({ field, fieldState: { error } }) => {
          return (
            <FormField
              error={error ? String(error.message) : undefined}
              label="Bucket name"
            >
              <TextInput
                isDisabled={Boolean(config)}
                isInvalid={Boolean(error)}
                value={field.value}
                onChange={field.onChange}
              />
            </FormField>
          );
        }}
      />

      <Controller
        control={control}
        name="credentialId"
        render={({ field, fieldState: { error } }) => (
          <FormField
            error={error ? String(error.message) : undefined}
            label="Google Cloud credentials"
          >
            <SelectCredential
              isInvalid={Boolean(error)}
              provider="gcp"
              value={field.value}
              onChange={field.onChange}
            />
          </FormField>
        )}
      />

      {config && credential && credential.stripped_config && (
        <FormField
          description="Run these two commands in your Google Cloud Shell to grant the service account access to Google Cloud Storage."
          label="Authentication commands"
        >
          <CodeSnippet
            label="Grant read access"
            code={`gcloud projects add-iam-policy-binding ${config?.projectId} \n --member serviceAccount:${credential.stripped_config.client_email} \n --role roles/storage.objectViewer`}
          />
          <br />
          <CodeSnippet
            label="Grant write access"
            code={`gcloud projects add-iam-policy-binding ${config?.projectId} \n --member serviceAccount:${credential.stripped_config.client_email} \n --role roles/storage.objectCreator`}
          />
        </FormField>
      )}

      {testError && (
        <Box maxWidth="100%" width="md">
          <Alert
            message={testError}
            title="Connection test failed with the following error from Google Cloud Storage"
            type="error"
            variant="inline"
            onDismiss={() => setTestError("")}
          />
        </Box>
      )}

      <ActionBar>
        <FormActions />
        <Button
          size="lg"
          isLoading={testLoading}
          onClick={() => handleTestConnection()}
        >
          Test configuration
        </Button>
      </ActionBar>
    </Form>
  );
};

const AzureForm: FC<
  Readonly<{
    config: any;
    credential: { id: number; stripped_config?: any } | null | undefined;
  }>
> = ({ config, credential }) => {
  const { toast } = useToast();
  const { workspace } = useUser();
  const [testError, setTestError] = useState<string>("");
  const { runTest, testLoading } = useTestStorageConnection();

  const validationSchema = object().shape({
    account: string().required("A storage account is required"),
    containerName: string().required("A container name is required"),
    credentialId: number().required("Azure credentials are required"),
  });

  const form = useHightouchForm({
    onSubmit: async ({ account, containerName, credentialId }) => {
      const newConfig = { account, containerName };

      const { success, error } = await runTest({
        type: StorageType.Azure,
        config: newConfig,
        credentialId,
      });

      if (success) {
        if (config) {
          await updateExternalBucket({
            workspaceId: workspace?.id,
            append: {
              config: newConfig,
            },
            set: {
              type: StorageType.Azure,
              credential_id: credentialId,
            },
          });
        } else {
          await createExternalBucket({
            object: {
              type: StorageType.Azure,
              config: newConfig,
              credential_id: credentialId,
            },
          });
        }
      } else {
        setTestError(error);
        throw new Error("Failed to connect to storage");
      }
    },
    success: "Microsoft Azure configuration saved",
    resolver: yupResolver(validationSchema),
    values: config
      ? {
          ...config,
          credentialId: credential?.id,
        }
      : undefined,
  });

  const { control } = form;

  const { mutateAsync: createExternalBucket } =
    useCreateExternalBucketMutation();
  const { mutateAsync: updateExternalBucket } =
    useUpdateExternalBucketMutation();

  const handleTestConnection = async () => {
    setTestError("");
    const { account, containerName, credentialId } = form.getValues();

    const { success, error } = await runTest({
      type: StorageType.Azure,
      config: { account, containerName },
      credentialId,
    });

    if (success) {
      toast({
        id: "azure-test",
        title: "Connection successful",
        variant: "success",
      });
    } else {
      setTestError(error);
    }
  };

  return (
    <Form form={form}>
      <Controller
        control={control}
        name="account"
        render={({ field, fieldState: { error } }) => {
          return (
            <FormField
              error={error ? String(error.message) : undefined}
              label="Account name"
            >
              <TextInput
                isDisabled={Boolean(config)}
                isInvalid={Boolean(error)}
                value={field.value}
                onChange={field.onChange}
              />
            </FormField>
          );
        }}
      />

      <Controller
        control={control}
        name="containerName"
        render={({ field, fieldState: { error } }) => {
          return (
            <FormField
              error={error ? String(error.message) : undefined}
              label="Container name"
            >
              <TextInput
                isDisabled={Boolean(config)}
                isInvalid={Boolean(error)}
                value={field.value}
                onChange={field.onChange}
              />
            </FormField>
          );
        }}
      />

      <Controller
        control={control}
        name="credentialId"
        render={({ field, fieldState: { error } }) => (
          <FormField
            error={error ? String(error.message) : undefined}
            label="Microsoft Azure credentials"
          >
            <SelectCredential
              isInvalid={Boolean(error)}
              provider="azure"
              value={field.value}
              onChange={field.onChange}
            />
          </FormField>
        )}
      />

      {testError && (
        <Box maxWidth="100%" width="md">
          <Alert
            message={testError}
            title="Connection test failed with the following error from Microsoft Azure"
            type="error"
            variant="inline"
            onDismiss={() => setTestError("")}
          />
        </Box>
      )}

      <ActionBar>
        <FormActions />
        <Button
          size="lg"
          isLoading={testLoading}
          onClick={() => handleTestConnection()}
        >
          Test configuration
        </Button>
      </ActionBar>
    </Form>
  );
};
