import { FC } from "react";

import { Box, ErrorIcon, Row, Text, Tooltip } from "@hightouchio/ui";
import { DecisionEngineChannelConfig } from "@hightouch/lib/customer-data/decision-engine/types";
import { capitalize } from "lodash";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { LinkWithTooltip } from "src/components/link-with-tooltip";
import { Skeleton } from "src/components/loading";
import { useDecisionEngineDestinationResourcesQuery } from "src/graphql";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";

type Props = {
  channel: {
    type: string;
    config: DecisionEngineChannelConfig;
    destination: {
      id: string;
      type: string;
      config: Record<string, unknown>;
      definition: {
        icon: string;
        name: string;
      };
    };
  };
  resourceId: string;
  variant?: "default" | "compact";
};

export const DestinationResourceLink: FC<Readonly<Props>> = ({
  channel,
  resourceId,
  variant = "default",
}) => {
  const { resource, isLoading, error, url } = useDestinationResource({
    channel,
    resourceId,
  });

  if (isLoading) {
    return <Skeleton width="100px" />;
  }

  if (error) {
    return <Text color="danger.base">Failed to load</Text>;
  }

  const definition = getChannelDefinition(channel.destination.type);
  const resourceName = definition.getResourceName(channel.config);
  const resourceType = definition.getResourceType(channel);

  if (!resourceType) {
    return null;
  }

  if (!resource) {
    return (
      <Text color="text.secondary" whiteSpace="nowrap">
        {capitalize(resourceName)} not added
      </Text>
    );
  }

  return (
    <Row
      gap={2}
      align="center"
      overflow="hidden"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {variant === "default" && (
        <IntegrationIcon
          name={channel.destination.definition.name}
          src={channel.destination.definition.icon}
        />
      )}
      <LinkWithTooltip href={url} isExternal>
        {resource?.name}
      </LinkWithTooltip>
      {resource && definition.isInactive(resource) && (
        <Tooltip
          message={`${channel.destination.definition.name} ${resourceType} not activated`}
        >
          <Box as={ErrorIcon} boxSize={5} color="danger.base" flexShrink={0} />
        </Tooltip>
      )}
    </Row>
  );
};

export const useDestinationResource = ({ channel, resourceId }: Props) => {
  const channelDefinition = getChannelDefinition(channel.destination.type);
  const resourceType = channelDefinition.getResourceType(channel);

  const { data, error, isLoading } = useDecisionEngineDestinationResourcesQuery(
    {
      destinationId: channel.destination.id,
      type: resourceType ?? "",
    },
    {
      select: (data) => data.getDecisionEngineDestinationResources,
      enabled: Boolean(resourceType),
    },
  );

  const option =
    data?.find((c) => String(c.id) === String(resourceId)) ?? ({} as any);

  const resource = {
    id: option?.id,
    name: option?.name,
    status: option?.status || "active",
  };

  const url = resource
    ? channelDefinition.getResourceUrl(
        resource,
        channel.destination.config,
        channel.config,
      )
    : "";

  return {
    url,
    resource,
    isLoading,
    error,
  };
};
