import {
  AggregationOption,
  AndOrCondition,
  AudienceAggregationType,
  ConditionType,
  DecisionEngineAnalyticsCampaignMetricType,
  DecisionEngineAnalyticsMetricDefinition,
  DecisionEngineInteractionColumnNames,
  isDecisionEngineAnalyticsAttributionMetricsDefinition,
  isDecisionEngineIncrementalityMetricDefinition,
  isDecisionEngineInteractionsMetricDefinition,
  PerUserAggregationType,
  PropertyCondition,
  StringOperator,
  SyntheticColumn,
  SyntheticMetricType,
} from "@hightouch/lib/query/visual/types";
import capitalize from "lodash/capitalize";
import { toSingleCondition } from "src/components/audiences/utils";
import {
  MinimalDecisionEngineFlowMessagesQuery,
  DecisionEngineInteractionFeatureColumnsQuery,
} from "src/graphql";
import {
  ColumnType,
  RawColumn,
  RelatedColumn,
  Relationship,
  TAG_FEATURE_PREFIX,
} from "src/types/visual";
import {
  AggregationOptions,
  isLiftNormalizationType,
} from "../metrics/constants";
import { getAggregationConfiguration } from "../metrics/utils";
import { MetricResultMaybeFromCache } from "./hooks/use-metric-series";
import { formatSyntheticGroupBys } from "./synthetic-column-utils";
import {
  isSyntheticMetric,
  MeasurementScope,
  MeasuringMode,
  MeasuringSelection,
  MeasuringSelectionColumn,
  MetricGroupOption,
  MetricSelection,
  SyntheticColumnValuesGetter,
} from "./types";

export type DecisionEngineResources = {
  outcomes: DecisionEngineOutcome[];
  flowMessages: MinimalDecisionEngineFlowMessage[];
};

export type DecisionEngineOutcome = {
  id: string;
  segment_id: number;
  name: string;
  attribution?: {
    // This is the only relevant field for analytics
    campaign_id_column?: string;
    [x: string]: unknown;
  };
  filter?: { subconditions: AndOrCondition<PropertyCondition>[] };
};

export type MinimalDecisionEngineFlowMessage =
  MinimalDecisionEngineFlowMessagesQuery["decision_engine_flow_messages"][number];

export type DecisionEngineFlow = {
  id: string;
  name: string;
  status: string;
};

export const formatMetricsForDecisionEngineScope = ({
  parentModelId,
  metricSelections,
  measuringSelection,
  measuringMode,
  cumulative,
  decisionEngineResources,
}: {
  parentModelId: string | undefined;
  metricSelections: MetricSelection[];
  measuringSelection: MeasuringSelection | undefined;
  measuringMode: MeasuringMode | undefined;
  cumulative: boolean;
  decisionEngineResources: DecisionEngineResources;
}) => {
  if (!parentModelId || !measuringSelection) {
    // Bail if there is no parent model ID or flow selected since they're needed to make queries
    return {
      metricIds: [],
      metricDefinitions: [],
    };
  }

  const metricDefinitions: DecisionEngineAnalyticsMetricDefinition[] = [];
  metricSelections.forEach((metricSelection) => {
    if (!metricSelection.id) {
      return;
    }

    const eventModelId = metricSelection?.eventModelId?.toString() ?? "";
    const relationshipId = metricSelection.relationshipId?.toString() ?? "";
    const column = metricSelection.column?.column_reference ?? null;

    // We want to calculate a cumulative aggregation for AID flows since it won't be
    // be accurate on the client side for some of the aggregation options (e.g. unique users).
    // `getAggregationConfiguration` should determine when the cumulative audienceAggregation
    // should be used.
    const aggregationConfiguration = getAggregationConfiguration(
      metricSelection?.aggregationMethod ?? AggregationOption.Count,
      cumulative,
    ) ?? {
      aggregation: PerUserAggregationType.Count,
      audienceAggregation: AudienceAggregationType.Sum,
    };

    metricDefinitions.push(
      formatDecisionEngineAnalyticsCampaignMetric({
        parentModelId,
        aggregationConfiguration,
        eventModelId,
        relationshipId,
        metricSelection,
        flowId: measuringSelection.id.toString(),
        measuringMode,
        column,
        outcomes: decisionEngineResources.outcomes,
        flowMessageCampaignIds: getCampaignIdsFromFlowMessages(
          decisionEngineResources.flowMessages,
        ),
      }),
    );
  });

  return {
    metricIds: [], // Don't support top level metrics (goals) for measuring AID
    metricDefinitions,
  };
};

export const formatDecisionEngineAnalyticsCampaignMetric = ({
  parentModelId,
  aggregationConfiguration,
  eventModelId,
  relationshipId,
  metricSelection,
  flowId,
  measuringMode,
  column,
  outcomes,
  flowMessageCampaignIds,
}: {
  parentModelId: string;
  aggregationConfiguration: {
    aggregation: PerUserAggregationType;
    audienceAggregation: AudienceAggregationType;
  };
  eventModelId: string;
  relationshipId: string;
  metricSelection: MetricSelection;
  flowId: string;
  measuringMode: MeasuringMode | undefined;
  column: RawColumn | RelatedColumn | undefined;
  outcomes: DecisionEngineOutcome[];
  flowMessageCampaignIds: string[];
}): DecisionEngineAnalyticsMetricDefinition => {
  const metricType = getFlowMetricType(measuringMode, metricSelection);

  if (metricType === DecisionEngineAnalyticsCampaignMetricType.Interactions) {
    return {
      type: "decision_engine_flow",
      flowId,
      parentModelId,
      ...aggregationConfiguration,
      metricType,
      config: {
        column: column ?? metricSelection.column?.column_reference,
        filter: {
          subconditions: metricSelection.conditions,
        },
        // Let resolver validation handle undefined values since these aren't
        // set by the users (the source and resource ID should be specified in the option)
        source: metricSelection.source as SyntheticMetricType,
        resourceId: metricSelection.resourceId as string,
      },
    };
  }

  const outcome = outcomes?.find(
    (outcome) => outcome.id === metricSelection.id,
  );

  return {
    type: "decision_engine_flow",
    flowId,
    parentModelId,
    ...aggregationConfiguration,
    metricType,
    config: {
      eventModelId,
      relationshipId,
      column: column ?? metricSelection.column?.column_reference,
      filter: {
        subconditions: getFilterSubconditionsForMetric(
          metricSelection,
          outcome,
          flowMessageCampaignIds,
        ),
      },
      normalization: metricSelection.normalization,
    },
  };
};

const getFlowMetricType = (
  measuringMode: MeasuringMode | undefined,
  metricSelection: MetricSelection,
) => {
  if (measuringMode === MeasuringMode.Incrementality) {
    return DecisionEngineAnalyticsCampaignMetricType.Incrementality;
  } else if (isSyntheticMetric(metricSelection)) {
    return DecisionEngineAnalyticsCampaignMetricType.Interactions;
  }

  return DecisionEngineAnalyticsCampaignMetricType.AttributedEvents;
};

/**
 * Get the subconditions to filter on for the selected metric (outcome model)
 * Along with the ad hoc filters users can add to a selected metric, we also
 * want to include the outcome's filter conditions. The outcome's filter is configured
 * in the AID and is used to filter event models to define certain outcomes (e.g.
 * purchase/subscribe/etc event types). We don't want to expose this filter to the
 * the user, but we do want to include it in the metric calculation so the BE can filter
 * the underlying event model accordingly. (Doing it in the FE since we have the info we
 * need so the BE doesn't need to gather it again)
 */
const getFilterSubconditionsForMetric = (
  metricSelection: MetricSelection,
  outcome: DecisionEngineOutcome | undefined,
  flowMessageCampaignIds: string[],
): AndOrCondition<PropertyCondition>[] => {
  const campaignIdColumn = outcome?.attribution?.campaign_id_column;
  const outcomeConditions = outcome?.filter?.subconditions ?? [];

  const filterConditions: AndOrCondition<PropertyCondition>[] = [
    ...(campaignIdColumn
      ? [
          {
            type: ConditionType.And,
            conditions: [
              {
                type: ConditionType.Property,
                propertyType: ColumnType.String,
                property: campaignIdColumn,
                operator: StringOperator.Equals,
                value: flowMessageCampaignIds,
              },
            ],
          } as AndOrCondition<PropertyCondition>,
        ]
      : []),
    ...outcomeConditions,
    ...metricSelection.conditions,
  ];

  return (toSingleCondition(filterConditions) ??
    []) as AndOrCondition<PropertyCondition>[];
};

export const decisionEngineFlowMetricOptions = (
  flowId: string,
  measuringMode: MeasuringMode | undefined,
  events: Relationship[] | undefined,
  outcomes: DecisionEngineOutcome[] | undefined,
): MetricGroupOption[] => {
  const metrics: MetricGroupOption[] = [
    {
      label: "Outcomes",
      options: (outcomes ?? [])
        .map((outcome) => {
          const relationship = events?.find(
            (e) => e.to_model?.id === outcome.segment_id,
          );

          return {
            id: outcome.id,
            eventModelId: outcome.segment_id,
            name: outcome.name,
            relationshipId: relationship?.id,
            description: null,
          };
        })
        .filter((outcome) => outcome.relationshipId),
    },
  ];

  if (measuringMode !== MeasuringMode.Incrementality) {
    metrics.push({
      label: "Interactions",
      options: [
        {
          // IDs will reference the resource's ID (SyntheticUserDefinedMetricConfig)
          id: flowId,
          eventModelId: null,
          resourceId: flowId,
          source: SyntheticMetricType.DecisionEngineInteractions,
          name: "AI Decisioning Interactions",
          description: null,
        },
      ],
    });
  }

  return metrics;
};

const supportedAggregations = [
  AggregationOption.Count,
  AggregationOption.SumOfProperty,
  AggregationOption.UniqueUsers,
  AggregationOption.AverageOfProperty,
];

export const DecisionEngineAggregationOptions = AggregationOptions.filter(
  (opt) => supportedAggregations.includes(opt.value),
);

export const formatGroupsFromDecisionEngineMetricResult = (
  result: MetricResultMaybeFromCache[],
  syntheticColumnValuesOptions: SyntheticColumnValuesGetter | undefined,
) => {
  return result.map((series) => {
    // XXX: Temporary solution to remove holdout group from metric result from the
    // client side by default
    if (
      isDecisionEngineAnalyticsAttributionMetricsDefinition(
        series.ids.metricDefinition,
      ) &&
      "data" in series.result
    ) {
      return {
        ...series,
        result: {
          ...series.result,
          data: series.result.data
            .filter(
              ({ splitId }) => splitId !== "holdout" && splitId !== "uniform",
            )
            .map((seriesData) => ({
              ...seriesData,
              splitId: null,
              groupBy: formatSyntheticGroupBys(
                seriesData.groupBy,
                syntheticColumnValuesOptions,
              ),
            })),
        },
      };
    }

    if (
      isDecisionEngineIncrementalityMetricDefinition(
        series.ids.metricDefinition,
      ) &&
      "data" in series.result
    ) {
      const liftNormalized = isLiftNormalizationType(
        series.ids.metricDefinition.config.normalization,
      );

      return {
        ...series,
        result: {
          ...series.result,
          data: series.result.data.map(({ splitId, ...seriesData }) => ({
            ...seriesData,
            // Make it clear that we're measuring treatment lift vs specified group
            splitId: splitId && liftNormalized ? "vs " + splitId : splitId,
            groupBy: formatSyntheticGroupBys(
              seriesData.groupBy,
              syntheticColumnValuesOptions,
            ),
          })),
        },
      };
    }

    if (
      isDecisionEngineInteractionsMetricDefinition(
        series.ids.metricDefinition,
      ) &&
      "data" in series.result
    ) {
      return {
        ...series,
        result: {
          ...series.result,
          data: series.result.data.map((seriesData) => ({
            ...seriesData,
            groupBy: formatSyntheticGroupBys(
              seriesData.groupBy,
              syntheticColumnValuesOptions,
            ),
          })),
        },
      };
    }

    return series;
  });
};

const DEFAULT_INTERACTION_COLUMNS: MeasuringSelectionColumn[] = [
  {
    alias: "User ID",
    columnReference: {
      type: "decision_engine_interaction",
      name: DecisionEngineInteractionColumnNames.UserId,
    },
    columnType: ColumnType.String,
  },
];

const DEFAULT_ACTION_FEATURES_COLUMNS: MeasuringSelectionColumn[] = [
  {
    alias: "Channel",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "channel",
    },
    columnType: ColumnType.String,
  },
  {
    alias: "Day of week",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "day_of_week",
    },
    columnType: ColumnType.String,
  },
  {
    alias: "Time of day",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "time_of_day",
    },
    columnType: ColumnType.String,
  },
  {
    alias: "Frequency",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "frequency_arm",
    },
    columnType: ColumnType.String,
  },
  {
    alias: "Message",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "message",
    },
    columnType: ColumnType.String,
  },
];

const getUserFeatureColumnType = (type: "categorical" | "boolean") => {
  switch (type) {
    case "categorical":
      return ColumnType.String;
    case "boolean":
    default:
      return ColumnType.String;
  }
};

type DecisionEngineInteractionFeatureColumnResult =
  | (DecisionEngineInteractionFeatureColumnsQuery["decision_engines"][0] & {
      // Union with explicit type so it's clearer what we expect from the JSON fields that
      flows: Array<{
        messages: Array<{
          message: {
            variables: any | null;
            tags: { [tagKey: string]: string } | null;
          };
        }>;
      }>;
    })
  | undefined;

/**
 * Extract and format the columns from the decision engine columns query. These
 * column schemas come from different resources but fetched in one query.
 *
 * Within a decision engine flow, we have these different types of interaction columns:
 * - Interactions
 * - User features
 * - Action features
 *
 * User features have different types but we only want to expose categorical (ie. text)
 *  and booleans for now.
 * Action features are always *string* type
 */
export const decisionEngineFlowSyntheticColumns = (
  measurmentMode: MeasuringMode | undefined,
  result: DecisionEngineInteractionFeatureColumnResult,
): {
  columns: MeasuringSelectionColumn[];
  additionalUserColumns: MeasuringSelectionColumn[];
} => {
  const config = result?.config;
  const customUserFeaturesColumns = (config?.user_feature_schema ?? [])
    .filter((u) => u.type === "categorical" || u.type === "boolean")
    .map((u) => ({
      alias: u.name,
      columnReference: {
        type: "decision_engine_interaction_user_features",
        name: `${u.name.toLowerCase()}`,
      },
      columnType: getUserFeatureColumnType(u.type),
    }));

  if (measurmentMode === MeasuringMode.Incrementality) {
    return {
      columns: [],
      additionalUserColumns: customUserFeaturesColumns,
    };
  }

  const messages = result?.flows[0]?.messages ?? [];
  const customActionFeatures = new Map();

  // Want to capture the unique action features across all messages within the flow
  for (const m of messages) {
    const variables = m.message.variables ?? [];

    for (const v of variables) {
      // Only want to include if there are values specified for the feature
      if (v?.name && v?.values.length && !customActionFeatures.has(v.name)) {
        customActionFeatures.set(v.name, {
          alias: v.name,
          columnReference: {
            type: "decision_engine_interaction_action_features" as const,
            name: v.name,
          },
          columnType: ColumnType.String,
        });
      }
    }

    const tags = m.message.tags ?? {};
    for (const [tagKey, tagValue] of Object.entries(tags)) {
      if (tagValue != null && !customActionFeatures.has(tagKey)) {
        customActionFeatures.set(tagKey, {
          alias: tagKey,
          columnReference: {
            type: "decision_engine_interaction_action_features" as const,
            name: `${TAG_FEATURE_PREFIX}${tagKey}`,
          },
          columnType: ColumnType.String,
          description: "AI decisioning message tag",
        });
      }
    }
  }

  const customActionFeaturesColumns = Array.from(customActionFeatures).map(
    ([_name, column]) => column,
  );

  const interactionColumns = [
    ...DEFAULT_INTERACTION_COLUMNS,
    ...DEFAULT_ACTION_FEATURES_COLUMNS,
    ...customActionFeaturesColumns,
  ];

  return {
    columns: interactionColumns,
    additionalUserColumns: customUserFeaturesColumns,
  };
};

export const flowSyntheticColumnValuesGetter = (
  flowMessages: MinimalDecisionEngineFlowMessage[],
): SyntheticColumnValuesGetter => {
  return (col: SyntheticColumn) => {
    if (
      !flowMessages.length ||
      col.type !== "decision_engine_interaction_action_features" ||
      // Only values getter for these columns
      (col.name !== "message" && col.name !== "channel")
    ) {
      return [];
    }

    return flowMessages.map(({ message }) => {
      const channelType = message.channel.type;
      const channelSyncType = message.channel.config?.type;
      const channelDestination = message.channel.destination.name;

      if (col.name === "channel") {
        return {
          value: message.channel.id,
          label: `${capitalize(channelType)} (${channelDestination}${channelSyncType ? ` - ${channelSyncType}` : ""})`,
        };
      }

      return {
        value: message.id,
        label: `${message.name} (${channelDestination ? `${channelDestination} - ` : ""}${channelType})`,
      };
    });
  };
};

// Reference `generateAndPullOutcomesTable`
export const getCampaignIdsFromFlowMessages = (
  flowMessages: MinimalDecisionEngineFlowMessage[],
): string[] => {
  return flowMessages.flatMap(({ config }) => {
    const campaignIds: string[] = [];

    if (config?.campaignId) {
      campaignIds.push(config.campaignId.toString());
    }
    if (config?.resourceId) {
      campaignIds.push(config.resourceId.toString());
    }
    if (config?.attributionCampaignIds) {
      campaignIds.push(...config.attributionCampaignIds.map(String));
    }

    return campaignIds;
  });
};

export const isMeasuringDecisionEngineIncrementality = (
  scope: MeasurementScope | undefined,
  mode: MeasuringMode | undefined,
) => {
  return (
    scope === MeasurementScope.DecisionEngineFlow &&
    mode === MeasuringMode.Incrementality
  );
};
