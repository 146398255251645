import { Link, Navigate, useOutletContext } from "src/router";
import { Page } from "src/components/layout";
import { DecisionEngine, OutletContext } from ".";
import {
  Text,
  Column,
  Row,
  StatusBadge,
  Alert,
  FormField,
  TextInput,
  Select,
  Heading,
  Button,
  NumberInput,
  useToast,
  ButtonGroup,
  Spinner,
  ConfirmationDialog,
  Paragraph,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useDisclosure,
  Combobox,
  Textarea,
  Checkbox,
  Switch,
  IconButton,
  ExternalLinkIcon,
  Tooltip,
  CheckIcon,
  CopyIcon,
  Box,
  useClipboard,
} from "@hightouchio/ui";
import { Editor } from "src/components/editor";
import json5 from "json5";
import { Card } from "src/components/card";
import { Value } from "@sinclair/typebox/value";
import {
  CollectionInputDryRunTypebox,
  DecisionEngineFlowConfig,
  DecisionEngineStatus,
  ModelConfigurationTypebox,
} from "@hightouch/lib/customer-data/decision-engine/types";
import {
  useAnalyzeDecisionEngineTagsForMessageQuery,
  useEligibleTargetAudiencesQuery,
  useGetTagsForMessagesBackgroundQuery,
  useResetAttributionStateMutation,
  useResetStateMutation,
  useDryRunDecisionEngineMutation,
  useRunSqlResultQuery,
  RunSqlResultQuery,
  useSetDecisionEngineCollectionConfigMutation,
  useSetDecisionEngineConfigMutation,
  useSetDecisionEngineOutcomeConfigMutation,
  useUpdateDecisionEngineFlowMutation,
  useUpdateDecisionEngineMutation,
  useValidateDecisionEngineConfigQuery,
  useExperimentsQuery,
  useUpsertExperimentsMutation,
  useClearCachedStateMutation,
  useCreateCollectionMutation,
  useCreateDecisionEngineMessageMutation,
  DryRunDecisionEngineMutationVariables,
  useFeatureSqlBackgroundQuery,
  useDecisionEngineTableNamesQuery,
} from "src/graphql";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { Controller } from "react-hook-form";
import { useUser } from "src/contexts/user-context";
import { useEffect, useState } from "react";
import {
  DemoCollectionInputDefinition,
  DemoDefinition,
  DemoMessageInputDefinition,
  TEMPLATE_IDS,
} from "./demo";
import { noop } from "ts-essentials";

export const Admin = () => {
  const { user } = useUser();
  const { engine } = useOutletContext<OutletContext>();
  const { toast } = useToast();

  const [resetStateOpen, setResetStateOpen] = useState<boolean>(false);
  const [resetAttributionStateOpen, setResetAttributionStateOpen] =
    useState<boolean>(false);
  const [clearCachedStateOpen, setClearCachedStateOpen] =
    useState<boolean>(false);
  const updateMutation = useUpdateDecisionEngineMutation();
  const validateQuery = useValidateDecisionEngineConfigQuery({
    decisionEngineId: engine.id,
  });
  const resetMutation = useResetStateMutation();
  const resetAttributionMutation = useResetAttributionStateMutation();
  const clearCachedStateMutation = useClearCachedStateMutation();
  const isValid =
    validateQuery.data?.validateDecisionEngineConfig.__typename ===
    "DecisionEngineSuccess";

  if (!user?.can_impersonate) {
    return <Navigate to="/ai/flows" />;
  }

  return (
    <Page title="Admin">
      <Column gap={6}>
        {validateQuery.data?.validateDecisionEngineConfig.__typename ===
          "DecisionEngineError" && (
          <Alert
            type="error"
            title="Validation error"
            message={JSON.stringify(
              validateQuery.data?.validateDecisionEngineConfig.error,
            )}
          />
        )}
        <Row gap={4} align="center" justify="space-between">
          <Row gap={4} align="center">
            <Heading size="xl">AI Admin</Heading>
            {validateQuery.isFetching ? (
              <Spinner />
            ) : (
              <StatusBadge variant={isValid ? "success" : "error"}>
                {isValid ? "VALID" : "INVALID"}
              </StatusBadge>
            )}
          </Row>

          <ButtonGroup size="lg">
            {engine.status === DecisionEngineStatus.PENDING ? (
              <Button
                variant="primary"
                isDisabled={!isValid}
                onClick={async () => {
                  try {
                    await updateMutation.mutateAsync({
                      id: engine.id,
                      input: { status: DecisionEngineStatus.READY },
                    });
                  } catch (error) {
                    toast({
                      id: "ready",
                      title: "Error",
                      message: error.message,
                      variant: "error",
                    });
                  }
                }}
              >
                Ready engine
              </Button>
            ) : (
              <>
                <Button
                  onClick={async () => {
                    try {
                      await updateMutation.mutate({
                        id: engine.id,
                        input: { status: DecisionEngineStatus.PENDING },
                      });
                    } catch (error) {
                      toast({
                        id: "ready",
                        title: "Error",
                        message: error.message,
                        variant: "error",
                      });
                    }
                  }}
                >
                  Unready engine
                </Button>
                <Button
                  variant="danger"
                  onClick={() => setResetStateOpen(true)}
                  isDisabled={resetMutation.isLoading}
                >
                  Reset warehouse state
                </Button>
                <Button
                  variant="danger"
                  onClick={() => setResetAttributionStateOpen(true)}
                  isDisabled={resetAttributionMutation.isLoading}
                >
                  Reset attribution state
                </Button>
              </>
            )}
            <Button
              variant="warning"
              onClick={() => setClearCachedStateOpen(true)}
              isDisabled={clearCachedStateMutation.isLoading}
            >
              Clear cached state
            </Button>
            <Button
              isLoading={validateQuery.isFetching}
              onClick={() => {
                validateQuery.refetch();
              }}
            >
              Validate
            </Button>
          </ButtonGroup>
        </Row>
        <Tabs>
          <TabList>
            <Tab>Engine</Tab>
            <Tab>Flows</Tab>
            <Tab>Outcomes</Tab>
            <Tab>Collections</Tab>
            <Tab>Demo</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Engine />
            </TabPanel>
            <TabPanel>
              <Column gap={6}>
                {engine.flows.sort().map((flow) => (
                  <Flow key={flow.id} flow={flow} />
                ))}
              </Column>
            </TabPanel>
            <TabPanel>
              <Column gap={6}>
                {engine.outcomes.sort().map((outcome) => (
                  <Outcome key={outcome.id} outcome={outcome} />
                ))}
              </Column>
            </TabPanel>
            <TabPanel>
              <Column gap={6}>
                {engine.collections.sort().map((collection) => (
                  <Collection key={collection.id} collection={collection} />
                ))}
              </Column>
            </TabPanel>
            <TabPanel>
              <Demo />
            </TabPanel>
          </TabPanels>
        </Tabs>

        <ConfirmationDialog
          isOpen={resetAttributionStateOpen}
          title="Reset attribution state"
          confirmButtonText="Reset attribution state"
          variant="danger"
          onClose={() => setResetAttributionStateOpen(false)}
          onConfirm={async () => {
            resetAttributionMutation.mutate({ decisionEngineId: engine.id });
          }}
        >
          <Paragraph>
            Are you sure you want to reset the attribution state? You won't be
            able to undo this.
          </Paragraph>
        </ConfirmationDialog>

        <ConfirmationDialog
          isOpen={resetStateOpen}
          title="Reset state"
          confirmButtonText="Reset state"
          variant="danger"
          onClose={() => setResetStateOpen(false)}
          onConfirm={async () => {
            resetMutation.mutate({ decisionEngineId: engine.id });
          }}
        >
          <Paragraph>
            Are you sure you want to reset the state? You won't be able to undo
            this.
          </Paragraph>
        </ConfirmationDialog>
        <ConfirmationDialog
          isOpen={clearCachedStateOpen}
          title="Clear cached state"
          confirmButtonText="Clear cached state"
          variant="warning"
          onClose={() => setClearCachedStateOpen(false)}
          onConfirm={async () => {
            clearCachedStateMutation.mutate({ decisionEngineId: engine.id });
          }}
        >
          <Paragraph>
            Are you sure you want to clear the cached state?
          </Paragraph>
        </ConfirmationDialog>
      </Column>
    </Page>
  );
};

const DryRun = () => {
  const { engine } = useOutletContext<OutletContext>();
  const flows = engine.flows;
  const dryRunEngine = useDryRunDecisionEngineMutation();
  const modelsQuery = useEligibleTargetAudiencesQuery(
    {
      parentModelId: engine.segment.id,
    },
    {
      select: (data) => data.segments,
    },
  );

  const [actionModelConfigError, setActionModelConfigError] = useState<
    string | undefined
  >(undefined);

  const [collectionModelConfigError, setCollectionModelConfigError] = useState<
    string | undefined
  >(undefined);

  const form = useHightouchForm({
    onSubmit: async (data) => {
      const input: DryRunDecisionEngineMutationVariables = {
        id: engine.id,
        actionModelConfiguration: data.actionModelConfiguration
          ? json5.parse(data.actionModelConfiguration)
          : undefined,
        collectionModelConfiguration: data.collectionModelConfiguration
          ? json5.parse(data.collectionModelConfiguration)
          : undefined,
        banditConfiguration: data.banditConfiguration
          ? json5.parse(data.banditConfiguration)
          : undefined,
        forceAllUsers: data.forceAllUsers,
        messageCompletionIntervalDays:
          data.messageCompletionIntervalDays != null
            ? Number(data.messageCompletionIntervalDays)
            : undefined,
        timing: data.timing ? json5.parse(data.timing) : undefined,
      };

      if (data.audience_id != undefined) {
        input.overrideAudienceId = data.audience_id.toString();
      }

      if (data.flow_id != undefined) {
        input.flowId = data.flow_id.toString();
      }

      await dryRunEngine.mutateAsync(input);
    },
  });

  // Basically used to do validation on the model config
  async function actionModelConfigurationChangeHandler(
    e: string,
    onChange: (value: string) => void,
  ) {
    // We allow an empty config
    if (e.length == 0) {
      setActionModelConfigError(undefined);
      return;
    }

    try {
      const jsonConfig = json5.parse(e);
      const errors = Array.from(
        Value.Errors(ModelConfigurationTypebox, jsonConfig),
      );
      if (errors.length > 0) {
        setActionModelConfigError(
          errors
            .map((e) => `Error with property ${e.path}: ${e.message}`)
            .join("\n"),
        );
        return;
      }

      onChange(e);
      setActionModelConfigError(undefined);
    } catch (error) {
      console.error("Parsing error: ", error);
      setActionModelConfigError(error.message);
    }
  }

  // Basically used to do validation on the model config
  async function collectionModelConfigurationChangeHandler(
    e: string,
    onChange: (value: string) => void,
  ) {
    // We allow an empty config
    if (e.length == 0) {
      setCollectionModelConfigError(undefined);
      return;
    }

    try {
      const jsonConfig = json5.parse(e);
      const errors = Array.from(
        Value.Errors(CollectionInputDryRunTypebox, jsonConfig),
      );
      if (errors.length > 0) {
        setCollectionModelConfigError(
          errors
            .map((e) => `Error with property ${e.path}: ${e.message}`)
            .join("\n"),
        );
        return;
      }

      onChange(e);
      setCollectionModelConfigError(undefined);
    } catch (error) {
      console.error("Parsing error: ", error);
      setCollectionModelConfigError(error.message);
    }
  }

  return (
    <Form form={form}>
      <Card maxHeight="1500px" overflow="hidden" gap={4}>
        <Column>
          <Heading>Dry Run</Heading>
        </Column>
        <Controller
          name="audience_id"
          render={({ field }) => (
            <FormField
              label="Target override audience"
              description="If omitted, defaults to the audience configured on the flow."
            >
              <Combobox
                {...field}
                isClearable={true}
                isLoading={modelsQuery.isLoading}
                optionValue={(option) => option.id}
                optionLabel={(option) => option.name}
                options={modelsQuery.data ?? []}
              />
            </FormField>
          )}
        />

        <Controller
          name="flow_id"
          render={({ field }) => (
            <FormField
              label="Target flow"
              description="If omitted, runs all enabled flows."
            >
              <Combobox
                {...field}
                isClearable={true}
                optionValue={(option) => option.id}
                optionLabel={(option) => option.name}
                options={flows ?? []}
              />
            </FormField>
          )}
        />

        <Controller
          name="actionModelConfiguration"
          render={({ field }) => (
            <FormField
              label="Action model configuration overrides"
              error={actionModelConfigError}
            >
              <Column overflow="hidden" flex={1} height="200px">
                <Editor
                  language="json"
                  bg="base.background"
                  {...field}
                  onChange={(e) =>
                    actionModelConfigurationChangeHandler(e, field.onChange)
                  }
                />
              </Column>
            </FormField>
          )}
        />

        <Controller
          name="collectionModelConfiguration"
          render={({ field }) => (
            <FormField
              label="Collection model configuration overrides"
              error={collectionModelConfigError}
            >
              <Column overflow="hidden" flex={1} height="200px">
                <Editor
                  language="json"
                  bg="base.background"
                  {...field}
                  onChange={(e) =>
                    collectionModelConfigurationChangeHandler(e, field.onChange)
                  }
                />
              </Column>
            </FormField>
          )}
        />

        <Controller
          name="banditConfiguration"
          render={({ field }) => (
            <FormField label="Bandit configuration overrides">
              <Column overflow="hidden" flex={1} height="200px">
                <Editor
                  language="json"
                  bg="base.background"
                  {...field}
                  onChange={(e) => field.onChange(e)}
                />
              </Column>
            </FormField>
          )}
        />

        <Controller
          name="timing"
          render={({ field }) => (
            <FormField label="Timing overrides">
              <Column overflow="hidden" flex={1} height="200px">
                <Editor language="json" bg="base.background" {...field} />
              </Column>
            </FormField>
          )}
        />

        <Controller
          name="forceAllUsers"
          render={({ field }) => (
            <FormField label="Force all users">
              <Checkbox
                isChecked={field.value}
                isDisabled={false}
                onChange={field.onChange}
              />
            </FormField>
          )}
        />

        <Controller
          name="messageCompletionIntervalDays"
          render={({ field }) => (
            <FormField label="Days to complete message">
              <TextInput {...field} type="number" />
            </FormField>
          )}
        />

        <Row>
          <Button
            variant="primary"
            isDisabled={
              dryRunEngine.isLoading ||
              modelsQuery.isLoading ||
              actionModelConfigError !== undefined
            }
            onClick={async () => {
              await form.submit();
            }}
          >
            Run dry run
          </Button>
        </Row>
      </Card>
    </Form>
  );
};

const Engine = () => {
  const { engine } = useOutletContext<OutletContext>();

  const mutation = useSetDecisionEngineConfigMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await mutation.mutateAsync({
        decisionEngineId: engine.id,
        config: {
          name: data.name,
          feature_model_id: Number(data.feature_model_id),
          user_feature_schema: json5.parse(data.user_feature_schema),
          output_schema: data.output_schema,
          attribution: data.attribution,
          message_completion_interval_days:
            data.message_completion_interval_days
              ? Number(data.message_completion_interval_days)
              : undefined,
          interactions_historical_days_to_pull:
            data.interactions_historical_days_to_pull
              ? Number(data.interactions_historical_days_to_pull)
              : undefined,
          touch_cached_interactions_older_than_days:
            data.touch_cached_interactions_older_than_days
              ? Number(data.touch_cached_interactions_older_than_days)
              : undefined,
          action_model_configuration: data.action_model_configuration
            ? json5.parse(data.action_model_configuration)
            : null,
          slack_channel: data.slack_channel,
          scheduling_timezone: data.scheduling_timezone,
          ignore_s3_state: data.ignore_s3_state,
        },
      });
    },
    values: {
      attribution: engine.config.attribution ?? {
        window: { unit: null, value: 1 },
      },
      feature_model_id: engine.config.feature_model_id ?? "",
      name: engine.config.name ?? "",
      output_schema: engine.config.output_schema ?? "",
      message_completion_interval_days:
        engine.config.message_completion_interval_days ?? "",
      interactions_historical_days_to_pull:
        engine.config.interactions_historical_days_to_pull ?? "",
      touch_cached_interactions_older_than_days:
        engine.config.touch_cached_interactions_older_than_days ?? "",
      user_feature_schema: engine.config.user_feature_schema
        ? JSON.stringify(engine.config.user_feature_schema, null, 2)
        : "",
      action_model_configuration: engine.config.action_model_configuration
        ? JSON.stringify(engine.config.action_model_configuration, null, 2)
        : "",
      slack_channel: engine.config.slack_channel ?? "",
      scheduling_timezone: engine.config.scheduling_timezone,
      ignore_s3_state: engine.config.ignore_s3_state,
    },
  });

  return (
    <Form form={form}>
      <Column gap={6}>
        <Card overflow="hidden" footer={<FormActions />} gap={4}>
          <Row align="center" gap={2}>
            <Heading>Engine</Heading>
            <StatusBadge
              variant={
                engine.status === DecisionEngineStatus.TRAINING
                  ? "processing"
                  : engine.status === DecisionEngineStatus.READY
                    ? "success"
                    : "inactive"
              }
            >
              {engine.status.toUpperCase()}
            </StatusBadge>
            <StatusBadge variant={engine.enabled ? "success" : "inactive"}>
              {engine.enabled ? "ENABLED" : "DISABLED"}
            </StatusBadge>
          </Row>
          <Text size="sm" color="text.secondary">
            {engine.id}
          </Text>

          {mutation.data?.setDecisionEngineConfig.__typename ===
            "DecisionEngineError" && (
            <Alert
              type="error"
              variant="inline"
              title="Error"
              message={JSON.stringify(
                mutation.data.setDecisionEngineConfig.error,
              )}
            />
          )}

          <Row gap={8}>
            <Column gap={4}>
              <Controller
                name="name"
                render={({ field }) => (
                  <FormField label="Name">
                    <TextInput {...field} />
                  </FormField>
                )}
              />
              <Controller
                name="feature_model_id"
                render={({ field }) => (
                  <FormField label="Feature Model ID">
                    <TextInput {...field} />
                  </FormField>
                )}
              />
              <Controller
                name="output_schema"
                render={({ field }) => (
                  <FormField label="Output Schema">
                    <TextInput {...field} />
                  </FormField>
                )}
              />
              <Controller
                name="message_completion_interval_days"
                render={({ field }) => (
                  <FormField label="Days to complete message">
                    <TextInput {...field} type="number" />
                  </FormField>
                )}
              />
              <Controller
                name="interactions_historical_days_to_pull"
                render={({ field }) => (
                  <FormField label="Days to pull interactions">
                    <TextInput {...field} type="number" />
                  </FormField>
                )}
              />
              <Controller
                name="touch_cached_interactions_older_than_days"
                render={({ field }) => (
                  <FormField label="Touch cached interactions older than days">
                    <TextInput {...field} type="number" />
                  </FormField>
                )}
              />
              <Controller
                name="attribution.window.unit"
                render={({ field }) => (
                  <FormField label="Attribution Window Unit">
                    <Select
                      {...field}
                      options={[
                        "minute",
                        "hour",
                        "day",
                        "week",
                        "month",
                        "year",
                      ]}
                      optionValue={(value) => value}
                      optionLabel={(value) => value}
                    />
                  </FormField>
                )}
              />
              <Controller
                name="attribution.window.value"
                render={({ field }) => (
                  <FormField label="Attribution Window Value">
                    <NumberInput {...field} />
                  </FormField>
                )}
              />
              <Controller
                name="slack_channel"
                render={({ field }) => (
                  <FormField label="Slack Channel">
                    <TextInput {...field} />
                  </FormField>
                )}
              />
              <Controller
                name="scheduling_timezone"
                render={({ field }) => (
                  <FormField label="Scheduling Timezone">
                    <TextInput {...field} placeholder="America/Los_Angeles" />
                  </FormField>
                )}
              />
              <Controller
                name="ignore_s3_state"
                render={({ field }) => (
                  <FormField label="Ignore S3 State">
                    <Checkbox
                      isChecked={field.value}
                      onChange={field.onChange}
                    />
                  </FormField>
                )}
              />
            </Column>
            <Column flex={1} gap={4}>
              <Controller
                name="user_feature_schema"
                render={({ field }) => (
                  <Column overflow="hidden" flex={1} maxHeight="400px">
                    <Row gap={2} align="center" width="fit-content" py={1}>
                      <Text fontWeight="medium" mb={1}>
                        user_feature_schema
                      </Text>
                      {engine.config.feature_model_id && (
                        <Link
                          href={`/schema-v2/view/query?source=${engine.segment.connection?.id}&id=${engine.config.feature_model_id}`}
                        >
                          <IconButton
                            icon={ExternalLinkIcon}
                            aria-label="Link to feature model"
                            variant="tertiary"
                            onClick={noop}
                          />
                        </Link>
                      )}
                    </Row>
                    <Editor language="json" bg="base.background" {...field} />
                  </Column>
                )}
              />
              <Controller
                name="action_model_configuration"
                render={({ field }) => (
                  <Column overflow="hidden" flex={1} maxHeight="400px">
                    <Text fontWeight="medium" mb={1}>
                      action_model_configuration
                    </Text>
                    <Editor language="json" bg="base.background" {...field} />
                  </Column>
                )}
              />
            </Column>
          </Row>
        </Card>

        <DryRun />

        <ChannelTags />

        <MessageTags />
      </Column>
    </Form>
  );
};

const Experiments = ({ flowId }: { flowId: string }) => {
  const experimentsQuery = useExperimentsQuery({
    decisionEngineFlowId: flowId,
  });

  const mutation = useUpsertExperimentsMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await mutation.mutateAsync({
        experiments: [
          {
            name: "uniform",
            decision_engine_flow_id: flowId,
            audience_percent: data.uniform_audience_percent,
          },
          {
            name: "customer_managed",
            decision_engine_flow_id: flowId,
            audience_percent: data.customer_managed_audience_percent,
          },
        ],
      });
    },
    values: {
      uniform_audience_percent:
        experimentsQuery.data?.decision_engine_flow_experiments.find(
          (e) => e.name === "uniform",
        )?.audience_percent ?? 0,
      customer_managed_audience_percent:
        experimentsQuery.data?.decision_engine_flow_experiments.find(
          (e) => e.name === "customer_managed",
        )?.audience_percent ?? 0,
    },
  });

  return (
    <Form form={form}>
      <Card footer={<FormActions />} gap={4}>
        <Column gap={4}>
          <Heading>Experiments</Heading>

          <Controller
            name="uniform_audience_percent"
            render={({ field }) => (
              <FormField label="Uniform">
                <NumberInput {...field} />
              </FormField>
            )}
          />

          <Controller
            name="customer_managed_audience_percent"
            render={({ field }) => (
              <FormField label="Customer Managed">
                <NumberInput {...field} />
              </FormField>
            )}
          />
        </Column>
      </Card>
    </Form>
  );
};

const FeatureSql = ({ flowId }: { flowId: string }) => {
  const [runQuery, setRunQuery] = useState<null | { runSql: boolean }>(null);
  const [features, setFeatures] = useState<any[] | null>(null);
  const [shouldPoll, setShouldPoll] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { data } = useFeatureSqlBackgroundQuery(
    {
      flowId,
      runSql: runQuery?.runSql ?? false,
    },
    {
      enabled: Boolean(runQuery),
      select: (data) => data.featureSqlBackground,
    },
  );

  useEffect(() => {
    if (data?.jobId) {
      setShouldPoll(true);
    }
  }, [data?.jobId]);

  useRunSqlResultQuery(
    {
      jobId: String(data?.jobId),
      page: 0,
    },
    {
      enabled: Boolean(data?.jobId),
      refetchInterval: shouldPoll ? 1000 : 0,
      onSuccess: (data) => {
        if (!data.backgroundPreviewQueryResult) {
          return;
        }

        if (
          data.backgroundPreviewQueryResult.__typename === "FailedQueryResponse"
        ) {
          setError(data.backgroundPreviewQueryResult.error);
        } else {
          setFeatures(data.backgroundPreviewQueryResult.rows);
        }

        setShouldPoll(false);
      },
    },
  );

  return (
    <Card
      footer={
        <Row gap={2}>
          <Button
            onClick={() => {
              setRunQuery({ runSql: true });
            }}
          >
            Run Query
          </Button>
          <Button
            onClick={() => {
              setRunQuery({ runSql: false });
            }}
          >
            Get SQL
          </Button>
        </Row>
      }
    >
      <Column gap={4}>
        <Column>
          <Heading>Feature SQL</Heading>
        </Column>
        {data?.querySql && (
          <Column maxHeight="400px">
            <Editor
              language="sql"
              bg="base.background"
              value={data?.querySql}
            />
          </Column>
        )}
        {error && (
          <Column>
            <Text>{error}</Text>
          </Column>
        )}

        <Column maxHeight="400px">
          {shouldPoll ? (
            <Spinner />
          ) : features ? (
            <Editor
              language="json"
              bg="base.background"
              value={JSON.stringify(features, null, 2)}
            />
          ) : null}
        </Column>
      </Column>
    </Card>
  );
};

const Collection = ({
  collection,
}: {
  collection: DecisionEngine["collections"][0];
}) => {
  const { engine } = useOutletContext<OutletContext>();

  const mutation = useSetDecisionEngineCollectionConfigMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await mutation.mutate({
        decisionEngineId: engine.id,
        collectionId: collection.id,
        schema: json5.parse(data.schema),
      });
    },
    values: {
      schema: JSON.stringify(collection.config?.schema ?? {}, null, 2),
    },
  });

  return (
    <Form form={form}>
      <Card
        maxHeight="800px"
        overflow="hidden"
        footer={<FormActions />}
        gap={4}
      >
        <Column>
          <Heading>{collection.collection.name}</Heading>
          <Text size="sm" color="text.secondary">
            {collection.id}
          </Text>
        </Column>

        {mutation.data?.setDecisionEngineCollectionConfig.__typename ===
          "DecisionEngineError" && (
          <Alert
            type="error"
            variant="inline"
            title="Error"
            message={JSON.stringify(
              mutation.data.setDecisionEngineCollectionConfig.error,
            )}
          />
        )}

        <Column flex={1} overflow="hidden">
          <Controller
            name="schema"
            render={({ field }) => <Editor language="json" {...field} />}
          />
        </Column>
      </Card>
    </Form>
  );
};

const Outcome = ({ outcome }: { outcome: DecisionEngine["outcomes"][0] }) => {
  const { engine } = useOutletContext<OutletContext>();

  const mutation = useSetDecisionEngineOutcomeConfigMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await mutation.mutate({
        decisionEngineId: engine.id,
        outcomeId: outcome.id,
        ...data,
      });
    },
    values:
      // If unspecified, omit the metadataColumn so it gets set to null in the DB.
      outcome.attribution?.metadata_column !== ""
        ? {
            metadataColumn: outcome.attribution?.metadata_column,
          }
        : {},
  });

  return (
    <Form form={form}>
      <Card footer={<FormActions />} gap={4}>
        <Column>
          <Heading>{outcome.name}</Heading>
          <Text size="sm" color="text.secondary">
            {outcome.id}
          </Text>
        </Column>

        {mutation.data?.setDecisionEngineOutcomeConfig.__typename ===
          "DecisionEngineError" && (
          <Alert
            type="error"
            variant="inline"
            title="Error"
            message={JSON.stringify(
              mutation.data.setDecisionEngineOutcomeConfig.error,
            )}
          />
        )}

        <Controller
          name="metadataColumn"
          control={form.control}
          render={({ field }) => (
            <FormField label="metadataColumn">
              <TextInput {...field} />
            </FormField>
          )}
        />
      </Card>
    </Form>
  );
};

// Note: this is pretty much shamelessly stolen from the flow/messages/message/integration.tsx and modified slightly
// to not rely on the flow message context. We should probably do something smarter like a shared component, but this
// seems reasonable for now.
const TableReference = ({
  tableName,
  label,
}: {
  tableName: string;
  label: string;
}) => {
  const { onCopy, hasCopied } = useClipboard(tableName);

  return (
    <Row align="center" justify="space-between" w="100%" gap={4}>
      <Row gap={2} align="center">
        <Text>{label}</Text>
      </Row>

      <Tooltip placement="left" message="Copy to clipboard" openSpeed="slow">
        <Row
          onClick={onCopy}
          align="center"
          cursor="pointer"
          py={1}
          px={2}
          bg="gray.100"
          borderRadius="md"
          _hover={{
            bg: "gray.200",
            svg: {
              display: "inline-block",
            },
          }}
          gap={1}
          overflow="hidden"
        >
          {hasCopied ? (
            <CheckIcon />
          ) : (
            <Box as={CopyIcon} display="none" flexShrink={0} />
          )}
          <Box
            fontFamily="mono"
            color="text.secondary"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            dir="rtl"
          >
            {tableName}
          </Box>
        </Row>
      </Tooltip>
    </Row>
  );
};
const Flow = ({ flow }: { flow: DecisionEngine["flows"][0] }) => {
  const { engine } = useOutletContext<OutletContext>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const updateMutation = useUpdateDecisionEngineFlowMutation();

  const resetMutation = useResetStateMutation();

  const tableNamesQuery = useDecisionEngineTableNamesQuery({
    decisionEngineId: engine.id,
  });

  const flowTables = tableNamesQuery.data?.getDecisionEngineTableNames.find(
    (tables) => tables.flowId === flow.id,
  );

  const form = useHightouchForm({
    onSubmit: async (data) => {
      const config = data.config;

      if (data.banditConfig !== "") {
        config.bandits = json5.parse(data.banditConfig);
      }

      await updateMutation.mutateAsync({
        id: flow.id,
        input: {
          config: {
            ...config,
            flow_start_date: data.config.flow_start_date
              ? new Date(data.config.flow_start_date).toISOString()
              : null,
          },
        },
      });
    },
    values: {
      config: flow.config as DecisionEngineFlowConfig,
      banditConfig: flow.config.bandits
        ? JSON.stringify(flow.config.bandits, null, 2)
        : "",
    },
  });

  function formatDateToDatetimeLocal(date: Date) {
    const pad = (num) => String(num).padStart(2, "0");

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  return (
    <>
      <Column>
        <Heading>{flow.name}</Heading>
        <Text size="sm" color="text.secondary">
          {flow.id}
        </Text>
      </Column>

      <Card gap={4}>
        <Heading>Warehouse Tables</Heading>
        {tableNamesQuery.isLoading ? (
          <Spinner />
        ) : flowTables ? (
          <Column gap={2}>
            <TableReference
              label="Recommendations Table"
              tableName={flowTables.qualifiedRecommendationsTableName}
            />
            <TableReference
              label="Attribution Table"
              tableName={flowTables.qualifiedAttributionTableName}
            />
            <TableReference
              label="Users Frequency State Table"
              tableName={flowTables.qualifiedUsersFrequencyStateTableName}
            />
          </Column>
        ) : (
          <Text color="text.secondary">No table information available</Text>
        )}
      </Card>

      <Form form={form}>
        <Card
          footer={
            <Row justify="space-between" w="100%">
              <FormActions />
              <ButtonGroup>
                {flow.status === DecisionEngineStatus.TRAINING && (
                  <Button
                    size="lg"
                    onClick={async () => {
                      await updateMutation.mutate({
                        id: flow.id,
                        input: {
                          status: DecisionEngineStatus.READY,
                        },
                      });
                    }}
                    isLoading={updateMutation.isLoading}
                  >
                    Ready flow
                  </Button>
                )}
                <Button
                  size="lg"
                  variant="danger"
                  onClick={onOpen}
                  isDisabled={resetMutation.isLoading}
                >
                  Reset Warehouse state
                </Button>
              </ButtonGroup>
            </Row>
          }
          gap={4}
        >
          <Heading>Configuration</Heading>
          <Controller
            name="config.flow_start_date"
            control={form.control}
            render={({ field }) => (
              <FormField label="Start date">
                <TextInput
                  type="datetime-local"
                  {...field}
                  value={
                    field.value
                      ? formatDateToDatetimeLocal(new Date(field.value))
                      : ""
                  }
                />
              </FormField>
            )}
          />
          <Controller
            name="banditConfig"
            render={({ field }) => (
              <FormField
                label="Bandits"
                description={`Defaults to {"default": {"algorithm": "epsilon_greedy", "epsilon": 0.1}}`}
              >
                <Column overflow="hidden" flex={1} maxHeight="400px">
                  <Editor language="json" bg="base.background" {...field} />
                </Column>
              </FormField>
            )}
          />
        </Card>
      </Form>

      <Experiments flowId={flow.id} />

      <FeatureSql flowId={flow.id} />

      <ConfirmationDialog
        isOpen={isOpen}
        title="Reset state"
        confirmButtonText="Reset state"
        variant="danger"
        onClose={onClose}
        onConfirm={async () => {
          resetMutation.mutate({
            decisionEngineId: engine.id,
            flowId: flow.id,
          });
        }}
      >
        <Paragraph>
          Are you sure you want to reset the state? You won't be able to undo
          this.
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};

const ChannelTags = () => {
  const { engine } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const [tagsError, setTagsError] = useState("");
  const [shouldPoll, setShouldPoll] = useState(false);
  const [channel, setChannel] = useState("");

  const { data: jobId } = useGetTagsForMessagesBackgroundQuery(
    {
      decisionEngineChannelId: channel,
    },
    {
      select: (data) => data.getTagsForMessagesBackground,
      enabled: channel?.length > 0,
    },
  );

  const [tags, setTags] = useState<any>();

  useEffect(() => {
    setShouldPoll(true);
  }, [jobId]);

  useRunSqlResultQuery(
    {
      jobId: String(jobId),
      page: 0,
    },
    {
      enabled: Boolean(jobId),
      refetchInterval: shouldPoll ? 1000 : 0,
      onError: () => {
        setShouldPoll(false);
        toast({
          id: "tags",
          title: "Error",
          message: tagsError,
          variant: "error",
        });
      },
      onSuccess: (data) => {
        if (!data.backgroundPreviewQueryResult) {
          return;
        }
        if (
          data.backgroundPreviewQueryResult.__typename === "FailedQueryResponse"
        ) {
          setTagsError(data.backgroundPreviewQueryResult.error);
        } else {
          const parsedTags = parseTagsFromSource(data);
          setTags(parsedTags);
        }
        setShouldPoll(false);
        setChannel("");
      },
    },
  );

  const form = useHightouchForm({
    onSubmit: async (data) => {
      setChannel(data.channel);
    },
  });

  return (
    <>
      <Form form={form}>
        <Card gap={4}>
          <Column>
            <Heading>Get tags for channel</Heading>
          </Column>
          <Controller
            name="channel"
            render={({ field }) => (
              <FormField label="Channels">
                <Select
                  {...field}
                  options={engine.channels}
                  optionValue={(value) => value.id}
                  optionLabel={(value) => value.type}
                />
              </FormField>
            )}
          />

          <Row>
            <Button variant="primary" onClick={form.submit} size="lg">
              Get tags
            </Button>
          </Row>

          {tags && JSON.stringify(tags, null, 2)}
        </Card>
      </Form>
    </>
  );
};

const MessageTags = () => {
  const { messages } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const [tagsError, setTagsError] = useState("");
  const [shouldPoll, setShouldPoll] = useState(false);
  const [message, setMessage] = useState("");
  const [messageTags, setMessageTags] = useState<any>();
  const [tags, setTags] = useState("");

  const form = useHightouchForm({
    onSubmit: async (data) => {
      setMessage(data.message);
      setTags(JSON.parse(data.tags));
    },
  });

  const { data: jobId } = useAnalyzeDecisionEngineTagsForMessageQuery(
    {
      messageId: message,
      tags,
    },
    {
      select: (data) => data.analyzeDecisionEngineTagsForMessage,
      enabled: message?.length > 0,
    },
  );

  useEffect(() => {
    setShouldPoll(true);
  }, [jobId]);

  useRunSqlResultQuery(
    {
      jobId: String(jobId),
      page: 0,
    },
    {
      enabled: Boolean(jobId),
      refetchInterval: shouldPoll ? 1000 : 0,
      onError: () => {
        setShouldPoll(false);
        toast({
          id: "tags",
          title: "Error",
          message: tagsError,
          variant: "error",
        });
      },
      onSuccess: (data) => {
        if (!data.backgroundPreviewQueryResult) {
          return;
        }
        if (
          data.backgroundPreviewQueryResult.__typename === "FailedQueryResponse"
        ) {
          setTagsError(data.backgroundPreviewQueryResult.error);
        } else {
          const parsedTags = parseTagsFromSource(data);
          setMessageTags(parsedTags);
        }
        setShouldPoll(false);
        setMessage("");
      },
    },
  );

  return (
    <Form form={form}>
      <Card gap={4}>
        <Column>
          <Heading>Tags for Message</Heading>
        </Column>

        <Controller
          name="message"
          render={({ field }) => (
            <FormField label="Message">
              <Select
                {...field}
                options={messages}
                optionValue={(value) => value.id}
                optionLabel={(value) => value.name}
              />
            </FormField>
          )}
        />

        <Controller
          name="tags"
          render={({ field }) => (
            <FormField label="Tags">
              <Textarea {...field} />
            </FormField>
          )}
        />

        <Row>
          <Button variant="primary" onClick={form.submit} size="lg">
            Get tags
          </Button>
        </Row>

        {messageTags && JSON.stringify(messageTags, null, 2)}
      </Card>
    </Form>
  );
};

export function parseTagsFromSource(data: RunSqlResultQuery) {
  if (
    !data ||
    data.backgroundPreviewQueryResult?.__typename === "FailedQueryResponse" ||
    !data.backgroundPreviewQueryResult
  ) {
    return null;
  }

  const rawData = Object.values(
    data.backgroundPreviewQueryResult.rows[0],
  ) as any;
  if (typeof rawData[0] === "string") {
    const dataAsObject = JSON.parse(rawData[0]);
    const choices = dataAsObject.choices[0];
    // Remove any ` backticks from the string
    const cleanedChoices = choices.replace(/`/g, "").replace("json", "");
    const parsedTags = JSON.parse(cleanedChoices);

    return parsedTags;
  }

  return JSON.parse(rawData[0]["choices"][0]["messages"]);
}

const Demo = () => {
  return (
    <Column gap={4}>
      <DemoSetup />
      <AddDemoCollection />
      <AddDemoMessage />
    </Column>
  );
};

const DemoSetup = () => {
  const { engine } = useOutletContext<OutletContext>();
  const demo = engine.config.demo as DemoDefinition | undefined;
  const mutation = useUpdateDecisionEngineMutation();
  const form = useHightouchForm({
    onSubmit: async (data) => {
      const { enabled, ...rest } = data;
      await mutation.mutateAsync({
        id: engine.id,
        input: {},
        append: {
          config: {
            demo: enabled
              ? {
                  ...demo,
                  ...rest,
                }
              : null,
          },
        },
      });
    },
    values: {
      enabled: Boolean(demo),
      logo_url: demo?.logo_url ?? "",
      from_name: demo?.from_name ?? "",
    },
  });

  return (
    <Form form={form}>
      <Card gap={4} footer={<FormActions />}>
        <Row gap={4}>
          <Heading>Demo setup</Heading>
          <Controller
            render={({ field }) => (
              <Switch isChecked={field.value} onChange={field.onChange} />
            )}
            name="enabled"
          />
        </Row>

        <>
          <Controller
            name="logo_url"
            render={({ field }) => (
              <FormField
                label="Logo URL"
                description="Should be approximately 3:1 ratio"
              >
                <TextInput {...field} />
              </FormField>
            )}
          />
          <Controller
            name="from_name"
            render={({ field }) => (
              <FormField
                label="Company name"
                description="Appears in some footers and headers"
              >
                <TextInput {...field} />
              </FormField>
            )}
          />
        </>
      </Card>
    </Form>
  );
};

const AddDemoCollection = () => {
  const { engine } = useOutletContext<OutletContext>();
  const demo = engine.config.demo as DemoDefinition | undefined;
  const collectionMutation = useCreateCollectionMutation();
  const updateMutation = useUpdateDecisionEngineMutation();
  const form = useHightouchForm({
    onSubmit: async (data) => {
      const { name, ...rest } = JSON.parse(
        data.config,
      ) as DemoCollectionInputDefinition;
      const collectionResult = await collectionMutation.mutateAsync({
        input: {
          decision_engine_id: engine.id,
          config: {
            fallback: "none",
          },
          collection: {
            data: {
              catalog_id: data.catalog_id,
              name,
              filter: { type: "and", conditions: [] },
            },
          },
        },
      });

      const createdCollectionId =
        collectionResult.insert_decision_engine_collections_one?.id ?? "";

      await updateMutation.mutateAsync({
        id: engine.id,
        input: {},
        append: {
          config: {
            demo: {
              ...demo,
              collections: {
                ...demo?.collections,
                [createdCollectionId]: {
                  items: rest.items.map((i) => {
                    const urlObj = new URL(i.image);
                    urlObj.searchParams.set("w", "800");
                    urlObj.searchParams.set("h", "800");
                    const newUrl = urlObj.toString();
                    return {
                      name: i.name,
                      image: newUrl,
                    };
                  }),
                },
              },
            },
          },
        },
      });

      form.reset();
    },
    values: {
      catalog_id: "",
      config: JSON.stringify(
        { name: "...", items: [{ name: "...", image: "..." }] },
        null,
        2,
      ),
    },
  });

  return (
    <Form form={form}>
      <Card gap={4} footer={<FormActions />}>
        <Column>
          <Heading>Add Demo Collection</Heading>
        </Column>
        <Controller
          name="catalog_id"
          render={({ field }) => (
            <FormField label="Catalog ID">
              <TextInput {...field} />
            </FormField>
          )}
        />

        <Controller
          name="config"
          render={({ field }) => (
            <FormField label="Configuration">
              <Editor language="json" {...field} />
            </FormField>
          )}
        />
      </Card>
    </Form>
  );
};

const AddDemoMessage = () => {
  const { engine } = useOutletContext<OutletContext>();
  const demo = engine.config.demo as DemoDefinition | undefined;
  const messageMutation = useCreateDecisionEngineMessageMutation();
  const updateMutation = useUpdateDecisionEngineMutation();
  const form = useHightouchForm({
    onSubmit: async (data) => {
      const { name, ...rest } = JSON.parse(
        data.config,
      ) as DemoMessageInputDefinition;

      let collectionId = "";
      if (rest.variant === 1) {
        collectionId = rest.collection_id;
      }

      const selectedChannel = engine.channels.find(
        (c) => c.id === data.channel_id,
      );

      if (!selectedChannel) return;

      const messageResult = await messageMutation.mutateAsync({
        flowId: data.flow_id,
        message: {
          decision_engine_channel_id: data.channel_id,
          name,
          config: {
            baseMessageId:
              TEMPLATE_IDS[selectedChannel?.destination.type][rest.variant],
          },
          variables: Object.entries(rest.configurable_variables).map(
            ([k, v]) => ({ name: k, values: v }),
          ),
          collections: {
            data:
              rest.variant === 1
                ? [
                    {
                      decision_engine_collection_id: collectionId,
                      item_count: 4,
                    },
                  ]
                : [],
          },
        },
        flowMessageConfig: {},
      });

      if (
        messageResult.createDecisionEngineMessage.__typename ===
        "DecisionEngineError"
      ) {
        throw new Error(
          JSON.stringify(messageResult.createDecisionEngineMessage.error),
        );
      }

      const createdMessageId =
        (messageResult.createDecisionEngineMessage as any)?.id ?? "";

      await updateMutation.mutateAsync({
        id: engine.id,
        input: {},
        append: {
          config: {
            demo: {
              ...demo,
              messages: {
                ...demo?.messages,
                [createdMessageId]: {
                  variant: rest.variant,
                  base_variables: rest.base_variables,
                },
              },
            },
          },
        },
      });

      form.reset();
    },
    values: {
      flow_id: "",
      channel_id: "",
      config: JSON.stringify(
        {
          variant: 1,
          base_variables: {},
          configurable_variables: {},
          collection_id: "...",
        },
        null,
        2,
      ),
    },
  });

  return (
    <Form form={form}>
      <Card gap={4} footer={<FormActions />}>
        <Column>
          <Heading>Add Demo Message</Heading>
        </Column>
        <Controller
          name="flow_id"
          render={({ field }) => (
            <FormField label="Flow">
              <Select
                {...field}
                options={engine.flows.map((f) => ({
                  label: f.name,
                  value: f.id,
                }))}
              />
            </FormField>
          )}
        />
        <Controller
          name="channel_id"
          render={({ field }) => (
            <FormField label="Channel">
              <Select
                {...field}
                options={engine.channels.map((c) => ({
                  label: c.destination.name,
                  description: c.type,
                  value: c.id,
                }))}
              />
            </FormField>
          )}
        />
        <Controller
          name="config"
          render={({ field }) => (
            <FormField label="Configuration">
              <Editor language="json" {...field} />
            </FormField>
          )}
        />
      </Card>
    </Form>
  );
};
