import { groupBy, isNumber, isObject, isString } from "lodash";
import formatXml from "xml-formatter";
import {
  SyncFailedWithRejectedRowsError,
  SyncRequestErrorInfo,
} from "src/types/sync-errors";
import {
  SyncAttemptFragment,
  RequestInfo as ApiRequestInfo,
  SyncRequestFragment,
} from "src/graphql/types";
import { Schedule, ScheduleType } from "src/components/schedule/types";
import pluralize from "pluralize";
import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";
import { enumOrFallback, isEnum } from "src/types/utils";
import { StatusIndicatorProps } from "@hightouchio/ui";
import JSONBig from "json-bigint";
import { Static, Type } from "@sinclair/typebox";
import { isPresent } from "ts-extras";

export enum SyncRunStatus {
  // These are definitely in use
  SUCCESS = "success",
  CANCELLED = "cancelled",
  FAILED = "failed",
  WARNING = "warning",
  REPORTING = "reporting",
  PROCESSING = "processing",
  QUERYING = "querying",
  ENQUEUED = "queued",

  DISABLED = "disabled", // Can a sync _run_ be disabled?
  PENDING = "pending", // Can a sync _run_ be pending?

  // Are these still used?
  ABORTED = "aborted",
  PREPARING = "preparing-plan",
  IN_PROGRESS = "inprogress",
  INCOMPLETE = "incomplete",
  ACTIVE = "active",
  INTERRUPTED = "interrupted",

  /**
   * @deprecated Use SyncStatus.FAILED with a syncRequestErrorCode of PREVIOUS_SYNC_RUN_OBJECT_MISSING or WAREHOUSE_TABLE_MISSING.
   */
  UNPROCESSABLE = "unprocessable",
}

export const isSyncRunStatus = isEnum(SyncRunStatus);

export const UnknownSyncRunStatus = "unknown-status" as const;
export type UnknownSyncRunStatus = typeof UnknownSyncRunStatus;

export const syncRunStatusOrUnknownStatus = enumOrFallback(
  SyncRunStatus,
  UnknownSyncRunStatus,
  true,
);

export type SyncRunStatusOrUnknownStatus = ReturnType<
  typeof syncRunStatusOrUnknownStatus
>;

/**
 * Returns true if the specified SyncStatus is terminal, i.e. it will never
 * transition to another status.
 */
export const syncStatusIsTerminal = (status: SyncRunStatus): boolean => {
  switch (status) {
    case SyncRunStatus.DISABLED:
    case SyncRunStatus.SUCCESS:
    case SyncRunStatus.ABORTED:
    case SyncRunStatus.CANCELLED:
    case SyncRunStatus.FAILED:
      return true;
    default:
      return false;
  }
};

export const getSyncStatusColor = (
  status:
    | SyncRunStatus
    | SyncHealthStatus
    | UnknownSyncHealth
    | UnknownSyncRunStatus,
): string => {
  switch (status) {
    case SyncRunStatus.SUCCESS:
    case SyncHealthStatus.Healthy:
      return "success.base";
    case SyncRunStatus.WARNING:
    case SyncHealthStatus.Warning:
      return "warning.border";
    case SyncRunStatus.FAILED:
    case SyncHealthStatus.Unhealthy:
      return "danger.base";
    case SyncRunStatus.DISABLED:
    case SyncRunStatus.CANCELLED:
    case SyncRunStatus.PENDING:
    case SyncRunStatus.ENQUEUED:
    case SyncHealthStatus.Disabled:
    case SyncHealthStatus.Pending:
    case UnknownSyncHealth:
    case UnknownSyncRunStatus:
      return "base.border";
    case SyncRunStatus.REPORTING:
    case SyncRunStatus.PROCESSING:
    case SyncRunStatus.QUERYING:
    case SyncRunStatus.ABORTED:
    case SyncRunStatus.PREPARING:
    case SyncRunStatus.IN_PROGRESS:
    case SyncRunStatus.INCOMPLETE:
    case SyncRunStatus.ACTIVE:
    case SyncRunStatus.INTERRUPTED:
    case SyncRunStatus.UNPROCESSABLE:
      return "electric.base";
  }
};

export const syncRunStatusToIndicatorVariant = (
  status: SyncRunStatus,
): StatusIndicatorProps["variant"] => {
  switch (status) {
    case SyncRunStatus.SUCCESS:
      return "success" as const;
    case SyncRunStatus.WARNING:
      return "warning" as const;
    case SyncRunStatus.FAILED:
    case SyncRunStatus.ABORTED:
    case SyncRunStatus.UNPROCESSABLE:
      return "error" as const;
    case SyncRunStatus.CANCELLED:
    case SyncRunStatus.PENDING:
    case SyncRunStatus.INTERRUPTED:
    case SyncRunStatus.DISABLED:
    case SyncRunStatus.INCOMPLETE:
      return "inactive" as const;
    case SyncRunStatus.ENQUEUED:
    case SyncRunStatus.QUERYING:
    case SyncRunStatus.PROCESSING:
    case SyncRunStatus.REPORTING:
    case SyncRunStatus.ACTIVE:
    case SyncRunStatus.IN_PROGRESS:
    case SyncRunStatus.PREPARING:
      return "processing" as const;
  }
};

export const syncRunStatusToDescription: {
  [k in SyncRunStatus]: string;
} = {
  [SyncRunStatus.DISABLED]: "Sync is disabled",
  [SyncRunStatus.PENDING]: "Sync is pending",
  [SyncRunStatus.PREPARING]: "Sync is preparing",
  [SyncRunStatus.QUERYING]: "Sync is querying",
  [SyncRunStatus.REPORTING]: "Sync is reporting",
  [SyncRunStatus.PROCESSING]: "Sync is processing",
  [SyncRunStatus.ENQUEUED]: "Sync is queued",
  [SyncRunStatus.ACTIVE]: "Sync is active",
  [SyncRunStatus.SUCCESS]: "Last run completed successfully",
  [SyncRunStatus.CANCELLED]: "Last run canceled",
  [SyncRunStatus.FAILED]: "Last run failed",
  [SyncRunStatus.WARNING]: "Last run had errors",
  [SyncRunStatus.INTERRUPTED]: "Last run interrupted",
  [SyncRunStatus.ABORTED]: "Last run was aborted",
  [SyncRunStatus.INCOMPLETE]: "Last run incomplete",
  [SyncRunStatus.IN_PROGRESS]: "Sync is in progress",
  [SyncRunStatus.UNPROCESSABLE]: "Last run was unprocessable",
};

export const SyncStatusToText = {
  [SyncRunStatus.DISABLED]: "Disabled",
  [SyncRunStatus.PENDING]: "Pending",
  [SyncRunStatus.SUCCESS]: "Healthy",
  [SyncRunStatus.QUERYING]: "Querying",
  [SyncRunStatus.REPORTING]: "Reporting",
  [SyncRunStatus.PROCESSING]: "Processing",
  [SyncRunStatus.CANCELLED]: "Canceled",
  [SyncRunStatus.FAILED]: "Failed",
  [SyncRunStatus.WARNING]: "Warning",
  [SyncRunStatus.INTERRUPTED]: "Interrupted",
  [SyncRunStatus.ENQUEUED]: "Queued",
  [SyncRunStatus.ACTIVE]: "Active",
};

export const ActiveSyncStatuses = [
  SyncRunStatus.QUERYING,
  SyncRunStatus.PROCESSING,
  SyncRunStatus.REPORTING,
  SyncRunStatus.ENQUEUED,
] as const;

export type ActiveSyncStatus = (typeof ActiveSyncStatuses)[number];

export function isActiveSyncStatus(
  status: SyncRunStatus,
): status is ActiveSyncStatus {
  return (ActiveSyncStatuses as readonly SyncRunStatus[]).includes(status);
}

/**
 * This structure sidesteps the issues that come out of using enums to populate another enum,
 * so that we can talk about SyncHealthStatus on its own terms rather than as a combination of monitor/pending state.
 */
export const SyncHealthStatus = {
  Healthy: MonitorStatus.Healthy,
  Unhealthy: MonitorStatus.Unhealthy,
  Warning: MonitorStatus.Warning,
  Disabled: MonitorStatus.Disabled,
  Pending: "pending",
} as const;

/**
 * Combine the states a monitor can be in with the states we show for overall sync health.
 */
export type SyncHealthStatus =
  (typeof SyncHealthStatus)[keyof typeof SyncHealthStatus];

export const isSyncHealthStatus = (
  status: string,
): status is SyncHealthStatus =>
  Object.values(SyncHealthStatus).includes(status as any);

export const UnknownSyncHealth = "unknown-health" as const;
export type UnknownSyncHealth = typeof UnknownSyncHealth;

export const syncHealthOrUnknown = enumOrFallback(
  SyncHealthStatus,
  UnknownSyncHealth,
  true,
);

export type SyncHealthOrUnknown = ReturnType<typeof syncHealthOrUnknown>;

export const getSyncRunOperations = ({
  attempt,
  syncRequest,
  queryRun,
}: {
  attempt?: Pick<
    SyncAttemptFragment,
    "add_rejected" | "change_rejected" | "remove_rejected" | "error"
  > | null;
  syncRequest?: Pick<
    SyncRequestFragment,
    | "status_computed"
    // Note: this must use *_executed fields, and not *_checkpoint fields
    // *_executed fields are defined by a pg function that handles special casing `sync_request.sync_behavior`
    | "add_executed"
    | "change_executed"
    | "remove_executed"
    | "error"
    | "planner_type"
  > | null;
  queryRun?: Pick<NonNullable<SyncRequestFragment["query_run"]>, "size"> | null;
}): {
  successful: {
    added: number;
    changed: number;
    removed: number;
    total: number;
  };
  rejected: {
    added: number;
    changed: number;
    removed: number;
    total: number;
  };
} => {
  if (syncRequest?.planner_type === "all") {
    const isTerminal =
      isSyncRunStatus(syncRequest?.status_computed) &&
      syncStatusIsTerminal(syncRequest.status_computed);

    // No operations if a planner type all sync is still running
    if (!isTerminal) {
      return {
        successful: {
          added: 0,
          changed: 0,
          removed: 0,
          total: 0,
        },
        rejected: {
          added: 0,
          changed: 0,
          removed: 0,
          total: 0,
        },
      };
    }

    const hasError = Boolean(attempt?.error || syncRequest?.error);
    const querySize = queryRun?.size ?? 0;

    // Note: planner_type "all" sync run operations are _only_ counted as add operations,
    // and it's all-or-nothing. If there are any errors, all rows are rejected.
    // In some specific cases the backend may report some operations as other operation types,
    // so we need to special case here and account for whether the sync was successful.
    return hasError
      ? {
          successful: {
            added: 0,
            changed: 0,
            removed: 0,
            total: 0,
          },
          rejected: {
            added: querySize,
            changed: 0,
            removed: 0,
            total: querySize,
          },
        }
      : {
          successful: {
            added: querySize,
            changed: 0,
            removed: 0,
            total: querySize,
          },
          rejected: {
            added: 0,
            changed: 0,
            removed: 0,
            total: 0,
          },
        };
  }

  const addRejected = attempt?.add_rejected ?? 0;
  const changeRejected = attempt?.change_rejected ?? 0;
  const removeRejected = attempt?.remove_rejected ?? 0;

  const added = (syncRequest?.add_executed ?? 0) - addRejected;
  const changed = (syncRequest?.change_executed ?? 0) - changeRejected;
  const removed = (syncRequest?.remove_executed ?? 0) - removeRejected;

  return {
    successful: {
      added,
      changed,
      removed,
      total: added + changed + removed,
    },
    rejected: {
      added: addRejected,
      changed: changeRejected,
      removed: removeRejected,
      total: addRejected + changeRejected + removeRejected,
    },
  };
};

const DEPRECATED_ERROR = SyncFailedWithRejectedRowsError.MESSAGE;

export const isDeprecatedSyncRunError = (error: string): boolean =>
  [DEPRECATED_ERROR, "Error: " + DEPRECATED_ERROR].includes(error);

export const getSyncRunError = ({
  attempt,
  syncRequest,
}: {
  attempt?: Pick<SyncAttemptFragment, "error">;
  syncRequest?: Pick<SyncRequestFragment, "error">;
}): SyncRequestErrorInfo | undefined => {
  // Use `sync_requests.error` if present, then fall back to `sync_attempts.error`.
  const error: SyncRequestErrorInfo | undefined =
    syncRequest?.error ??
    (attempt?.error ? { message: attempt.error } : undefined);

  return error?.message && isDeprecatedSyncRunError(error.message)
    ? undefined
    : error;
};

/**
 * getObjectName returns a human friendly name for a synced object.
 **/
export function getObjectName(
  objectVal: string | undefined,
): string | undefined {
  if (!objectVal) {
    return objectVal;
  }

  const salesforceMultiOptions = [
    {
      label: "Contact or Lead",
      value: "___hightouch-reserved-contact-or-lead",
    },
    {
      label: "Account or Lead",
      value: "___hightouch-reserved-account-or-lead",
    },
  ];

  // We use a special identifier for Salesforce multitypes (e.g. Contact or Lead).
  const sfMultiType = salesforceMultiOptions.find(
    (mt) => mt.value === objectVal,
  );
  if (sfMultiType != null) {
    return sfMultiType.label;
  }

  return objectVal;
}

export const RequestInfoSchema = Type.Object({
  requestType: Type.String(),
  status: Type.String(),
  method: Type.String(),
  meta: Type.Any(),
  destination: Type.String(),
  requestBody: Type.String(),
  requestIsJson: Type.Boolean(),
  requestIsXml: Type.Boolean(),
  requestHeaders: Type.Record(Type.String(), Type.Any()),
  responseBody: Type.String(),
  responseIsJson: Type.Boolean(),
  responseIsXml: Type.Boolean(),
  errored: Type.Boolean(),
});

export type RequestInfo = Static<typeof RequestInfoSchema>;

const BATCH_REQUEST_INFO_DEFAULT_STATUS = "Success";
const BATCH_REQUEST_INFO_DEFAULT_DESTINATION = "Unknown destination";
const BATCH_REQUEST_INFO_DEFAULT_METHOD = "Save";

export const isXml = (value: unknown): boolean => {
  if (typeof value !== "string") {
    return false;
  }
  try {
    formatXml(value);
    return true;
  } catch (_err) {
    return false;
  }
};

export const processRequestInfo = (
  requestInfo: ApiRequestInfo,
  definition?: { name: string },
): RequestInfo => {
  let requestBody: RequestInfo["requestBody"] = "";
  let requestIsJson = false;
  let requestIsXml = false;
  let meta: RequestInfo["meta"];
  let responseBody: RequestInfo["responseBody"] = "";
  let responseIsJson = false;
  let responseIsXml = false;
  let errored = false;
  let status = BATCH_REQUEST_INFO_DEFAULT_STATUS;
  let method = BATCH_REQUEST_INFO_DEFAULT_METHOD;
  let destination = BATCH_REQUEST_INFO_DEFAULT_DESTINATION;
  let requestHeaders: RequestInfo["requestHeaders"] = {};

  const data = requestInfo.data;

  try {
    let request =
      requestInfo.requestType === "method-call"
        ? data.parameters
        : data.request?.body;
    if (typeof request === "string") {
      try {
        request = JSONBig.parse(request);
      } catch {
        // Response is not json, ignore error and continue
      }
    }
    requestIsJson = isObject(request);
    requestIsXml = isXml(request);
    requestBody = requestIsJson
      ? JSONBig.stringify(request, null, 2)
      : requestIsXml
        ? formatXml(request)
        : request;

    let response =
      requestInfo.requestType === "method-call"
        ? data.result
        : data.response?.body;
    if (typeof response === "string") {
      try {
        response = JSONBig.parse(response);
      } catch {
        // Response is not json, ignore error and continue
      }
    }
    responseIsJson = isObject(response);
    responseIsXml = isXml(response);
    responseBody = responseIsJson
      ? JSONBig.stringify(response, null, 2)
      : responseIsXml
        ? formatXml(response)
        : !isPresent(response) && data.error
          ? JSONBig.stringify(data.error, null, 2)
          : String(response ?? "");

    if (data.method) {
      method = data.method;
    }
    if (data.error) {
      errored = true;
    }

    const error = data.response?.error;
    if (
      requestInfo.requestType !== "method-call" &&
      typeof error === "string"
    ) {
      if (error?.includes("TIMEDOUT")) {
        status = "TIMEOUT ERROR";
      } else {
        errored = true;
      }
    }
    if (data.response?.status && isString(data.response?.status)) {
      status = data.response?.status;
    }
    if (data.request?.method) {
      method = data.request?.method;
    }
    if (data.request?.url) {
      destination = data.request?.url;
    }
    if (data.request?.headers) {
      requestHeaders = data.request?.headers;
    }

    if (data.meta) {
      meta = data.meta;
    }
  } catch (_err) {
    // Fall back to default empty values
  }

  if (isNumber(data.response?.status)) {
    errored = data.response.status >= 400;
    status = errored
      ? `${data.response.status} ERR`
      : `${data.response.status} OK`;
  }

  if (
    destination === BATCH_REQUEST_INFO_DEFAULT_DESTINATION &&
    definition?.name
  ) {
    destination = definition.name;
  }

  if (status === BATCH_REQUEST_INFO_DEFAULT_STATUS && errored) {
    status = "Error";
  }

  return {
    ...requestInfo,
    requestBody,
    requestIsJson,
    requestIsXml,
    requestHeaders,
    responseBody,
    responseIsJson,
    responseIsXml,
    meta,
    errored,
    status,
    method,
    destination,
  };
};

export type MinimalSync = {
  id: number;
  description: string | null;
  destination: {
    definition: {
      icon: string | undefined;
      name: string | undefined;
    };
    name: string | null | undefined;
  } | null;
  status: string | null;
  sync_template?: { name: string } | null;
  sync_template_id?: number | null;
};

export type DisambiguatedSync = MinimalSync & { name: string };

const getSyncName = (sync: MinimalSync): string => {
  return (
    sync?.destination?.name ??
    sync?.destination?.definition?.name ??
    String(sync.id)
  );
};

/**
 * Syncs don't have names. We can instead identify them by their destination's name.
 * However, if there are multiple syncs with the same destination, we may need to disambiguate them.
 * In such a case, we append the sync ID to the destination name.
 */
export const disambiguateSyncs = (
  syncs: MinimalSync[],
): DisambiguatedSync[] => {
  const syncsGroupedByDestinationName = groupBy(syncs, (sync) =>
    getSyncName(sync),
  );

  return syncs.map((sync) => {
    const syncName = getSyncName(sync);
    const destinationHasMultipleSyncs =
      (syncsGroupedByDestinationName[syncName] || []).length > 1;

    return {
      ...sync,
      name: destinationHasMultipleSyncs
        ? `${syncName} (Sync #${sync.id})`
        : syncName,
    };
  });
};

export const describeWhenSyncRuns = (schedule: Schedule | null): string => {
  switch (schedule?.type) {
    case undefined:
    case ScheduleType.MANUAL:
      return "Not scheduled";
    case ScheduleType.STREAMING:
      return "Runs continuously";
    case ScheduleType.CUSTOM:
      return "Runs on custom schedule";
    case ScheduleType.CRON:
      return `Runs on cron schedule (${schedule.schedule?.expression})`;
    case ScheduleType.FIVETRAN:
      return "Runs after Fivetran sync";
    case ScheduleType.DBT_CLOUD:
      return "Runs after dbt job";
    case ScheduleType.MATCH_BOOSTER:
      return "Runs after Match Booster";
    case ScheduleType.JOURNEY_TRIGGERED:
      return "Runs when Journey is triggered";
    case ScheduleType.INTERVAL:
      return schedule.schedule?.interval?.unit != null &&
        schedule.schedule?.interval.quantity != null
        ? `Runs every ${
            schedule.schedule?.interval?.quantity === 1
              ? schedule.schedule?.interval.unit
              : pluralize(
                  schedule.schedule?.interval?.unit,
                  schedule.schedule?.interval?.quantity,
                  true,
                )
          }`
        : "Not scheduled";
    default:
      return "Runs on schedule";
  }
};
