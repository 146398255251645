import { useEffect } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Column,
  Dialog,
  Spinner,
  Text,
} from "@hightouchio/ui";
import {
  ChannelConfig,
  ChannelType,
} from "@hightouch/lib/resource-monitoring/types";

import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { ChannelDefinition } from "src/components/notification-channels/channel-definitions";
import { ChannelTypeSelect } from "src/pages/alerting/recipients/components/channel-type-select";
import { TestConnection } from "src/pages/alerting/recipients/components/test-connection";
import { useAlertingCredentialsQuery } from "src/graphql";
import { capitalize } from "lodash";
import { PermissionedLinkButton } from "src/components/permission";

type Props = {
  isOpen: boolean;
  initialChannelType?: ChannelType;
  onClose: () => void;
  onCreate: (data: ChannelData) => Promise<unknown>;
};

export type ChannelData = {
  channelType: string;
  config: ChannelConfig[keyof ChannelConfig] | null;
};

export const CreateRecipientModal = ({
  isOpen,
  initialChannelType,
  onClose: onCloseProp,
  onCreate,
}: Props) => {
  const { data: credentialsData, isLoading: credentialsLoading } =
    useAlertingCredentialsQuery();

  const form = useHightouchForm<ChannelData>({
    success: "Recipient created",
    onSubmit: async (data) => {
      await onCreate(data);
      onClose();
    },
    values: {
      channelType: initialChannelType ?? ChannelType.Email,
      config: null,
    },
  });

  const onClose = () => {
    form.reset();
    onCloseProp();
  };

  const channelType = form.watch("channelType");

  useEffect(() => {
    form.resetField("config");
  }, [channelType]);

  const definition = ChannelDefinition[channelType!];

  const showSpinner =
    credentialsLoading &&
    (channelType === ChannelType.Slack ||
      channelType === ChannelType.PagerDuty);

  const slackCredentials = credentialsData?.slack_credentials?.[0];
  const pagerDutyCredentials = credentialsData?.pagerduty_credentials?.[0];

  const showCredentialNotice =
    (channelType === ChannelType.Slack && !slackCredentials) ||
    (channelType === ChannelType.PagerDuty && !pagerDutyCredentials);

  return (
    <Form form={form}>
      <Dialog
        title="Create recipient"
        variant="info"
        width="lg"
        isOpen={isOpen}
        onClose={onClose}
        actions={
          <ButtonGroup>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <SaveButton
              variant="primary"
              size="md"
              isDisabled={showCredentialNotice}
            >
              Create recipient
            </SaveButton>
          </ButtonGroup>
        }
      >
        <Column gap={6}>
          <ChannelTypeSelect />
          {showSpinner ? (
            <Spinner mx="auto" />
          ) : showCredentialNotice ? (
            <Alert
              type="subtle"
              title="Setup required"
              message={
                <Text>
                  {capitalize(channelType)} requires initial setup before a
                  recipient can be created. Workspace admins can perform this in
                  the global alerting settings page.
                </Text>
              }
              actions={
                <PermissionedLinkButton
                  href="/alerting/recipients"
                  onClick={onClose}
                  variant="primary"
                  permission={{
                    v2: { resource: "workspace", grant: "can_update" },
                  }}
                >
                  Configure {capitalize(channelType)}
                </PermissionedLinkButton>
              }
            />
          ) : (
            <>
              <definition.component />
              <TestConnection />
            </>
          )}
        </Column>
      </Dialog>
    </Form>
  );
};
