import { ReactElement } from "react";
import { MergedSyncRequestEventStatus } from "src/graphql";

import {
  Badge,
  Box,
  CheckIcon,
  Column,
  InformationIcon,
  Row,
  Spinner,
  Text,
  TimeIcon,
} from "@hightouchio/ui";

import { PhaseError } from "./phase-error";
import { SyncRequest, type SyncRunPhase } from "./types";
import { PhaseDuration } from "./phase-duration";
import { PHASE_DISPLAY_CONFIG } from "./phase-display-config";
import { isNil } from "lodash";

type SyncRunPhaseProps = {
  phase: SyncRunPhase;
  syncRequest: SyncRequest;
};

export const PhaseDisplay = ({ phase, syncRequest }: SyncRunPhaseProps) => {
  const config = PHASE_DISPLAY_CONFIG[phase.span];

  // If no config for this phase span type is found, don't render anything
  if (!config) return null;

  const {
    phaseDisplayName,
    PhaseHeaderIcon,
    PhaseHeaderData,
    PhaseHeaderAction,
    PhaseAdditionalData,
  } = config;

  const phaseErrors = phase.errors?.filter((e) => !isNil(e)) ?? [];

  return (
    <Column gap={2} p={4}>
      <Row justifyContent="space-between" alignItems="center">
        <Row gap={4} fontSize="xl" alignItems="center">
          {PHASE_STATUS_ICON[phase.status]}
          <Column>
            <Row alignItems="center" gap={1}>
              <Text>{phaseDisplayName}</Text>
              {PhaseHeaderIcon && (
                <PhaseHeaderIcon phase={phase} syncRequest={syncRequest} />
              )}
            </Row>
            {PhaseHeaderData && (
              <PhaseHeaderData phase={phase} syncRequest={syncRequest} />
            )}
          </Column>
        </Row>
        <Row alignItems="center" gap={4}>
          {PhaseHeaderAction && (
            <PhaseHeaderAction phase={phase} syncRequest={syncRequest} />
          )}
          <PhaseStatusBadge phase={phase} />
          {phase.durationMillis.self > 0 && (
            <PhaseDuration
              durationMillis={phase.durationMillis.self}
              isActive={phase.status === MergedSyncRequestEventStatus.Active}
            />
          )}
        </Row>
      </Row>
      {PhaseAdditionalData && (
        <Box ml={8}>
          <PhaseAdditionalData phase={phase} syncRequest={syncRequest} />
        </Box>
      )}
      {phase.status === MergedSyncRequestEventStatus.Failed &&
        phaseErrors.length > 0 && (
          <Box ml={8} mt={2}>
            <PhaseError
              span={phase.span}
              errors={phaseErrors}
              syncRequest={syncRequest}
            />
          </Box>
        )}
    </Column>
  );
};

const PHASE_STATUS_ICON: Record<MergedSyncRequestEventStatus, ReactElement> = {
  [MergedSyncRequestEventStatus.NotStarted]: (
    <TimeIcon color="text.placeholder" />
  ),
  [MergedSyncRequestEventStatus.Active]: <Spinner size="sm" speed="slow" />,
  [MergedSyncRequestEventStatus.Failed]: (
    <InformationIcon color="danger.base" />
  ),
  [MergedSyncRequestEventStatus.Completed]: <CheckIcon color="success.base" />,
};

const PhaseStatusBadge = ({ phase }: { phase: SyncRunPhase }) => {
  const hasErrors = phase.errors && phase.errors.length > 0;

  if (!hasErrors) return null;

  switch (phase.status) {
    case MergedSyncRequestEventStatus.NotStarted:
    case MergedSyncRequestEventStatus.Active:
      return (
        <Badge variant="primary">Error encountered - retry in progress</Badge>
      );
    case MergedSyncRequestEventStatus.Completed:
      return <Badge variant="subtle">Recovered from error</Badge>;
    case MergedSyncRequestEventStatus.Failed:
      return <Badge variant="danger">Failed</Badge>;
  }
};
