import { FC, useState } from "react";

import {
  Column,
  Row,
  MenuActionsButton,
  MenuList,
  Menu,
  DeleteIcon,
} from "@hightouchio/ui";
import { Outlet, useNavigate } from "src/router";

import { DetailPage } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { eventSourceDefinitions } from "src/events/types";
import { useDeleteEventForwardingSyncMutation } from "src/graphql";

import { ForwardingOutletContext } from "src/events/syncs/common";
import { useUser } from "src/contexts/user-context";
import { SyncName } from "src/events/syncs/common/sync-name";
import { PermissionedMenuItem } from "src/components/permission";
import { useTabState } from "src/components/route-tabs";

export type { ForwardingOutletContext as OutletContext };
export type EventForwardingSync = ForwardingOutletContext["sync"];

export const ForwardingSync: FC<
  Readonly<{ sync: ForwardingOutletContext["sync"] }>
> = ({ sync }) => {
  const { workspace } = useUser();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteMutation = useDeleteEventForwardingSyncMutation();

  const sourceDefinition = eventSourceDefinitions[sync.event_source!.type];
  const destinationDefinition = sync.destination!.definition;

  const tabs = [
    { path: "overview", title: "Overview" },
    { path: "configuration", title: "Configuration" },
    { path: "errors", title: "Errors" },
  ];

  const { activeTab } = useTabState(tabs, 5);

  return (
    <DetailPage
      title="Sync"
      crumbs={[{ label: "All syncs", link: "/events/syncs" }]}
      tabDepth={5}
      tabs={tabs}
      hasBottomPadding={activeTab?.path !== "configuration"}
      header={
        <Column minWidth={0} gap={2}>
          <Row flex={1} justify="space-between" minWidth={0} pt={1}>
            <SyncName
              isWarehouseSync={false}
              sourceDefinition={sourceDefinition}
              destinationDefinition={destinationDefinition}
              source={sync.event_source}
              destination={sync.destination}
            />
            <Menu>
              <MenuActionsButton variant="secondary" />
              <MenuList>
                <PermissionedMenuItem
                  permission={{
                    v1: { resource: "workspace", grant: "update" },
                    v2: {
                      resource: "workspace",
                      grant: "can_update",
                    },
                  }}
                  icon={DeleteIcon}
                  variant="danger"
                  onClick={() => {
                    setIsDeleting(true);
                  }}
                >
                  Delete
                </PermissionedMenuItem>
              </MenuList>
            </Menu>
          </Row>
        </Column>
      }
    >
      <Outlet
        context={{ sync, type: "forwarding" } as ForwardingOutletContext}
      />
      <DeleteConfirmationModal
        label="sync"
        isOpen={isDeleting}
        onClose={() => {
          setIsDeleting(false);
        }}
        onDelete={async () => {
          await deleteMutation.mutateAsync({
            id: sync.id,
            workspaceId: workspace?.id,
          });
          navigate("/events/syncs");
        }}
      />
    </DetailPage>
  );
};
