import { Column, FormField, Switch, TextInput } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Controller } from "react-hook-form";
import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { useUpdateOrganizationMutation } from "src/graphql";
import { useOutletContext } from "src/router";
import { OutletContext } from "..";

export const Organization = () => {
  const { organization } = useOutletContext<OutletContext>();
  const { mutateAsync: updateOrganization } = useUpdateOrganizationMutation();
  const { allowTogglingImpersonation } = useFlags();

  const form = useHightouchForm({
    onSubmit: async ({ name, allow_support_access }) =>
      updateOrganization({
        id: organization.id,
        input: { name, allow_support_access },
      }),
    values: {
      name: organization.name,
      allow_support_access: organization.allow_support_access,
    },
  });

  return (
    <Form form={form}>
      <Column gap={6}>
        <FormField label="Organization name">
          <Controller
            name="name"
            render={({ field }) => <TextInput {...field} />}
          />
        </FormField>
        {allowTogglingImpersonation && (
          <FormField
            label="Allow support access"
            description="Allow Hightouch support to access your organization for troubleshooting purposes."
          >
            <Controller
              name="allow_support_access"
              render={({ field }) => (
                <Switch isChecked={field.value} onChange={field.onChange} />
              )}
            />
          </FormField>
        )}
        <ActionBar center={false}>
          <FormActions />
        </ActionBar>
      </Column>
    </Form>
  );
};
