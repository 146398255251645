import { FC, useCallback, useEffect, useMemo } from "react";

import { useNavigate, useParams, useSearchParams } from "src/router";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import { useModelState } from "src/hooks/use-model-state";
import { MembersDrawer } from "src/components/audiences/member-details-drawer/member-drawer";
import { useJourneyNodeMemberHook } from "src/pages/journeys/utils/get-journey-node-members";
import { isMergedColumn } from "src/types/visual";
import { JourneyGraph } from "src/graphql";
import { useFormContext } from "react-hook-form";
import {
  isValidJourneyEventType,
  type JourneyEventType,
  type JourneyNode,
} from "src/pages/journeys/types";
import { MemberDetailHeader } from "src/pages/journeys/components/journey-node-member-header";
import { Row, useToast, Box, InformationIcon, Text } from "@hightouchio/ui";
import { mapJourneyEventForResolver } from "./utils";

export const MemberDetailForm: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { node_id } = useParams<{
    node_id?: string;
  }>();
  const parentForm = useFormContext<JourneyGraph>();
  const { id: journeyId } = parentForm.getValues("journey");
  const [searchParams] = useSearchParams();
  const searchParamEvent = searchParams.get("event");
  const {
    parentModel,
    onCloseDrawer,
    isDrawerOpen,
    nodeRunStats,
    latestJourneyRun,
  } = useGraphContext();

  const showErrorToast =
    !searchParamEvent || !isValidJourneyEventType(searchParamEvent);
  useEffect(() => {
    if (showErrorToast) {
      // If no valid event type is provided, show an error toast.
      toast({
        id: "journey-member-details-error",
        title: "Node member preview failed",
        message: "Invalid event provided",
        variant: "error",
      });
    }
  }, [showErrorToast, searchParamEvent]);

  const event = searchParamEvent as JourneyEventType;
  const currentNodeStats = useMemo(() => {
    return nodeRunStats?.find((nodeRun) => nodeRun.node_id === node_id);
  }, [node_id, nodeRunStats]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Condition types
  const nodes: JourneyNode[] = parentForm.watch("nodes");
  const currentNode = useMemo(() => {
    return nodes.find(({ id }) => id === node_id);
  }, [node_id, nodes]);

  const options = useMemo(() => {
    return {
      columns: parentModel?.columns,
      mergedColumns: (parentModel?.filterable_audience_columns ?? []).filter(
        (col) => isMergedColumn(col.column_reference),
      ),
    };
  }, [parentModel]);

  const parentModelState = useModelState(parentModel);
  const { runQuery, cancelQuery, rows, loading, error } =
    useJourneyNodeMemberHook(parentModelState.state, options);

  const closeDrawer = async () => {
    if (loading) {
      await cancelQuery();
    }
    onCloseDrawer();
    navigate(`../${node_id || ""}`);
  };

  useEffect(() => {
    if (error) {
      toast({
        id: "journey-member-details-error",
        title: "Node member preview failed",
        message: error,
        variant: "error",
      });
    }
  }, [error]);

  const handleRunQuery = useCallback(() => {
    const { direction, event: mappedEvent } = mapJourneyEventForResolver(event);
    runQuery({
      journeyId,
      nodeId: node_id,
      direction: direction,
      eventType: mappedEvent,
    });
  }, [runQuery, node_id, journeyId, event]);

  useEffect(() => {
    handleRunQuery();
  }, [node_id, journeyId, event]);

  const customHeader = (
    <MemberDetailHeader
      loading={loading}
      event={event}
      currentNode={currentNode}
      currentNodeStats={currentNodeStats}
      latestJourneyRun={latestJourneyRun}
    />
  );

  const customTableFooter =
    (rows || [])?.length >= 100 ? (
      <Row width="100%" bg="base.background" px={2} py={4}>
        <Box
          as={InformationIcon}
          fontSize="18px"
          mx={2}
          color="text.secondary"
        />
        <Text color="text.secondary" fontWeight="semibold">
          Only displaying 100 random profiles.
        </Text>
      </Row>
    ) : undefined;

  return (
    <MembersDrawer
      audienceSize={null}
      audienceRows={rows ?? []}
      includeMergedColumns
      isOpen={isDrawerOpen}
      size="xl"
      error={error}
      parentModel={parentModel}
      isLoading={loading}
      onClose={closeDrawer}
      customHeader={customHeader}
      customTableFooter={customTableFooter}
    />
  );
};
