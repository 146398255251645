import { mapValues } from "lodash";
import {
  useRenderedConditionChangesQuery,
  useSyncsMonitorStatusesQuery,
} from "src/graphql/types";

export const useAlertsforSyncs = (syncIds: string[], enabled: boolean) => {
  const syncMonitorStatusesQuery = useSyncsMonitorStatusesQuery(
    {
      syncIds,
    },
    {
      enabled,
      refetchInterval: 3000,
      notifyOnChangeProps: "tracked",
      select: (data) =>
        Object.fromEntries(
          data.resource_monitor_statuses.map((status) => [
            status.resource_id as string,
            {
              condition_changes: (status.condition_changes || []) as string[],
            },
          ]),
        ),
    },
  );

  const conditionChangeIds =
    (syncMonitorStatusesQuery.data &&
      Object.values(syncMonitorStatusesQuery.data).flatMap(
        (sync) => sync.condition_changes,
      )) ||
    [];

  const renderedConditionChangesQuery = useRenderedConditionChangesQuery(
    {
      resourceId: "", // TODO: These two fields are unused in the resolver, but it will
      resourceType: "Sync", // require a series of PRs to remove them. Removing soon.
      conditionChangeIds,
    },
    {
      select: (result) => {
        return Object.fromEntries(
          result.renderedMonitorConditionStatuses.map((status) => [
            status.id,
            status,
          ]),
        );
      },
      notifyOnChangeProps: "tracked",
      enabled: Boolean(syncMonitorStatusesQuery.data),
    },
  );
  return {
    isLoading:
      syncMonitorStatusesQuery.isLoading ||
      renderedConditionChangesQuery.isLoading,
    data:
      syncMonitorStatusesQuery.data &&
      renderedConditionChangesQuery.data &&
      mapValues(syncMonitorStatusesQuery.data, (status) =>
        status.condition_changes.flatMap((change_id) =>
          renderedConditionChangesQuery.data[change_id]
            ? [renderedConditionChangesQuery.data[change_id]]
            : [],
        ),
      ),
  };
};
