import { Column } from "@hightouchio/ui";

import { Targeting } from "./components/targeting";
import { Messages } from "./messages";
import { Outcomes } from "./outcomes";
import { Summary } from "./components/summary";
import { FC } from "react";
import { Flow } from ".";
import { Timing } from "./timing";

type Props = {
  flow: Flow;
};

export const FlowConfiguration: FC<Readonly<Props>> = ({ flow }) => {
  return (
    <>
      <Column gap={6}>
        <Summary flow={flow} />
        <Targeting flow={flow} />
        <Messages messages={flow.messages} />
        <Outcomes outcomes={flow.outcomes.flatMap((o) => o.outcome)} />
        <Timing flow={flow} />
      </Column>
    </>
  );
};
