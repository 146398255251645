import { Box, Row, Text } from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { useCallback } from "react";
import { Outlet, useNavigate, useOutletContext } from "src/router";

import { DetailPage } from "src/components/layout";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useCollectionsQuery } from "src/graphql";
import { OutletContext } from "src/pages/decision-engines";
import catalogIcon from "src/pages/schema/assets/catalog.svg";
import { Table } from "src/ui/table";
import { openUrl } from "src/utils/urls";

export const Collections = () => {
  const context = useOutletContext<OutletContext>();
  const navigate = useNavigate();

  const { data: collections } = useCollectionsQuery(
    { id: context.engine.id },
    {
      suspense: true,
      select: (data) => data.decision_engine_collections,
    },
  );

  const onRowClick = useCallback(
    ({ id }, event) => openUrl(`${id}`, navigate, event),
    [navigate],
  );

  return (
    <DetailPage
      heading="Collections"
      actions={
        <LinkButton href="add" variant="primary">
          Add collection
        </LinkButton>
      }
    >
      <Table
        columns={[
          {
            name: "Name",
            cell: ({ collection: { name } }) => (
              <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
            ),
          },
          {
            name: "Catalog",
            cell: ({ collection: { catalog } }) => {
              return (
                <Row align="center" gap={2}>
                  <Box as="img" src={catalogIcon} w="24px" />
                  <TextWithTooltip fontWeight="medium">
                    {catalog.model.name}
                  </TextWithTooltip>
                </Row>
              );
            },
          },
          {
            name: "Filter",
            cell: ({ collection: { filter } }) => (
              <Text>{filter.conditions.length} criteria</Text>
            ),
          },
        ]}
        onRowClick={onRowClick}
        data={collections}
        placeholder={{
          title: "No collections",
          body: "Create a collection to get started",
        }}
      />
      <Outlet context={context} />
    </DetailPage>
  );
};
