import {
  ArrowRightIcon,
  Badge,
  Button,
  Column,
  PlayIcon,
  Row,
  SectionHeading,
  Skeleton,
  SkeletonBox,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { useSyncRunsQuery } from "src/graphql";
import { Sync } from "src/pages/syncs/sync/utils/types";
import { Table } from "src/ui/table";
import {
  isSyncRunStatus,
  SyncRunStatus,
  UnknownSyncRunStatus,
} from "src/utils/syncs";
import { SyncRunStatusIndicator } from "src/components/syncs/sync-run-status-indicator";
import { isEnum } from "src/types/utils";
import { DiffModeOverride } from "@hightouch/lib/sync/diff-mode/types";
import { commaNumber } from "src/utils/numbers";
import { RunOperationsColumn } from "src/pages/syncs/sync/components/run-operations-column";
import { isSyncMatchBoosted } from "src/pages/syncs/sync/matchbooster";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { useNavigate } from "src/router";
import { useDraft } from "src/contexts/draft-context";
import { openUrl } from "src/utils/urls";
import {
  formatRunCreatedAt,
  syncRunStatusDurationText,
} from "src/pages/syncs/sync/components/utils";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { Card } from "src/components/card";
import { Placeholder } from "src/ui/table/placeholder";
import { ScheduleType } from "src/components/schedule/types";
import { useCurrentRun } from "src/pages/syncs/sync/utils/use-current-run";
import placeholder from "src/assets/placeholders/sync.svg";
import { PermissionedButton } from "src/components/permission";
import { useEntitlements } from "src/hooks/use-entitlement";

type Props = { sync: Sync };

export const RecentRunsTable = ({ sync }: Props) => {
  const navigate = useNavigate();
  const { editingDraft } = useDraft();

  const {
    data,
    error,
    isLoading: isLoadingRuns,
  } = useSyncRunsQuery(
    {
      filter: { destination_instance_id: { _eq: sync.id } },
      limit: 5,
      orderBy: { created_at: "desc" },
    },
    {
      enabled: Boolean(sync),
      select: (data) => data.sync_requests,
      refetchInterval: 5000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const { isPermitted: hasDebugPermissions, isLoading: isLoadingPermissions } =
    useResourcePermission({
      v2: { resource: "sync", grant: "can_debug", id: sync.id },
    });

  const { startRun, isRunDisabled } = useCurrentRun(sync.id);

  const { data: entitlementsData } = useEntitlements(true);
  const overageLockout: boolean = entitlementsData.overage?.overageLockout;

  const showPendingRows =
    isSyncMatchBoosted(sync.config) ||
    data?.some(
      (run) => run?.query_run?.pending_rows && run.query_run.pending_rows > 0,
    );

  const onRowClick = ({ id, status_computed, sync_attempts }, event) => {
    // This isn't ideal, since we'll just silently do nothing when a user doesn't have permissions
    // But right now we don't really have a better to signal that a user can't click a row
    if (!hasDebugPermissions) return;

    const attempt = sync_attempts?.[0];
    const hasRejectedRows =
      (attempt?.add_rejected || 0) +
      (attempt?.change_rejected || 0) +
      (attempt?.remove_rejected || 0);

    const rowType =
      hasRejectedRows || status_computed === SyncRunStatus.WARNING
        ? "rejected"
        : "successful";

    // TODO: this might not work for partner pages
    // Should take in a debugger path props like `runs.tsx`
    openUrl(
      `/syncs/${sync.id}/runs/${id}/${rowType}?${
        editingDraft ? "?editing=true" : ""
      }`,
      navigate,
      event,
    );
  };

  const isLoading = isLoadingRuns || isLoadingPermissions;

  const isJourneyTriggered =
    sync.schedule?.type === ScheduleType.JOURNEY_TRIGGERED;

  return (
    <Skeleton isLoading={isLoading}>
      <SkeletonBox height={isLoading ? "430px" : undefined} borderRadius="md">
        {!data?.length && (
          <Placeholder
            error={Boolean(error)}
            content={{
              title: "This sync has not run yet",
              body: isJourneyTriggered
                ? "This sync will be triggered by your journey"
                : "Trigger your first run to get started.",
              error: "Runs failed to load, please try again.",
              image: placeholder,
              button: !isJourneyTriggered ? (
                <PermissionedButton
                  permission={{
                    v2: {
                      resource: "sync",
                      grant: "can_run",
                      id: sync?.id,
                    },
                  }}
                  isDisabled={
                    isRunDisabled || overageLockout || Boolean(sync?.draft)
                  }
                  variant="primary"
                  icon={PlayIcon}
                  onClick={() => startRun()}
                >
                  Run sync
                </PermissionedButton>
              ) : null,
            }}
          />
        )}
        {data?.length ? (
          <Card gap={4} p={4}>
            <Row alignItems="center" justifyContent="space-between">
              <SectionHeading>Recent sync runs</SectionHeading>
              <Button
                variant="secondary"
                size="sm"
                directionIcon={ArrowRightIcon}
                onClick={() => navigate(`/syncs/${sync.id}/runs`)}
              >
                View all activity
              </Button>
            </Row>
            <Table
              data={data}
              rowHeight="44px"
              loading={isLoading}
              onRowClick={hasDebugPermissions ? onRowClick : undefined}
              columns={[
                {
                  name: "Started at",
                  cell: ({ created_at }) => {
                    const date = formatRunCreatedAt(created_at);
                    return (
                      <TextWithTooltip message={date ?? ""}>
                        {date ?? "--"}
                      </TextWithTooltip>
                    );
                  },
                },
                {
                  name: "Run status",
                  min: "max-content",
                  cell: ({
                    status_computed,
                    completion_ratio,
                    created_at: createdAt,
                    diff_mode_override,
                    finished_at,
                  }) => {
                    if (!isSyncRunStatus(status_computed)) {
                      return (
                        <SyncRunStatusIndicator
                          status={UnknownSyncRunStatus}
                          completionRatio={null}
                        />
                      );
                    }

                    return (
                      <Row gap={1} alignItems="center" fontWeight="medium">
                        <SyncRunStatusIndicator
                          status={status_computed}
                          completionRatio={completion_ratio}
                        />
                        <Text color="text.secondary" fontWeight="normal">
                          {syncRunStatusDurationText(
                            status_computed,
                            createdAt,
                            finished_at,
                          ) ?? ""}
                        </Text>
                        {isEnum(DiffModeOverride)(diff_mode_override) && (
                          <Badge size="sm">
                            {diff_mode_override === DiffModeOverride.ResetCdc
                              ? "Reset CDC"
                              : "Full resync"}
                          </Badge>
                        )}
                      </Row>
                    );
                  },
                },
                {
                  max: "20%",
                  breakpoint: "sm",
                  header: () => (
                    <Row justify="flex-end" flex={1}>
                      <Tooltip message="Number of rows in your full model query results">
                        <Text size="sm" color="text.secondary">
                          Rows queried
                        </Text>
                      </Tooltip>
                    </Row>
                  ),
                  cell: ({ query_run }) => {
                    return (
                      <Column align="flex-end" flex={1}>
                        <Text>
                          {query_run?.size !== undefined
                            ? commaNumber(query_run.size)
                            : "--"}
                        </Text>
                      </Column>
                    );
                  },
                },
                {
                  max: "20%",
                  breakpoint: "sm",
                  header: () => (
                    <Row justify="flex-end" flex={1}>
                      <Tooltip message="Breakdown of operations that were successful or rejected during each sync run">
                        <Text size="sm" color="text.secondary">
                          Operations
                        </Text>
                      </Tooltip>
                    </Row>
                  ),
                  cellSx: {
                    paddingRight: 8,
                  },
                  cell: ({
                    error,
                    planner_type,
                    query_run,
                    sync_attempts,
                    add_executed,
                    change_executed,
                    remove_executed,
                    status_computed,
                  }) => (
                    <RunOperationsColumn
                      showPendingRows={Boolean(showPendingRows)}
                      queryRun={query_run}
                      syncAttempts={sync_attempts}
                      syncRequest={{
                        status_computed,
                        error,
                        planner_type,
                        add_executed,
                        change_executed,
                        remove_executed,
                      }}
                    />
                  ),
                },
              ]}
            />
          </Card>
        ) : null}
      </SkeletonBox>
    </Skeleton>
  );
};
