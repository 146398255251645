import { FC, useMemo, useState } from "react";

import {
  FormulaTraitConfig,
  isFormulaTraitConfig,
  TraitConfig,
  TraitType,
} from "@hightouch/lib/query/visual/types";
import {
  Column,
  InformationIcon,
  Row,
  Switch,
  Text,
  TextProps,
  Tooltip,
} from "@hightouchio/ui";
import { isPresent } from "ts-extras";

import PlaceholderSrc from "src/assets/placeholders/generic.svg";
import { PermissionedButton } from "src/components/permission";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { Pagination, Table } from "src/ui/table";
import { getColumnName } from "src/utils/models";

import { usePreviewTrait } from "./use-preview-trait";
import { transformModelColumnsForFormulaTrait } from "./utils";
import { ColumnFragment, FilterableColumn } from "src/graphql";
import { TraitDefinition } from "src/types/visual";
import { stringify } from "src/utils/string";

type Props = {
  parentModel: {
    id: string;
    visual_query_primary_label: string | null;
    visual_query_secondary_label: string | null;
    columns: ColumnFragment[];
    filterable_audience_columns: FilterableColumn[];
    enabled_sampled_segments: { id: string }[];
    traits: TraitDefinition[];
  };
  trait:
    | {
        name?: string;
        type: TraitType;
        config: TraitConfig;
        relationshipId: string;
      }
    | {
        name?: string;
        type: TraitType.Formula;
        config: FormulaTraitConfig;
      };
  isDisabled?: boolean;
  onValidateTrait?: () => Promise<void>;
  onError?: (error: Error) => void;
};

const ROWS_PER_PAGE = 10;
const MAX_CELL_WIDTH = 176;

export const PreviewTrait: FC<Props> = ({
  parentModel,
  trait,
  isDisabled,
  onValidateTrait,
  onError,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const isSamplingAvailable = Boolean(
    parentModel.enabled_sampled_segments.length,
  );
  const [isFastPreviewEnabled, setIsFastPreviewEnabled] =
    useState(isSamplingAvailable);

  const {
    previewTrait,
    data: traitPreviewData,
    error,
    isLoading,
  } = usePreviewTrait({
    parentModel: parentModel,
    trait,
    isFastPreviewEnabled,
  });

  // Map the referenced aliases to the actual column name
  const formulaTraitColumns = useMemo<
    { columnName: string; alias: string }[]
  >(() => {
    // Bail if this isn't a formula trait
    if (!isFormulaTraitConfig(trait.type, trait.config)) {
      return [];
    }

    // Get all the columns in the correct format
    const allColumns = transformModelColumnsForFormulaTrait({
      columns: parentModel.filterable_audience_columns,
      traits: parentModel.traits,
    });

    return trait.config.referencedColumns.map(({ alias }) => {
      const column = allColumns.find((c) => c.alias === alias);
      return {
        // If we can't find the column, it's possible that it was deleted.
        // Fallback to the alias used in the referenced columns.
        // This is just for display purposes so the UI doesn't look broken either.
        columnName: column?.label ?? alias,
        alias: column?.alias ?? "",
      };
    });
  }, [parentModel, trait]);

  const primaryLabel = parentModel.visual_query_primary_label ?? "";
  const secondaryLabel = parentModel.visual_query_secondary_label ?? "";

  // Resolve column name to alias
  const primaryLabelColumnName = getColumnName(
    primaryLabel,
    parentModel.columns,
  );
  const secondaryLabelColumnName = getColumnName(
    secondaryLabel,
    parentModel.columns,
  );

  const rows = traitPreviewData?.rows ?? [];

  const showTable = rows.length > 0 || Boolean(error);

  const clickPreview = async () => {
    try {
      await onValidateTrait?.();
      await previewTrait();
    } catch (error) {
      onError?.(error);
    }
  };

  return (
    <Column gap={2} align="flex-start" width="100%">
      <Row gap={3}>
        <PermissionedButton
          permission={{
            v1: { resource: "audience_schema", grant: "create" },
            v2: {
              resource: "model",
              grant: "can_preview",
              id: parentModel.id,
            },
          }}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={clickPreview}
        >
          Preview results
        </PermissionedButton>
        {isSamplingAvailable && (
          <Row alignItems="center" gap={2}>
            <Row gap={1}>
              <Text fontWeight="medium">Fast preview</Text>
              <Tooltip message="Fast preview uses the sampled version of your models to speed up the query.">
                <InformationIcon />
              </Tooltip>
            </Row>
            <Switch
              size="sm"
              isChecked={isFastPreviewEnabled}
              onChange={setIsFastPreviewEnabled}
            />
          </Row>
        )}
      </Row>
      {showTable && (
        <Column gap={2} width="100%">
          <Table
            scrollable
            columns={[
              {
                max: `${MAX_CELL_WIDTH}px`,
                header: () => (
                  <TextWithTooltip
                    color="text.secondary"
                    textTransform="uppercase"
                  >
                    {primaryLabelColumnName}
                  </TextWithTooltip>
                ),
                cell: (data) => (
                  <CellValue value={data[primaryLabelColumnName]} />
                ),
              },
              {
                max: `${MAX_CELL_WIDTH}px`,
                header: () => (
                  <TextWithTooltip
                    color="text.secondary"
                    textTransform="uppercase"
                  >
                    {secondaryLabelColumnName}
                  </TextWithTooltip>
                ),
                cell: (data) => (
                  <CellValue value={data[secondaryLabelColumnName]} />
                ),
              },
              // Show the values of the columns referenced in a formula trait
              ...formulaTraitColumns
                .filter(
                  ({ columnName }) =>
                    // We already show the primary and secondary label columns.
                    // So if they're used in the formula, we don't want to show them twice.
                    columnName !== primaryLabelColumnName &&
                    columnName !== secondaryLabelColumnName,
                )
                .map(({ alias, columnName }) => ({
                  max: `${MAX_CELL_WIDTH}px`,
                  header: () => (
                    <TextWithTooltip
                      color="text.secondary"
                      textTransform="uppercase"
                    >
                      {columnName}
                    </TextWithTooltip>
                  ),
                  cell: (row) => <CellValue value={row[alias]} />,
                })),
              {
                max: `${MAX_CELL_WIDTH}px`,
                header: () => (
                  <Row width="100%" justifyContent="right">
                    <TextWithTooltip
                      color="text.secondary"
                      textTransform="uppercase"
                    >
                      {trait.name || "Trait Preview"}
                    </TextWithTooltip>
                  </Row>
                ),
                cell: ({ trait_preview }) => (
                  <CellValue value={trait_preview} />
                ),
                cellSx: {
                  bg: "primary.background !important",
                  justifyContent: "right",
                },
                headerSx: { bg: "primary.background" },
              },
            ]}
            data={
              rows.slice(
                0 + currentPage * ROWS_PER_PAGE,
                currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE,
              ) as any
            }
            isDense
            placeholder={{
              image: PlaceholderSrc,
              title: "No results",
              body: "The preview did not return any results. Please try again.",
              error,
            }}
            error={Boolean(error)}
          />
          <Row justifyContent="end">
            <Pagination
              count={rows.length}
              page={currentPage}
              rowsPerPage={ROWS_PER_PAGE}
              setPage={setCurrentPage}
            />
          </Row>
        </Column>
      )}
    </Column>
  );
};

const CellValue: FC<{ value: any } & Omit<TextProps, "children">> = ({
  value,
  ...props
}) => {
  if (!isPresent(value)) {
    return (
      <Text color="text.placeholder" {...props}>
        --
      </Text>
    );
  }

  return <TextWithTooltip {...props}>{stringify(value)}</TextWithTooltip>;
};
