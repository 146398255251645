import {
  Box,
  BreakdownIcon,
  GlobeIcon,
  OrbitIcon,
  PerformanceIcon,
  Select,
  TableIcon,
} from "@hightouchio/ui";
import { IconBox } from "../../components/icon-box";
import { ChartFormState, GraphType } from "./types";
import { Controller, useFormContext } from "react-hook-form";

export const DecisionEngineFlowIcon = () => (
  <IconBox
    boxSize="20px"
    icon={<OrbitIcon />}
    iconSize="20px"
    iconSx={{ color: "text.secondary" }}
    hasGradient={false}
  />
);

export const BaseGlobeIcon = () => (
  <IconBox
    boxSize="20px"
    hasGradient={false}
    icon={<GlobeIcon />}
    iconSize="20px"
    iconSx={{ color: "text.secondary" }}
  />
);

export const GraphTypeIcons: Record<GraphType, JSX.Element> = {
  [GraphType.Line]: <PerformanceIcon />,
  [GraphType.Bar]: <BreakdownIcon />,
  [GraphType.Table]: <TableIcon />,
};

const graphOptions = [
  {
    label: "Line",
    value: GraphType.Line,
    icon: (props) => <PerformanceIcon {...props} color="text.secondary" />,
  },
  {
    label: "Bar",
    value: GraphType.Bar,
    icon: (props) => <BreakdownIcon {...props} color="text.secondary" />,
  },
  {
    label: "Table",
    value: GraphType.Table,
    icon: (props) => <TableIcon {...props} color="text.secondary" />,
  },
];

export const GraphOptionsSelection = () => {
  const form = useFormContext<ChartFormState>();

  return (
    <Controller
      control={form.control}
      name="graphType"
      render={({ field }) => (
        <Box width={32}>
          <Select
            optionAccessory={({ icon }) => ({
              type: "icon",
              icon,
            })}
            options={graphOptions}
            value={field.value}
            size="sm"
            onChange={field.onChange}
            popoverWidth="4xs"
            width="100%"
          />
        </Box>
      )}
    />
  );
};
