import {
  Alert,
  Button,
  Column,
  Combobox,
  DrawerBody,
  EditableText,
  EditIcon,
  FormField,
  PlusIcon,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import { ComponentType, ReactNode, SVGAttributes } from "react";
import { Navigate, useNavigate, useOutletContext } from "src/router";

import {
  DecisionEngineFlowMessageConfig,
  DecisionEngineMessageConfig,
} from "@hightouch/lib/customer-data/decision-engine/types";
import { format as formatDate } from "date-fns";
import pluralize from "pluralize";
import { Card } from "src/components/card";
import {
  useDecisionEngineDestinationResourcesQuery,
  useEnableDecisionEngineFlowMessageMutation,
  useUpdateDecisionEngineFlowMessageMutation,
  useUpdateDecisionEngineMessageMutation,
} from "src/graphql";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";
import { OptimisticUpdate } from "src/utils/optimistic-update";
import { FlowMessageContext } from ".";
import { DestinationResourceLink } from "./components/campaign-link";
import { SyncsCell } from "src/pages/syncs/sync/components/syncs-cell";

export const Overview = () => {
  const { flowMessage } = useOutletContext<FlowMessageContext>();
  const isDraft = !flowMessage.model?.syncs?.length && !flowMessage.enabled;

  if (!isDraft) {
    return <Navigate to="../configuration" replace />;
  }
  return (
    <DrawerBody bg="gray.50">
      <Column flex={1} gap={6} pb={6} maxW="2xl" mx="auto">
        <Card
          heading="Draft action"
          subheading="Complete the tasks listed below to finish setting up this action"
          gap={6}
        >
          <MessageCampaignPreview />
          <ConfigurationPreview />
          <GuardrailsPreview />
          <CustomChannelSetup />
          <EnableAction />
        </Card>
      </Column>
    </DrawerBody>
  );
};

export const ConfigurationPreview = () => {
  const { flowMessage } = useOutletContext<FlowMessageContext>();
  const variables = flowMessage.message.variables;
  const tags = flowMessage.message.tags ?? {};
  const collections = flowMessage.message.collections;

  const hasConfiguration =
    variables.length || Object.keys(tags).length || collections.length;
  return (
    <FormField
      label="Additional configuration"
      description="Personalize further with dynamic content"
      isOptional
    >
      <SuggestedAction
        action={{
          text: "Modify",
          url: "../configuration",
          icon: EditIcon,
        }}
        isDefined={hasConfiguration}
        emptyState="No additional configuration"
      >
        {Boolean(variables.length) && (
          <Text fontWeight="medium">
            {variables.length} {pluralize("variable", variables.length)},{" "}
            {variables.flatMap((v) => v.values).length}{" "}
            {pluralize("values", variables.length)}
          </Text>
        )}
        {collections.map((collection) => {
          return (
            <Text
              key={collection.decision_engine_collection.id}
              fontWeight="medium"
            >
              {collection.item_count} {pluralize("item", collection.item_count)}{" "}
              from {collection.decision_engine_collection.collection.name}{" "}
              collection
            </Text>
          );
        })}
        {Object.keys(tags).length > 0 && (
          <Text fontWeight="medium">
            {Object.keys(tags).length}{" "}
            {pluralize("tag", Object.keys(tags).length)}
          </Text>
        )}
      </SuggestedAction>
    </FormField>
  );
};

export const GuardrailsPreview = () => {
  const { flowMessage } = useOutletContext<FlowMessageContext>();

  const flowMessageConfig =
    flowMessage.config as DecisionEngineFlowMessageConfig;

  const hasFrequencyGuardrails =
    flowMessageConfig.maxSendsPerUser ||
    flowMessageConfig.maxSendsPerUserWindowDays ||
    flowMessageConfig.minOtherMessagesInBetween;
  const hasEligibilityGuardrails = flowMessageConfig.userFilter;
  const hasTimelineGuardrails =
    flowMessageConfig.firstEligibleSendDate ||
    flowMessageConfig.lastEligibleSendDate;

  return (
    <FormField
      label="Guardrails"
      description="Specify eligible users, frequency caps, and start/stop dates"
      isOptional
    >
      <SuggestedAction
        action={{
          text: "Modify",
          url: "../guardrails",
          icon: EditIcon,
        }}
        emptyState="No guardrails"
        isDefined={Boolean(
          hasFrequencyGuardrails ||
            hasEligibilityGuardrails ||
            hasTimelineGuardrails,
        )}
      >
        {hasFrequencyGuardrails && (
          <>
            {flowMessageConfig.maxSendsPerUser ? (
              <Text fontWeight="medium">
                Maximum {flowMessageConfig.maxSendsPerUser}{" "}
                {pluralize("send", flowMessageConfig.maxSendsPerUser)} per user
                {flowMessageConfig.maxSendsPerUserWindowDays
                  ? ` every ${flowMessageConfig.maxSendsPerUserWindowDays} ${pluralize("day", flowMessageConfig.maxSendsPerUserWindowDays)}`
                  : ""}
              </Text>
            ) : null}
            {flowMessageConfig.minOtherMessagesInBetween ? (
              <Text fontWeight="medium">
                Minimum {flowMessageConfig.minOtherMessagesInBetween} other
                action before repeating this action
              </Text>
            ) : null}
          </>
        )}
        {hasTimelineGuardrails && (
          <Text fontWeight="medium">
            Sending
            {flowMessageConfig.firstEligibleSendDate
              ? ` from  ${formatDate(flowMessageConfig.firstEligibleSendDate, "MMM d, yyyy")}`
              : ""}
            {flowMessageConfig.lastEligibleSendDate
              ? ` until ${formatDate(flowMessageConfig.lastEligibleSendDate, "MMM d, yyyy")}`
              : ""}
          </Text>
        )}
        {hasEligibilityGuardrails && <Text>Eligibility filters applied</Text>}
      </SuggestedAction>
    </FormField>
  );
};

export const MessageCampaignPreview = () => {
  const { flowMessage, flow } = useOutletContext<FlowMessageContext>();
  const channel = flowMessage.message.channel;
  const flowMessageConfig =
    flowMessage.config as DecisionEngineFlowMessageConfig;
  const messageConfig = flowMessage.message
    .config as DecisionEngineMessageConfig;
  const { destination, config } = channel;

  const channelDefinition = getChannelDefinition(destination.type);
  const campaignSetup = channelDefinition.campaignSetup(config);
  const resourceName = channelDefinition.getResourceName(config);
  const resourceType = channelDefinition.getResourceType(channel);

  const updateFlowMessageMutation =
    useUpdateDecisionEngineFlowMessageMutation();
  const updateMessageMutation = useUpdateDecisionEngineMessageMutation();

  if (campaignSetup === "event-triggered") {
    return (
      <FormField
        label="Triggered campaign"
        description="Configure the event that will trigger this message"
      >
        <SuggestedAction>
          <Column gap={4}>
            {Boolean(resourceType) && (
              <FormField
                label={`${channel.destination.definition.name} ${resourceName}`}
                description={`The ${resourceName} associated with this message.`}
              >
                <OptimisticUpdate
                  initialValue={flowMessageConfig.resourceId}
                  onUpdate={async (value) => {
                    await updateFlowMessageMutation.mutate({
                      flowId: flow.id,
                      messageId: flowMessage.message.id,
                      input: {
                        config: {
                          ...flowMessageConfig,
                          resourceId: value,
                        },
                      },
                    });
                  }}
                  render={({ field }) => {
                    const { data, isLoading } =
                      useDecisionEngineDestinationResourcesQuery(
                        {
                          destinationId: destination.id,
                          type:
                            channelDefinition.getResourceType(channel) ?? "",
                        },
                        {
                          select: (data) =>
                            data.getDecisionEngineDestinationResources,
                          enabled: Boolean(
                            channelDefinition.getResourceType(channel),
                          ),
                        },
                      );
                    return (
                      <Combobox
                        {...field}
                        isLoading={isLoading}
                        options={data ?? []}
                        optionLabel={(option) => option.name ?? option.id}
                        optionValue={(option) => option.id}
                        placeholder={`Select a ${resourceName}...`}
                      />
                    );
                  }}
                />
              </FormField>
            )}
            <FormField
              label="Event name"
              description="A unique identifier for the event that will trigger this message."
            >
              <OptimisticUpdate
                initialValue={flowMessageConfig.campaignId}
                onUpdate={async (value) => {
                  await updateFlowMessageMutation.mutate({
                    flowId: flow.id,
                    messageId: flowMessage.message.id,
                    input: {
                      config: {
                        ...flowMessageConfig,
                        campaignId: value,
                      },
                    },
                  });
                }}
                render={({ field }) => (
                  <EditableText
                    {...field}
                    placeholder="Identifier..."
                    isMonospace
                  />
                )}
              />
            </FormField>
          </Column>
        </SuggestedAction>
      </FormField>
    );
  }

  if (campaignSetup === "freeform") {
    return (
      <FormField
        label="Message identifier"
        description="Configure a unique identifier you can use to reference this message."
      >
        <SuggestedAction>
          <OptimisticUpdate
            initialValue={messageConfig.baseMessageId}
            onUpdate={async (value) => {
              await updateMessageMutation.mutate({
                id: flowMessage.message.id,
                input: {
                  config: {
                    ...messageConfig,
                    baseMessageId: value,
                  },
                },
              });
            }}
            render={({ field }) => (
              <EditableText
                {...field}
                placeholder="Identifier..."
                isMonospace
              />
            )}
          />
        </SuggestedAction>
      </FormField>
    );
  }

  return (
    <FormField label="Creative" description="Link this action to a creative">
      <SuggestedAction>
        <Row gap={1}>
          <Text fontWeight="medium">Linked to</Text>
          <DestinationResourceLink
            variant="compact"
            channel={channel}
            resourceId={
              flowMessage.config.resourceId ?? flowMessage.config.campaignId
            }
          />
        </Row>
      </SuggestedAction>
    </FormField>
  );
};

const SuggestedAction = ({
  children,
  action,
  emptyState,
  isDefined = true,
}: {
  children: ReactNode;
  action?: {
    text: string;
    icon: ComponentType<SVGAttributes<SVGElement>>;
    url: string;
  };
  isDefined?: boolean;
  emptyState?: string;
}) => {
  const navigate = useNavigate();
  return (
    <Row
      bg="base.background"
      borderRadius="md"
      p={4}
      alignItems="center"
      justifyContent="space-between"
    >
      {!isDefined ? (
        <Text color="text.secondary" fontWeight="medium">
          {emptyState}
        </Text>
      ) : (
        <Column>{children}</Column>
      )}
      {action ? (
        <Button
          size="sm"
          icon={action.icon}
          onClick={() => {
            navigate(action.url);
          }}
        >
          {action.text}
        </Button>
      ) : null}
    </Row>
  );
};

const EnableAction = () => {
  const { toast } = useToast();
  const { flowMessage, flow } = useOutletContext<FlowMessageContext>();
  const messageId = flowMessage.message.id;
  const flowId = flow.id;
  const enableMutation = useEnableDecisionEngineFlowMessageMutation();

  return (
    <FormField label="Enable" description="This action will start being sent">
      {enableMutation.data &&
        enableMutation.data.enableDecisionEngineFlowMessage.__typename ===
          "DecisionEngineError" && (
          <Alert
            variant="inline"
            type="error"
            title="Unable to initialize message"
            message={enableMutation.data?.enableDecisionEngineFlowMessage.error}
          />
        )}
      <Button
        variant="primary"
        isLoading={enableMutation.isLoading}
        onClick={async () => {
          try {
            const response = await enableMutation.mutateAsync({
              messageId,
              flowId,
            });
            if (
              response.enableDecisionEngineFlowMessage.__typename ===
              "DecisionEngineSuccess"
            ) {
              toast({
                id: "enable-message",
                title: "Message initialized and enabled",
                variant: "success",
              });
            } else {
              toast({
                id: "enable-message",
                title: "Error initializing message",
                variant: "error",
                message: response.enableDecisionEngineFlowMessage.error,
              });
            }
          } catch (_err) {
            toast({
              id: "enable-message",
              title: "Error initializing message",
              variant: "error",
            });
          }
        }}
      >
        Enable action
      </Button>
    </FormField>
  );
};

const CustomChannelSetup = () => {
  const { flowMessage } = useOutletContext<FlowMessageContext>();
  const { destination, config } = flowMessage.message.channel;
  const channelDefinition = getChannelDefinition(destination.type);

  if (channelDefinition.campaignSetup(config) !== "freeform") {
    return null;
  }

  return (
    <FormField
      label="Syncs"
      description="Add a sync to enable this custom channel"
    >
      <SuggestedAction
        isDefined={Boolean(flowMessage.model?.syncs?.length)}
        emptyState="No syncs"
        action={{
          text: "Add sync",
          url: `/syncs/new?model=${flowMessage.model?.id}&destination=${flowMessage.message.channel.destination.id}`,
          icon: PlusIcon,
        }}
      >
        <SyncsCell syncs={flowMessage.model?.syncs ?? []} />
      </SuggestedAction>
    </FormField>
  );
};
