import { FC, useState } from "react";

import { captureException } from "@sentry/react";
import {
  Column,
  IconButton,
  PlusIcon,
  Row,
  SectionHeading,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { useFieldArray, useFormContext } from "react-hook-form";
import { isPresent } from "ts-extras";

import { getPropertyNameFromColumn } from "src/components/explore/visual/utils";
import * as analytics from "src/lib/analytics";
import {
  AggregationOption,
  AggregationOptionsWithColumns,
} from "src/pages/metrics/constants";
import {
  getAggregationConfiguration,
  mapAggregationConfigurationToConfigurationOption,
} from "src/pages/metrics/utils";
import {
  AndOrCondition,
  ConditionType,
  PropertyCondition,
} from "src/types/visual";

import { toSingleCondition } from "src/components/audiences/utils";
import { useFormErrorContext } from "src/contexts/form-error-context";
import { useAudiencesForGoalsQuery, useCreateGoalMutation } from "src/graphql";
import {
  getFilteredGroupByColumnsFromMetricSelection,
  useAnalyticsContext,
} from "src/pages/analytics/state";
import { DEFAULT_METRIC_SELECTION } from "src/pages/analytics/state/constants";
import { ChartFormState, MetricSelection } from "src/pages/analytics/types";
import {
  getEventById,
  getMetricById,
  getMetricSelectionFromMetricOption,
  isInitialMetric,
} from "src/pages/analytics/utils";
import {
  SimpleMetricFormData,
  SimpleMetricModal,
} from "src/pages/metrics/simple-metric-modal";
import { Relationship } from "src/types/visual";

import { MetricBox } from "./metric-box";
import { useAvailableMetricOptions } from "../hooks/use-available-metrics";
import { isMeasuringDecisionEngineIncrementality } from "../decision-engine-utils";
import { NormalizationType } from "../../../types/visual";

type MetricsProps = {
  metricSeriesErrors: Record<string, string>;
};

export const Metrics: FC<MetricsProps> = ({ metricSeriesErrors }) => {
  const { toast } = useToast();

  const { metrics, events, parentModelLoading, audiencesAndMetricsLoading } =
    useAnalyticsContext();
  const { hasValidationErrors } = useFormErrorContext();
  const createGoalMutation = useCreateGoalMutation();

  const form = useFormContext<ChartFormState>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Suppress circular reference error
  const { fields, update, remove, append } = useFieldArray({
    control: form.control,
    name: "metricSelection",
  });

  const [metricToSave, setMetricToSave] = useState<MetricSelection | null>(
    null,
  );

  const selectedMetrics = form.watch("metricSelection");
  const parentModelId = form.watch("parentModelId");
  const measuringSelection = form.watch("measuringSelection");
  const measuringMode = form.getValues("measuringMode");

  const {
    availableMetricsOptions: metricOptions,
    supportedAggregationOptions,
    isLoading: isOptionsLoading,
  } = useAvailableMetricOptions({
    measuringSelection,
    measuringMode,
    metrics,
    events,
  });

  // TODO(samuel): come back to see if this component may use analytics context instead of this hook
  const { data: audiencesForMetrics } = useAudiencesForGoalsQuery(
    { parentModelId: parentModelId?.toString() ?? "" },
    { enabled: Boolean(parentModelId), select: (data) => data.segments },
  );

  const updateMetric = (index: number, updates: Partial<MetricSelection>) => {
    const metric = form.getValues(`metricSelection.${index}`);

    let conditions = updates.conditions;
    if (updates.conditions) {
      conditions = toSingleCondition(
        updates.conditions,
      ) as AndOrCondition<PropertyCondition>[]; // TODO(samuel): fix type of this function
    }

    update(index, {
      ...metric,
      ...updates,
      conditions: conditions ?? metric.conditions,
    });
  };

  const resetMetric = (index: number) => {
    const metric = form.getValues(`metricSelection.${index}`);

    const metricDefinition = metrics.find(({ id }) => id === metric.id);
    const aggregationMethod =
      (metricDefinition &&
        mapAggregationConfigurationToConfigurationOption(metricDefinition)) ||
      AggregationOption.Count;

    update(index, {
      ...metric,
      aggregationMethod,
      conditions: metricDefinition?.config.filter?.subconditions ?? [],
      attributionWindow: metricDefinition?.attribution_window,
      column: undefined,
    });
  };

  const removeMetric = (index: number) => {
    const values = form.getValues();

    remove(index);

    form.setValue(
      "groupByColumns",
      getFilteredGroupByColumnsFromMetricSelection({
        parentModelId: parentModelId,
        selectedMetrics: values.metricSelection.filter(
          (_, metricIndex) => metricIndex !== index,
        ),
        metricOptions: metrics,
        selectedGroupByColumns: values.groupByColumns.filter(isPresent),
      }),
    );
  };

  const openMetricModal = (metricData: MetricSelection) => {
    if (hasValidationErrors()) {
      toast({
        id: "validation-toast",
        title: "Validation failed",
        message: "Please complete all required fields to save a new metric",
        variant: "error",
      });

      return;
    }

    setMetricToSave(metricData);
  };

  const createMetric = async (data: SimpleMetricFormData) => {
    const index = selectedMetrics.findIndex(
      ({ id }) => id === metricToSave?.id,
    );

    if (!parentModelId || index < 0 || !metricToSave) {
      return;
    }

    let selectedEvent: Relationship | undefined;
    if (metricToSave?.eventModelId === null) {
      const metric = getMetricById(metrics, metricToSave.id);
      selectedEvent = getEventById(events, {
        eventModelId: metric?.config.eventModelId,
      });
    } else {
      selectedEvent = getEventById(events, {
        relationshipId: metricToSave?.id.toString(),
      });
    }

    try {
      const aggregationConfig = getAggregationConfiguration(
        metricToSave?.aggregationMethod,
      );

      const result = await createGoalMutation.mutateAsync({
        input: {
          name: data.name,
          description: data.description,
          parent_model_id: parentModelId.toString(),
          attribution_window: {
            quantity: data.quantity,
            unit: data.unit,
            basis: data.basis,
          },
          enabled: true,
          audience_goals: {
            data:
              audiencesForMetrics?.map((audience) => ({
                segment_id: audience.id,
                enabled: true,
              })) ?? [],
          },
          aggregation: aggregationConfig?.aggregation,
          audience_aggregation: aggregationConfig?.audienceAggregation,
          config: {
            type: ConditionType.Event,
            eventModelId: selectedEvent?.to_model.id.toString(),
            relationshipId: selectedEvent?.id.toString(),
            filter: {
              subconditions: toSingleCondition(metricToSave?.conditions),
            },
            column: metricToSave?.column?.column_reference ?? null,
          },
        },
      });

      if (!result.insert_goals_one) {
        // TODO(samuel): What to do if this is null?
        return;
      }

      toast({
        id: "metrics-form-toast",
        title: "Metric created successfully",
        variant: "success",
      });

      update(index, {
        id: result.insert_goals_one.id,
        eventModelId: null,
        name: data.name,
        description: data.description ?? null,
        aggregationMethod: metricToSave?.aggregationMethod,
        conditions: metricToSave?.conditions ?? null,
        attributionWindow: {
          quantity: data.quantity,
          unit: data.unit,
          basis: data.basis,
        },
        column: metricToSave?.column,
      });

      setMetricToSave(null);
    } catch (error) {
      captureException(error);

      toast({
        id: "metrics-form-toast",
        title: "Metric creation error",
        message: "Error creating metric.",
        variant: "error",
      });
    }
  };

  return (
    <Column gap={2}>
      <Row align="center" justifyContent="space-between">
        <SectionHeading>Metrics</SectionHeading>
        <Tooltip message="Add metric">
          <IconButton
            aria-label="Add metric."
            icon={PlusIcon}
            onClick={() => append(DEFAULT_METRIC_SELECTION)}
          />
        </Tooltip>
      </Row>

      {fields?.map((_, index) => {
        const metric = selectedMetrics[index];
        if (!metric) return null;

        const {
          aggregationMethod,
          conditions = [],
          attributionWindow,
          column,
          normalization,
          ...selectedMetric
        } = metric;
        const metricDefinition = metrics.find(
          ({ id }) => id === selectedMetric.id,
        );

        let metricColumn = column;
        const metricDefinitionAggregationMethod = metricDefinition
          ? mapAggregationConfigurationToConfigurationOption(metricDefinition)
          : AggregationOption.Count;

        if (
          !metricColumn &&
          metricDefinition &&
          metricDefinitionAggregationMethod &&
          AggregationOptionsWithColumns.includes(
            metricDefinitionAggregationMethod,
          )
        ) {
          const columnReference =
            metricDefinition && "column" in metricDefinition.config
              ? metricDefinition?.config.column
              : null;
          const event = events.find(
            ({ id }) =>
              metricDefinition.config.relationshipId.toString() ===
              id.toString(),
          );
          metricColumn = event?.to_model.filterable_audience_columns.find(
            ({ name }) => name === getPropertyNameFromColumn(columnReference),
          );
        }

        // Conditions will always be wrapped in an and/or condition.
        let propertyConditions: PropertyCondition[] = [];

        if (conditions?.[0]?.type === ConditionType.And) {
          // Pulling subconditions out doesn't resolve the type, since it is recursive.
          propertyConditions = conditions?.[0]
            ?.conditions as PropertyCondition[];
        }

        return (
          <MetricBox
            key={index}
            aggregationMethod={aggregationMethod}
            attributionWindow={attributionWindow}
            normalization={normalization}
            column={metricColumn}
            conditions={propertyConditions}
            error={metricSeriesErrors[metric.id]}
            isDirty={
              metricDefinition &&
              !isInitialMetric(metricDefinition, {
                conditions,
                attributionWindow,
                normalization,
              })
            }
            isLoading={
              parentModelLoading ||
              audiencesAndMetricsLoading ||
              isOptionsLoading
            }
            metric={selectedMetric}
            metricOptions={metricOptions ?? []}
            aggregationOptions={supportedAggregationOptions}
            onRemove={fields.length > 1 ? () => removeMetric(index) : undefined}
            onResetForm={() => resetMetric(index)}
            onSave={() => openMetricModal(metric)}
            onSelectNewMetric={(metricOption) => {
              const data = getMetricSelectionFromMetricOption({
                metricOption,
                events,
                metrics,
              });

              if (data) {
                analytics.track("New Metric Selected", {
                  chart_type: form.getValues("graphType"),
                  event_model_id: data.eventModelId,
                  is_event: data.eventModelId !== null,
                  metric_id: data.id,
                  metric_name: data.name,
                });

                // Explicitly set normalization default for incrementality mode
                if (
                  isMeasuringDecisionEngineIncrementality(
                    measuringSelection?.scope,
                    measuringMode,
                  )
                ) {
                  data.normalization = NormalizationType.TreatmentSize;
                }

                update(index, data);
              }
            }}
            onUpdate={(updates) => {
              analytics.track("Updating Live Metric", {
                chart_type: form.getValues("graphType"),
                event_model_id: updates.eventModelId,
                is_event: updates.eventModelId !== null,
                metric_id: updates.id,
                metric_name: updates.name,
              });

              updateMetric(index, updates);
            }}
          />
        );
      })}

      <SimpleMetricModal
        metricDefinition={metricToSave}
        isLoading={createGoalMutation.isLoading}
        isOpen={Boolean(metricToSave)}
        onClose={() => setMetricToSave(null)}
        onSave={(data) => createMetric(data)}
      />
    </Column>
  );
};
