import { type FC, useEffect, useRef, useState } from "react";

import {
  ArrowRightIcon,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Column,
  Portal,
  Row,
  StatusIndicator,
  Text,
} from "@hightouchio/ui";
import { RouterLink } from "src/router";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { AnimatedBox } from "src/ui/animations";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  getSyncStatusColor,
  syncHealthOrUnknown,
  SyncRunStatus,
  syncRunStatusOrUnknownStatus,
  syncRunStatusToDescription,
  syncRunStatusToIndicatorVariant,
  UnknownSyncRunStatus,
} from "src/utils/syncs";
import { useUser } from "src/contexts/user-context";
import type {
  DestinationDefinition,
  Maybe,
  SyncRequests,
  Syncs,
} from "@hightouch/core/server/graphql/types";
import { format } from "date-fns";
import { SyncRunStatusBadge } from "src/components/syncs/sync-run-status-badge";

type MinimalSyncProperties = {
  sync_requests: Array<
    Pick<SyncRequests, "id" | "created_at" | "status_computed">
  >;
  destination: Maybe<{
    name: Maybe<string>;
    definition: Pick<DestinationDefinition, "name" | "icon">;
  }>;
};

export type Props = {
  syncs: (Pick<Syncs, "id" | "status" | "health" | "schedule_paused"> &
    MinimalSyncProperties)[];
  getSyncLink?: (syncId: string) => string;
  getRunLink?: (syncId: string, runId: string) => string;
};

const gap = 8;

const variants = {
  expand: (index) => ({
    x: index * gap,
  }),
  collapse: (index) => ({
    x: index * -gap,
  }),
};

export const SyncsCell: FC<Readonly<Props>> = ({
  syncs,
  getSyncLink: getSyncLink = (syncId) => `/syncs/${syncId}`,
  getRunLink: getRunLink = (syncId, runId) => `/syncs/${syncId}/runs/${runId}`,
}) => {
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const [expand, setExpand] = useState<boolean>(false);
  const [openSyncIndex, setOpenSyncIndex] = useState<number | undefined>();
  const parent = useRef<HTMLDivElement | null>(null);
  const { workspace } = useUser();
  const alertingEnabled = workspace?.alerting_v2_enabled || false;

  useEffect(() => {
    if (expand) {
      if (!mouseOver && openSyncIndex === undefined) {
        setExpand(false);
      }
    } else if (mouseOver || openSyncIndex !== undefined) {
      setExpand(true);
    }
  }, [mouseOver, expand, openSyncIndex]);

  if (syncs?.length === 0) {
    return (
      <Text fontWeight="medium" color="text.placeholder">
        No syncs
      </Text>
    );
  }

  const visibleSyncs = syncs?.slice(0, 4);
  const collapsedSyncs = syncs?.slice(4, syncs?.length);

  return (
    <Row
      align="center"
      ref={parent}
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
        setOpenSyncIndex(undefined);
      }}
      w="100%"
      h="100%"
      // This is a little gross, but I'm trying to leave a healthy margin before we dismiss the tooltip so we don't force users to sharpshoot if they want to click on it.
      m="-10px"
      p="10px"
    >
      {visibleSyncs?.map((sync, index) => {
        const syncStatusColor = getSyncStatusColor(
          alertingEnabled && sync.health
            ? syncHealthOrUnknown(sync.health)
            : syncRunStatusOrUnknownStatus(sync.status),
        );
        const isOpen = index === openSyncIndex;
        if (!sync.id) return null;
        return (
          <AnimatedBox
            key={sync.id}
            animate={expand ? "expand" : "collapse"}
            custom={index}
            variants={variants}
          >
            <ChakraPopover
              isLazy
              placement="top-start"
              isOpen={isOpen}
              onClose={() => {
                setOpenSyncIndex(undefined);
              }}
            >
              <RouterLink to={getSyncLink(sync.id)}>
                <ChakraPopoverTrigger>
                  <Row
                    onMouseEnter={(e) => {
                      e.stopPropagation();
                      setOpenSyncIndex(isOpen ? undefined : index);
                    }}
                    align="center"
                    justify="center"
                    w="30px"
                    h="30px"
                    borderRadius="50%"
                    bg="white"
                    border="1px"
                    borderColor={syncStatusColor}
                  >
                    <IntegrationIcon
                      size={4}
                      name={sync.destination?.definition?.name}
                      src={sync.destination?.definition?.icon}
                    />
                  </Row>
                </ChakraPopoverTrigger>
              </RouterLink>
              <Portal>
                <ChakraPopoverContent w="fit-content">
                  <ChakraPopoverBody p={0}>
                    <Sync
                      sync={sync}
                      getRunLink={getRunLink}
                      getSyncLink={getSyncLink}
                    />
                  </ChakraPopoverBody>
                </ChakraPopoverContent>
              </Portal>
            </ChakraPopover>
          </AnimatedBox>
        );
      })}
      {collapsedSyncs?.length > 0 && (
        <AnimatedBox
          animate={expand ? "expand" : "collapse"}
          custom={4}
          variants={variants}
        >
          <ChakraPopover
            isLazy
            placement="top-end"
            isOpen={openSyncIndex === 6}
            onClose={() => {
              setOpenSyncIndex(undefined);
            }}
          >
            <ChakraPopoverTrigger>
              <Row
                align="center"
                justify="center"
                w="30px"
                h="30px"
                borderRadius="50%"
                bg="gray.200"
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  setOpenSyncIndex(openSyncIndex === 6 ? undefined : 6);
                }}
              >
                <Text size="sm">+{collapsedSyncs.length}</Text>
              </Row>
            </ChakraPopoverTrigger>
            <Portal>
              <ChakraPopoverContent maxH="80vh">
                <ChakraPopoverBody
                  p={0}
                  display="flex"
                  flexDir="column"
                  gap={2}
                  overflow="auto"
                >
                  {collapsedSyncs.map((sync) => (
                    <Sync
                      key={sync.id}
                      sync={sync}
                      getRunLink={getRunLink}
                      getSyncLink={getSyncLink}
                    />
                  ))}
                </ChakraPopoverBody>
              </ChakraPopoverContent>
            </Portal>
          </ChakraPopover>
        </AnimatedBox>
      )}
    </Row>
  );
};

const Sync = ({
  sync: { id, destination, sync_requests },
  getRunLink: getRunLink,
  getSyncLink: getSyncLink = (syncId) => `/syncs/${syncId}`,
}: {
  getSyncLink: (syncId: string) => string;
  getRunLink: (syncId: string, runId: string) => string;
  sync: Pick<Syncs, "id" | "health" | "schedule_paused" | "status"> &
    MinimalSyncProperties;
}) => {
  if (!id) return null;
  const request = sync_requests?.[0];

  const definition = destination?.definition;
  const name = destination?.name;

  const verifiedLastRunStatus = request
    ? syncRunStatusOrUnknownStatus(request.status_computed)
    : SyncRunStatus.PENDING;

  return (
    <Column px={3} py={2} gap={2}>
      <Row align="center" gap={2}>
        <IntegrationIcon
          name={definition?.name}
          src={definition?.icon}
          size={5}
        />
        <TextWithTooltip fontWeight="semibold">
          {name ?? definition?.name ?? "Private destination"}
        </TextWithTooltip>
        {verifiedLastRunStatus !== UnknownSyncRunStatus && (
          <SyncRunStatusBadge status={verifiedLastRunStatus} />
        )}
      </Row>

      {request && verifiedLastRunStatus !== UnknownSyncRunStatus && (
        <RouterLink to={getRunLink(id, request.id)}>
          <Column
            p={2}
            backgroundColor="base.background"
            borderRadius={4}
            gap={1}
          >
            <Row align="center" gap={4}>
              <StatusIndicator
                variant={syncRunStatusToIndicatorVariant(verifiedLastRunStatus)}
              >
                <Text size="sm" fontWeight="medium">
                  {syncRunStatusToDescription[verifiedLastRunStatus]}
                </Text>
              </StatusIndicator>
            </Row>
            <Text size="sm" ml={4} color="text.secondary">
              {request?.created_at &&
                format(new Date(request.created_at), "MMMM dd, yyyy · h:mm aa")}
            </Text>
          </Column>
        </RouterLink>
      )}

      <RouterLink
        to={getSyncLink(id)}
        onClick={(event) => event.stopPropagation()}
      >
        <Row gap={1} flex={1} fontWeight="medium">
          <Text size="sm" color="text.secondary">
            Click to view sync details
          </Text>
          <ArrowRightIcon color="text.secondary" />
        </Row>
      </RouterLink>
    </Column>
  );
};
