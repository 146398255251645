import { useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";

import { useUser } from "src/contexts/user-context";
import { adminSwitchWorkspace, switchWorkspace } from "src/utils/workspaces";
import { topLevelRoutes } from "./constants";
import { PageSpinner } from "src/components/loading";
import { queryClient } from "src/lib/react-query";
import { useInitialQuery, useResourcesQuery } from "src/graphql";
import { fetcher } from "src/utils/fetcher";
import { useUpdateSentryTransactionNamesForWorkspaceRoutes } from "./use-sentry-tracking";

export const loader = async () => {
  // Note: for now we don't want this loader to await on the promise
  // We haven't fully implemented a good UI to use for route loaders,
  // and awaiting here would make some route transition seem slow/frozen in the app.
  // However, we still do want a loader fn so that can be called early in the app
  // initialization cycle, and we can parallelize these two potentially slow requests.
  void Promise.all([
    queryClient.fetchQuery({
      queryKey: useInitialQuery.getKey({}),
      queryFn: fetcher(
        useInitialQuery.document,
        {},
        {
          useFastEndpoint: true,
        },
      ),
    }),
    queryClient.fetchQuery({
      queryKey: useResourcesQuery.getKey({}),
      queryFn: fetcher(
        useResourcesQuery.document,
        {},
        {
          useFastEndpoint: true,
        },
      ),
    }),
  ]);
  return null;
};

export const Element = () => {
  const params = useParams<{
    root_workspace_slug: string;
  }>();
  const location = useLocation();
  const { slug } = useUser();

  const rootWorkspaceSlug = params.root_workspace_slug;
  // React Router stores the rest of the params in "*"
  // e.g. /destinations/new/foo -> params = { root_workspace_slug: "destinations", "*": "new/foo" }
  const otherParams = params["*"] ? `/${params["*"]}` : "";

  // Redirect without switchWorkspace
  if (slug && topLevelRoutes.includes(rootWorkspaceSlug!)) {
    return (
      <Navigate
        replace
        to={`/${slug}/${rootWorkspaceSlug}` + otherParams + location?.search}
      />
    );
  }

  useUpdateSentryTransactionNamesForWorkspaceRoutes();

  return <SlugRedirect />;
};

export const SlugRedirect = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { root_workspace_slug } = useParams<{ root_workspace_slug: string }>();
  const { user, slug, getWorkspaceIdFromSlug } = useUser();

  useEffect(() => {
    // Handle redirects when the root_workspace_slug is not the current workspace slug
    const redirect = async () => {
      // Redirect to top level routes with a workspace slug

      if (slug !== root_workspace_slug) {
        const workspaceId = getWorkspaceIdFromSlug(root_workspace_slug!);
        if (workspaceId) {
          const isSwitching = await switchWorkspace(
            workspaceId,
            `${window.location.pathname}${window.location.search}`,
          );
          if (!isSwitching) {
            // Failed to switch into this workspace slug
            window.location.href = "/";
          }
        } else if (user?.can_impersonate) {
          const isSwitching = await adminSwitchWorkspace(
            root_workspace_slug!,
            `${window.location.pathname}${window.location.search}`,
          );
          if (!isSwitching) {
            // Failed to switch into this workspace slug
            window.location.href = "/";
          }
        } else {
          // Failed to switch into this workspace slug
          window.location.href = "/";
        }
      }
      setIsLoading(false);
    };

    redirect();
  }, [root_workspace_slug, slug]);

  return isLoading ? <PageSpinner /> : <Outlet />;
};
