import { FC, useState } from "react";

import {
  useToast,
  Column,
  Row,
  MenuActionsButton,
  MenuList,
  Menu,
  DeleteIcon,
  Box,
  Alert,
} from "@hightouchio/ui";
import {
  Routes,
  Route,
  Navigate,
  useParams,
  Outlet,
  useNavigate,
  useOutletContext,
} from "src/router";

import { DetailPage } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import {
  PermissionedEditableHeading,
  PermissionedMenuItem,
} from "src/components/permission";
import { Warning } from "src/components/warning";
import {
  EventSourceDefinition,
  EventSourceType,
  eventSourceDefinitions,
} from "src/events/types";
import {
  EventSourceQuery,
  EventSourcesSetInput,
  QueueSourceQuery,
  useDeleteEventSourceMutation,
  useEventSourceQuery,
  useQueueSourceQuery,
  useUpdateEventSourceMutation,
} from "src/graphql";
import { PageSpinner } from "src/components/loading";
import { formatFriendlyDistanceToNow } from "src/utils/time";

import { Debugger } from "./debugger";
import { Setup } from "./setup";
import { Configuration } from "./configuration";
import { getEventSourceForm } from "./form";
import { Overview } from "./overview";
import { EventCatalog } from "./event-catalog";
import { ConfigureEvent } from "./configure-event";

const tabs = [
  { route: "overview", title: "Overview", element: <Overview /> },
  {
    route: "event-catalog",
    title: "Event catalog",
    element: <EventCatalog />,
    child: (
      <Route
        key="config"
        path="configure/:eventId"
        element={<ConfigureEvent />}
      />
    ),
  },
  { route: "debugger", title: "Debugger", element: <Debugger /> },
  { route: "setup", title: "Setup", element: <Setup /> },
  {
    route: "configuration",
    title: "Configuration",
    element: <Configuration />,
  },
];

export type OutletContext = {
  source: NonNullable<EventSourceQuery["event_sources_by_pk"]>;
  definition: EventSourceDefinition;
  queueSource: QueueSourceQuery["getQueueSource"];
};

export const EventSourceRouter: FC = () => {
  return (
    <Routes>
      <Route element={<Loader />}>
        <Route element={<Layout />}>
          {tabs.map((route) => (
            <Route
              key={route.route}
              path={`/${route.route}`}
              element={route.element}
            >
              {route.child}
            </Route>
          ))}
          <Route
            index
            element={
              <Navigate
                to={{
                  pathname: "overview",
                  search: location.search,
                }}
                replace
              />
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

const Loader: FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: source, isLoading } = useEventSourceQuery(
    { id: String(id) },
    { enabled: Boolean(id), select: (data) => data.event_sources_by_pk },
  );

  const definition = source
    ? eventSourceDefinitions[source.type as EventSourceType]
    : null;

  const { data: queueSource } = useQueueSourceQuery(
    { id: source?.id || "" },
    {
      enabled: definition?.isQueue,
      select: (data) => data.getQueueSource,
    },
  );

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!id) {
    return null;
  }

  if (!source) {
    return (
      <Warning
        subtitle="It may have been deleted"
        title="Event source not found"
      />
    );
  }

  if (!definition) {
    return (
      <Warning
        subtitle="Invalid event source"
        title="Event source definition not found"
      />
    );
  }

  return <Outlet context={{ source, definition, queueSource }} />;
};

const Layout: FC = () => {
  const { source, definition, queueSource } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);

  const hasConfigurationTab = !!getEventSourceForm(definition);

  const deleteMutation = useDeleteEventSourceMutation();
  const updateMutation = useUpdateEventSourceMutation();

  const update = async (input: EventSourcesSetInput) => {
    try {
      await updateMutation.mutateAsync({
        id: source.id,
        input,
      });

      toast({
        id: "update-event-source",
        title: `Event source was updated`,
        variant: "success",
      });
    } catch (_err) {
      toast({
        id: "update-event-source-error",
        title: `There was a problem updating your event source.`,
        variant: "error",
      });
    }
  };

  return (
    <DetailPage
      title={source.name}
      crumbs={[{ label: "All sources", link: "/events/sources" }]}
      tabDepth={5}
      hasBottomPadding
      tabs={[
        { path: "overview", title: "Overview" },
        {
          path: "event-catalog",
          title: "Event catalog",
        },
        { path: "debugger", title: "Debugger" },
        { path: "setup", title: "Setup" },
        {
          path: "configuration",
          title: "Configuration",
          isHidden: !hasConfigurationTab,
          bg: "base.lightBackground",
        },
      ]}
      header={
        <Column minWidth={0} gap={2}>
          <Row flex={1} justify="space-between" minWidth={0} pt={1}>
            <Row gap={4} align="center">
              <Box as="img" src={definition.icon} width="32px" />
              <PermissionedEditableHeading
                permission={{
                  v1: { resource: "workspace", grant: "update" },
                  v2: {
                    resource: "workspace",
                    grant: "can_update",
                  },
                }}
                size="lg"
                value={source.name ?? ""}
                onChange={(name) => {
                  update({ name });
                }}
              />
            </Row>

            <Row align="center" gap={4}>
              <Menu>
                <MenuActionsButton variant="secondary" />
                <MenuList>
                  <PermissionedMenuItem
                    permission={{
                      v1: { resource: "workspace", grant: "update" },
                      v2: {
                        resource: "workspace",
                        grant: "can_update",
                      },
                    }}
                    icon={DeleteIcon}
                    variant="danger"
                    onClick={() => {
                      setIsDeleting(true);
                    }}
                  >
                    Delete
                  </PermissionedMenuItem>
                </MenuList>
              </Menu>
            </Row>
          </Row>
        </Column>
      }
    >
      {queueSource?.error && (
        <Alert
          my={4}
          type="error"
          title="Source error"
          message={queueSource.error}
          contextMessage={
            queueSource.error_timestamp
              ? formatFriendlyDistanceToNow(queueSource.error_timestamp)
              : undefined
          }
        />
      )}

      <Outlet context={{ source, definition, queueSource }} />

      <DeleteConfirmationModal
        isOpen={isDeleting}
        label="source"
        onClose={() => {
          setIsDeleting(false);
        }}
        onDelete={async () => {
          await deleteMutation.mutateAsync({ id: source.id });
          navigate("/events/sources");
        }}
      />
    </DetailPage>
  );
};
