import { FC } from "react";
import times from "lodash/times";

import {
  Badge,
  Box,
  chakra,
  Column,
  ExternalLinkIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Row,
  SearchInput,
  Skeleton,
  SkeletonBox,
  SparkleIcon,
  Text,
  Tooltip,
} from "@hightouchio/ui";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import placeholderSource from "src/components/permission/source.svg";

import { OrderBy, useParentModelSelect } from "./use-parent-model-select";
import { Placeholder } from "src/ui/table/placeholder";
import { formatFriendlyDistanceToNow } from "src/utils/time";
import { Link } from "src/router";
import { TextWithTooltip } from "src/components/text-with-tooltip";

export type ParentModelOption = {
  id: string;
  name: string;
  description: string | null;
  created_at: string | null;
  updated_at: string | null;
  matchboosting_enabled: boolean | null;
  connection: {
    id: string;
    definition: {
      icon: string;
    };
  } | null;
};

interface Props {
  showAudienceRatio?: boolean;
  isOptionAvailable?: (id: string) => boolean;
  onSelect: (ParentModel: ParentModelOption) => void;
}

export const ParentModelSelect: FC<Readonly<Props>> = ({
  isOptionAvailable,
  onSelect,
  showAudienceRatio,
}) => {
  const {
    search,
    error,
    onSearch,
    options: parents,
    isLoading,
    orderBy,
    setOrderBy,
  } = useParentModelSelect({
    includeAudienceRatio: Boolean(showAudienceRatio),
  });

  const noParentModels = !isLoading && parents?.length === 0;

  return (
    <Column gap={4} m="0 auto" width="700px">
      <Row gap={2}>
        <Row flexGrow={1}>
          <SearchInput
            autoFocus
            placeholder="Search by name..."
            value={search}
            size="md"
            width="100%"
            onChange={(event) => onSearch(event.target.value)}
          />
        </Row>
        <Menu>
          <MenuButton>Sort by: {orderBy}</MenuButton>
          <MenuList>
            {showAudienceRatio && (
              <MenuItem onClick={() => setOrderBy(OrderBy.MostUsed)}>
                Most used
              </MenuItem>
            )}
            <MenuItem onClick={() => setOrderBy(OrderBy.NameAscending)}>
              {"A -> Z"}
            </MenuItem>
            <MenuItem onClick={() => setOrderBy(OrderBy.NameDescending)}>
              {"Z -> A"}
            </MenuItem>
            <MenuItem onClick={() => setOrderBy(OrderBy.RecentlyUpdated)}>
              Recently updated
            </MenuItem>
          </MenuList>
        </Menu>
      </Row>
      {(noParentModels || error) && (
        <Placeholder
          error={Boolean(error)}
          content={{
            title: `No parent models`,
            error: `Parent models failed to load, please try again.`,
            body:
              !error && !search ? (
                <Text>
                  Setup a parent model in your{" "}
                  <Link href="/schema-v2">schema</Link>
                </Text>
              ) : undefined,
          }}
        />
      )}

      {isLoading && (
        <Skeleton isLoading={isLoading}>
          <Column gap={2}>
            {times(4).map((n) => (
              <Column
                key={n}
                p={4}
                gap={2}
                border="1px solid"
                borderColor="base.border"
                borderRadius="md"
              >
                <Row justifyContent="space-between">
                  <Row alignItems="center" gap={2}>
                    <SkeletonBox
                      borderRadius="full"
                      width="24px"
                      height="24px"
                    />
                    <SkeletonBox width="200px" height="20px" />
                  </Row>
                  <SkeletonBox width="150px" height="20px" />
                </Row>
                <Column gap={2} ml={8}>
                  <SkeletonBox width="250px" height="20px" />
                  <SkeletonBox width="180px" height="20px" />
                </Column>
              </Column>
            ))}
          </Column>
        </Skeleton>
      )}

      <Column as="ul" gap={4} p={0}>
        {parents?.map((parent) => {
          const {
            id,
            connection,
            description,
            name,
            matchboosting_enabled,
            audience_ratio,
            created_at,
            updated_at,
          } = parent;

          const updatedAt = updated_at || created_at;
          const isDisabled = isOptionAvailable && !isOptionAvailable(id);
          const onClick = () => (isDisabled ? null : onSelect(parent));

          return (
            <Tooltip
              key={id}
              isDisabled={!isDisabled}
              message="You do not have permission to access this model"
            >
              <chakra.li listStyleType="none">
                <Column
                  as="button"
                  tabIndex={0}
                  borderRadius="md"
                  border="1px solid"
                  borderColor="base.border"
                  p={4}
                  cursor={isDisabled ? "auto" : "pointer"}
                  opacity={isDisabled ? 0.5 : 1}
                  _hover={{
                    background: "base.lightBackground",
                  }}
                  width="100%"
                  onClick={onClick}
                >
                  <Row
                    align="center"
                    gap={2}
                    justifyContent="space-between"
                    flex={1}
                    width="100%"
                  >
                    <Row align="center" gap={2} minWidth={0} maxWidth="430px">
                      <IntegrationIcon
                        name={connection?.definition?.name ?? "Private source"}
                        src={connection?.definition?.icon ?? placeholderSource}
                      />
                      <Row align="center" gap={1} minWidth={0}>
                        <Link
                          display="flex"
                          alignItems="center"
                          cursor="pointer"
                          isExternal
                          isTruncated
                          fontWeight="medium"
                          color="black"
                          href={`/schema-v2/view/query?id=${id}&source=${connection?.id}`}
                          _hover={{ "& > div": { color: "link.default" } }}
                          onClick={(e) => e.stopPropagation()} // Clicking the link should not trigger the container's onClick()
                        >
                          <TextWithTooltip color="inherit">
                            {name ?? "Private source"}
                          </TextWithTooltip>
                          <Row color="text.secondary" ml={1}>
                            <Box as={ExternalLinkIcon} h={4} w={4} />
                          </Row>
                        </Link>

                        {matchboosting_enabled && (
                          <Row ml={1}>
                            <Badge
                              size="md"
                              icon={SparkleIcon}
                              iconColor="warning.400"
                            >
                              Boosted
                            </Badge>
                          </Row>
                        )}
                      </Row>
                    </Row>

                    {showAudienceRatio && audience_ratio && (
                      <Row>
                        <Badge
                          variant={
                            audience_ratio.ratio >= 0.1 ? "success" : "subtle"
                          }
                        >
                          {formatAudienceRatio(audience_ratio.ratio)}
                        </Badge>
                      </Row>
                    )}
                  </Row>

                  <Column
                    alignItems="flex-start"
                    mt={2}
                    ml={8}
                    gap={2}
                    textAlign="left"
                  >
                    {description && <Text>{description}</Text>}
                    {updatedAt && (
                      <Text color="text.secondary">
                        Last updated{" "}
                        {formatFriendlyDistanceToNow(updatedAt).toLowerCase()}
                      </Text>
                    )}
                  </Column>
                </Column>
              </chakra.li>
            </Tooltip>
          );
        })}
      </Column>
    </Column>
  );
};

const PercentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
});

const formatAudienceRatio = (ratio: number): string => {
  if (ratio === 0) {
    return "No audiences use";
  }

  if (ratio * 100 < 1) {
    return "<1% of audiences use";
  }

  return `${PercentFormatter.format(ratio)} of audiences use`;
};
