import { useState } from "react";

import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useQueryClient } from "react-query";

import { useUser } from "src/contexts/user-context";
import {
  RunVisualBackgroundQueryVariables,
  useRunVisualBackgroundQuery,
  useVisualQueryBackgroundResultQuery,
} from "src/graphql";
import { VisualQueryFilter } from "src/types/visual";
import {
  Query,
  useLastUsedQuery,
} from "src/components/audiences/use-last-used-query";

interface UseCalculateAudienceSizeInput {
  audienceId: string | undefined;
  isFastQuery: boolean;
  parentModelId: string;
  sourceId: string;
  visualQueryFilter: VisualQueryFilter;
  subsetIds: string[] | undefined;
  destinationId: string | undefined;
}

export type UseCalculateAudienceSizeOutput = {
  audienceSize: { count: number; isEstimate: boolean } | null;
  audienceSizeUpdatedAt: number | null;
  isLoading: boolean;
  lastUsedQuery: Query | null;
  calculateAudienceSize: () => Promise<void>;
  cancelCalculateAudienceSize: () => Promise<void>;
  updateAudienceSize: (
    updatedSize: { count: number; isEstimate: boolean } | null,
    query?: Query,
  ) => void;
};

/**
 * A hook that can be used to run a count-only audience preview.
 *
 * It exposes functions to update stored audience size since breakdowns, overlaps
 * and previews in `useModelRun` can return an audience size.
 */
export const useCalculateAudienceSize = ({
  audienceId,
  parentModelId,
  sourceId,
  visualQueryFilter,
  subsetIds,
  isFastQuery,
  destinationId,
}: UseCalculateAudienceSizeInput): UseCalculateAudienceSizeOutput => {
  const { featureFlags } = useUser();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const [
    audienceSize,
    // The audience size should be set using the `updateAudienceSize ` wrapper functino
    // since we have a few callbacks that might need to be run when the audience size is recalculated
    _setAudienceSize,
  ] = useState<{
    count: number;
    isEstimate: boolean; // The associated count will be an estimate if fast query is used
  } | null>(null);
  const [lastUsedQuery, setLastUsedQuery] = useLastUsedQuery(null);

  const [audienceSizeUpdatedAt, setAudienceSizeUpdatedAt] = useState<
    number | null
  >(null);

  const updateAudienceSize = (
    updatedAudienceSize: { count: number; isEstimate: boolean } | null,
    query?: Query,
  ) => {
    // Anything can set the audience size. We need to track which filter was
    // run on the last audience size update.

    _setAudienceSize(updatedAudienceSize);
    if (query) {
      setLastUsedQuery(query);
    }

    setAudienceSizeUpdatedAt(Date.now());
  };

  const [backgroundJobId, setBackgroundJobId] = useState<string | null>(null);
  const [queryVariables, setQueryVariables] =
    useState<null | RunVisualBackgroundQueryVariables>(null);
  const [shouldPollForResults, setShouldPollForResults] = useState(false);

  const getUserErrorMessage = (errMessage: string): string => {
    if (errMessage == "Error: relationship does not exist") {
      return errMessage + ". The underlying model may have been changed";
    }

    return errMessage;
  };

  const runVisualBackgroundQuery = useRunVisualBackgroundQuery(
    {
      audienceId,
      filter: visualQueryFilter,
      subsetIds,
      parentModelId,
      sourceId,
      countOnly: true,
      useSampledModels: isFastQuery,
      destinationId,
    },
    {
      enabled: false,
      // Disable caching the query results.
      cacheTime: 0,
    },
  );

  useVisualQueryBackgroundResultQuery(
    {
      jobId: backgroundJobId ?? "",
      page: 0,
      filter: visualQueryFilter,
      parentModelId,
    },
    {
      enabled: Boolean(backgroundJobId) && Boolean(shouldPollForResults),
      refetchInterval: 500,
      // Disable caching the query results.
      cacheTime: 0,
      onError: (error) => {
        Sentry.captureException(error);
        setShouldPollForResults(false);
        setBackgroundJobId(null);

        toast({
          id: "calculate-size-query",
          title: "Size calculation failed",
          message: error.message,
          variant: "error",
        });
      },
      onSuccess: (data) => {
        if (!data?.visualQueryBackgroundResult) {
          return;
        }

        if (
          data.visualQueryBackgroundResult.__typename ===
          "SuccessfulQueryResponse"
        ) {
          updateAudienceSize(
            data.visualQueryBackgroundResult.numRowsWithoutLimit === null
              ? null
              : {
                  count: Number(
                    data.visualQueryBackgroundResult.numRowsWithoutLimit,
                  ),
                  isEstimate: Boolean(
                    data.visualQueryBackgroundResult.usedSampledModels,
                  ),
                },
            {
              filter: visualQueryFilter,
              subsetIds,
              isFastQuery,
              destinationId,
            },
          );
        } else {
          toast({
            id: "calculate-size-query",
            title: "Size calculation failed",
            message: getUserErrorMessage(
              data.visualQueryBackgroundResult.error,
            ),
            variant: "error",
          });
        }

        setShouldPollForResults(false);
        setBackgroundJobId(null);
      },
    },
  );

  const calculateAudienceSize = async () => {
    setShouldPollForResults(true);
    setQueryVariables({
      audienceId,
      filter: visualQueryFilter,
      parentModelId,
      sourceId,
      countOnly: true,
      // NOTE: the feature flag names on the right side need to match the names
      // in the database
      disableRowCounter: featureFlags?.sql_row_counter_disabled,
      subsetIds,
      useSampledModels: isFastQuery,
      destinationId,
    });

    try {
      const result = await runVisualBackgroundQuery.refetch();

      setLastUsedQuery({
        filter: visualQueryFilter,
        subsetIds,
        isFastQuery,
        destinationId,
      });
      setBackgroundJobId(result.data?.visualQueryBackground.jobId ?? null);
    } catch (error) {
      setShouldPollForResults(false);
      setBackgroundJobId(null);

      toast({
        id: "calculate-size-query",
        title: "Failed to calculate size",
        message: "Please try again",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const cancelQuery = async () => {
    if (queryVariables !== null) {
      const queryKey = useRunVisualBackgroundQuery.getKey(queryVariables);
      queryClient.cancelQueries([queryKey]);
      setShouldPollForResults(false);

      toast({
        id: "calculate-size-query",
        title: "Query cancelled",
        variant: "info",
      });
    }
  };

  return {
    audienceSize,
    audienceSizeUpdatedAt,
    isLoading: shouldPollForResults,
    lastUsedQuery,

    calculateAudienceSize,
    cancelCalculateAudienceSize: cancelQuery,
    updateAudienceSize,
  };
};
