import { getSyncRunError } from "src/utils/syncs";
import { GroupedPhaseData, SyncRequest } from "./types";
import { isNil } from "lodash";

// This function merges any sync request errors with any errors in parent spans from the grouped phase data
// The goal is to use this as a catch-all and show any errors that are not directly associated with a specific phase
export const mergeSyncRequestAndPhaseErrors = (
  syncRequest: Pick<SyncRequest, "error" | "sync_attempts">,
  phaseErrors: GroupedPhaseData["topLevelErrors"],
): { message: string }[] => {
  const syncRequestErrorInfo = getSyncRunError({
    syncRequest,
    attempt: syncRequest.sync_attempts[0],
  });

  const syncRequestError = syncRequestErrorInfo && {
    message:
      syncRequestErrorInfo?.userFacingMessage ||
      syncRequestErrorInfo?.message ||
      "We apologize for the inconvenience, but an unknown error has occurred. Please contact our customer support team for assistance.",
  };

  return [...phaseErrors, syncRequestError].filter((x) => !isNil(x));
};
