import { RouteObject } from "src/router";
import AudiencesLoader from "./audiences";
import { Audience } from "./audience";
import { CreateAudience } from "./create-audience";
import { CloneAudience } from "./clone-audience";
import { PermissionedRoute } from "src/components/router/permissioned-route";
import { FormErrorProvider } from "src/contexts/form-error-context";

export const routes: Array<RouteObject> = [
  { path: "audiences", element: <AudiencesLoader /> },
  { path: "audiences/:id", element: <Audience /> },
  {
    path: "audiences/new",
    element: (
      <PermissionedRoute
        permission={{
          v1: { resource: "audience", grant: "create" },
        }}
        redirect="/audiences"
      >
        <FormErrorProvider>
          <CreateAudience />
        </FormErrorProvider>
      </PermissionedRoute>
    ),
  },
  {
    path: "audiences/:id/clone",
    element: (
      <PermissionedRoute
        permission={{
          v1: { resource: "audience", grant: "create" },
        }}
        redirect="/audiences/:id"
      >
        <FormErrorProvider>
          <CloneAudience />
        </FormErrorProvider>
      </PermissionedRoute>
    ),
  },
  { path: "audiences/:id/*", element: <Audience /> },
];
