import * as Sentry from "@sentry/react";
import { isPresent } from "ts-extras";
import { v4 as uuidv4 } from "uuid";

import {
  AdHocAudience,
  AudienceFilter,
  ChartFormState,
  ConversionWindow,
  FunnelStep,
  GroupByColumn,
  isMetricSelection,
  FunnelMeasurementType,
  Metric,
  MetricSelection,
  ParentModel,
  TimeOptions,
  MeasurementScope,
  SelectedAudience,
  MeasuringSelection,
  MeasurementGroupOption,
  MeasuringMode,
} from "src/pages/analytics/types";
import {
  getEventIdFromMetricSelection,
  getMetricSelectionFromMetric,
  getTimeValueAndSelectedDates,
  mapMetricToMetricSelection,
  transformSavedChartFrequency,
  transformSavedChartGraphType,
  transformSavedChartTab,
} from "src/pages/analytics/utils";
import { Relationship } from "src/types/visual";
import { getModelIdFromColumn } from "src/components/explore/visual/utils";

import {
  flowSyntheticColumnValuesGetter,
  MinimalDecisionEngineFlowMessage,
} from "../decision-engine-utils";
import {
  DEFAULT_FUNNEL_CONVERSION_WINDOW,
  DEFAULT_GROUP_BY_COLUMNS,
  getDefaultFunnelSteps,
  NULL_OPTION_VALUE,
} from "./constants";

type GetChartStateFromChartDefinition = {
  name: string;
  description: string | null;
  frequency: string;
  chart_type: string;
  conversion_window: ConversionWindow | null;
  measuring_id: string | null;
  measuring_mode: string | null;
  cumulative: boolean | null;
  lookback_window:
    | {
        start: string;
        end: string;
      }
    | {
        lookback: TimeOptions | number;
      }
    | undefined;
  group_by: (GroupByColumn | undefined)[];
  parent_model_id: number;
  goals: {
    id: string;
    goal_definition: any | null;
    goal: {
      id: string;
      parent_model_id: string;
      name: string;
    } | null;
  }[];
  cohorts: {
    id: string;
    cohort_definition: any | null;
    cohort: {
      id: string;
      name: string;
    } | null;
  }[];
  funnel_stages: {
    id: string;
    funnel_stage_definition: any | null;
    relationship_id: any | null;
    event_model: {
      id: any;
      name: string;
    } | null;
  }[];
};

type ChartDependencies = {
  parentModel: ParentModel | null;
  metricOptions: Metric[];
  audienceOptions: SelectedAudience[];
  events: Relationship[];
  measurementOptions: MeasurementGroupOption[];
};

export const getChartStateFromChartDefinition = (
  chart: GetChartStateFromChartDefinition | null | undefined,
  dependencies: ChartDependencies,
): ChartFormState | undefined => {
  if (chart) {
    const {
      name,
      description,
      frequency,
      chart_type,
      lookback_window,
      group_by,
      parent_model_id,
      goals,
      cohorts,
      funnel_stages,
      conversion_window,
      cumulative,
      measuring_id,
      measuring_mode,
    } = chart;
    const metricSelection: MetricSelection[] = [];

    goals.forEach(({ goal, goal_definition }) => {
      if (goal) {
        const data = getMetricSelectionFromMetric({
          metricId: goal.id,
          events: dependencies.events,
          metrics: dependencies.metricOptions,
        });

        if (data) {
          metricSelection.push(data);
        }
      } else if (goal_definition) {
        let goalDefinition: MetricSelection | null = goal_definition;
        if (!goal_definition?.aggregationMethod) {
          goalDefinition = mapMetricToMetricSelection(
            goal_definition as Metric,
            dependencies.metricOptions,
            dependencies.parentModel?.filterable_audience_columns ?? [],
            dependencies.events,
          );
        }

        if (!goalDefinition || !isMetricSelection(goalDefinition)) {
          Sentry.captureException(
            "Saved goal definition is not of type MetricSelection",
          );
        }

        if (goalDefinition) {
          metricSelection.push(goalDefinition);
        }
      }
    });

    const selectedAudiences: SelectedAudience[] = [];
    cohorts.forEach(({ cohort, cohort_definition }) => {
      let audience: SelectedAudience | undefined;
      if (cohort?.id) {
        // The id comes back as a number, but graphql type labels it as string
        audience = dependencies.audienceOptions.find(
          ({ id }) => id === (cohort.id as unknown as number),
        );
      } else {
        // For named ad hoc filters
        const filter: AudienceFilter | undefined = cohort_definition?.filter;

        audience = {
          id: parent_model_id,
          name: cohort_definition?.name ?? dependencies.parentModel?.name,
          splits: [],
          filter,
        } as AdHocAudience;
      }

      if (audience) {
        selectedAudiences.push(audience);
      }
    });

    const { timeValue, selectedDates } =
      getTimeValueAndSelectedDates(lookback_window);

    const funnelSteps: FunnelStep[] = (funnel_stages ?? [])
      .map((stage) => {
        if (!stage.event_model || !stage.relationship_id) {
          Sentry.captureException(
            `Funnel stage ${stage.id}: event or relationship was deleted.`,
          );
          return null;
        }

        return {
          id: uuidv4(),
          eventModelId: stage.event_model.id,
          relationshipId: stage.relationship_id,
          subconditions: stage.funnel_stage_definition?.subconditions ?? [],
        };
      })
      .filter(isPresent);

    const graphType = transformSavedChartGraphType(chart_type);

    // Default should be the all data option
    let measuringSelection: MeasuringSelection | undefined = {
      id: NULL_OPTION_VALUE,
      scope: MeasurementScope.AllData,
    };

    if (measuring_id) {
      const measurementsOptions = dependencies.measurementOptions.flatMap(
        ({ options }) => options,
      );

      const foundMeasuringSelection = measurementsOptions.find(
        (m) => m.id === measuring_id,
      );

      if (!foundMeasuringSelection) {
        measuringSelection = undefined;
        Sentry.captureException(
          `Measuring selection ${measuring_id}: resource was deleted.`,
        );
      } else {
        measuringSelection = {
          id: measuring_id,
          scope: foundMeasuringSelection.scope,
        };
      }
    }

    const result: ChartFormState = {
      parentModelId: parent_model_id,
      name,
      description,
      chartTab: transformSavedChartTab(chart_type),
      graphType,
      groupByColumns: group_by ?? [],
      metricSelection,
      measuringSelection,
      measuringMode: measuring_mode as MeasuringMode,
      timeValue,
      funnelSteps:
        funnelSteps.length > 0 ? funnelSteps : getDefaultFunnelSteps(),
      selectedDates,
      rollupFrequency: transformSavedChartFrequency(frequency, graphType),
      selectedAudiences,
      funnelConversionWindow:
        conversion_window ?? DEFAULT_FUNNEL_CONVERSION_WINDOW,
      cumulative: cumulative ?? false,
      funnelMeasuringType: FunnelMeasurementType.TotalConversion,
    };

    return result;
  }

  return undefined;
};

/**
 * Filters out any group by columns that are not related to the parent model or the selected metrics/events
 */
export const getFilteredGroupByColumnsFromMetricSelection = ({
  parentModelId,
  selectedMetrics,
  metricOptions,
  selectedGroupByColumns,
}: {
  parentModelId: number | null;
  selectedMetrics: MetricSelection[];
  metricOptions: Metric[];
  selectedGroupByColumns: GroupByColumn[];
}) => {
  const modelIds = selectedMetrics
    .map((updatedMetric) =>
      getEventIdFromMetricSelection(updatedMetric, metricOptions)?.toString(),
    )
    .concat([parentModelId?.toString()])
    .filter(isPresent);

  const filteredGroupByColumns = selectedGroupByColumns.filter((column) => {
    const modelId = getModelIdFromColumn(column);
    return modelId && modelIds.includes(modelId);
  });

  return filteredGroupByColumns.length > 0
    ? filteredGroupByColumns
    : DEFAULT_GROUP_BY_COLUMNS;
};

/**
 * If available, grab a function to fetch values for a given synthetic column
 */
export const getSyntheticColumnValuesGetter = (
  measuringSelection: MeasuringSelection | undefined,
  measuringMetricResources: Record<string, unknown>,
) => {
  switch (measuringSelection?.scope) {
    case MeasurementScope.DecisionEngineFlow:
      return flowSyntheticColumnValuesGetter(
        measuringMetricResources.flowMessages as MinimalDecisionEngineFlowMessage[],
      );
    case MeasurementScope.AllData:
    default:
      return undefined;
  }
};
