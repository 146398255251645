import { FC } from "react";
import { Link } from "src/router";
import {
  MergedSyncRequestEventSpan,
  MergedSyncRequestEventStatus,
} from "src/graphql";

import {
  ChangeIcon,
  Column,
  ExternalLinkIcon,
  InformationIcon,
  PlusIcon,
  Row,
  SubtractIcon,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import {
  DestinationIcon,
  HightouchIcon,
  SourceIcon,
} from "src/pages/syncs/sync/run/summary/icons";
import { getSyncRunOperations } from "src/utils/syncs";
import { MultiValueProgressBar } from "src/pages/syncs/sync/run/summary/multi-value-progress-bar";

import { SyncRequest, type SyncRunPhase } from "./types";
import { NumericValue } from "./numeric-value";
import { map, pickBy } from "lodash";

type SyncBehavior = { add: boolean; change: boolean; remove: boolean };

enum PhaseGroup {
  Prepare = "Prepare",
  Sync = "Sync",
  Finalize = "Finalize",
}

export const PHASE_DISPLAY_CONFIG: Record<
  MergedSyncRequestEventSpan,
  {
    phaseGroupName: PhaseGroup;
    phaseDisplayName: string;
    PhaseHeaderIcon?: FC<{ phase: SyncRunPhase; syncRequest: SyncRequest }>;
    PhaseHeaderData?: FC<{ phase: SyncRunPhase; syncRequest: SyncRequest }>;
    PhaseHeaderAction?: FC<{ phase: SyncRunPhase; syncRequest: SyncRequest }>;
    PhaseAdditionalData?: FC<{
      phase: SyncRunPhase;
      syncRequest: SyncRequest;
    }>;
  } | null
> = {
  // These are not displayed in the UI (parent spans, or setup spans)
  [MergedSyncRequestEventSpan.Sync]: null,
  [MergedSyncRequestEventSpan.Prepare]: null,
  [MergedSyncRequestEventSpan.GeneratePlan]: null,
  [MergedSyncRequestEventSpan.Queue]: {
    phaseGroupName: PhaseGroup.Prepare,
    phaseDisplayName: "Queue",
  },
  [MergedSyncRequestEventSpan.PrepareQuery]: {
    phaseGroupName: PhaseGroup.Prepare,
    phaseDisplayName: "Setup",
    PhaseHeaderIcon: SourceIcon,
  },
  [MergedSyncRequestEventSpan.ExecuteQuery]: {
    phaseGroupName: PhaseGroup.Prepare,
    phaseDisplayName: "Query source",
    PhaseHeaderIcon: SourceIcon,
    PhaseHeaderData: ({ syncRequest }) => {
      const queryRun = syncRequest.query_run;
      if (!queryRun) return null;

      return <NumericValue value={queryRun.size} label="rows" />;
    },
  },
  [MergedSyncRequestEventSpan.Cdc]: {
    phaseGroupName: PhaseGroup.Prepare,
    phaseDisplayName: "Detect changes",
    PhaseHeaderIcon: SourceIcon,
    PhaseHeaderAction: ({ phase, syncRequest }) => {
      const source = syncRequest.sync?.segment?.connection;
      if (!source) return null;

      const phaseDurationIsLong = phase.durationMillis.self > 1000 * 60 * 10; // 10 minutes
      const lightningEnabled = source.plan_in_warehouse;

      if (phaseDurationIsLong && !lightningEnabled) {
        return (
          <Link href={`/sources/${source.id}`} fontSize="sm" isExternal>
            Taking a long time? Try Lightning Engine <ExternalLinkIcon />
          </Link>
        );
      }

      return null;
    },
    PhaseHeaderData: ({ syncRequest }) => {
      const diff = syncRequest.sync_request_diff;
      if (!diff) return null;

      const syncBehavior = syncRequest.sync_behavior as SyncBehavior | null;

      const ignoredOperations = map(
        pickBy(syncBehavior || {}, (value) => value === false),
        (_value, key) => `'${key}'`,
      );

      const listFormat = new Intl.ListFormat("en");
      const tooltipMessage = `This sync is configured to ignore ${listFormat.format(ignoredOperations)} operations.`;

      return (
        <Row gap={2} alignItems="center">
          <NumericValue value={diff.added_count} icon={PlusIcon} />
          <NumericValue value={diff.changed_count} icon={ChangeIcon} />
          <NumericValue value={diff.removed_count} icon={SubtractIcon} />
          {ignoredOperations.length > 0 && (
            <Row fontSize="lg" alignItems="center">
              <Tooltip message={tooltipMessage} placement="right">
                <InformationIcon color="text.secondary" />
              </Tooltip>
            </Row>
          )}
        </Row>
      );
    },
  },
  [MergedSyncRequestEventSpan.LoadEnrichmentData]: {
    phaseGroupName: PhaseGroup.Prepare,
    phaseDisplayName: "Enrich identifiers",
    PhaseHeaderIcon: HightouchIcon,
  },
  [MergedSyncRequestEventSpan.ExecuteDestination]: {
    phaseGroupName: PhaseGroup.Sync,
    phaseDisplayName: "Send data to the destination",
    PhaseHeaderIcon: DestinationIcon,
    PhaseAdditionalData: ({ phase, syncRequest }) => {
      const diff = syncRequest.sync_request_diff;
      const queryRun = syncRequest.query_run;

      if (!diff || !queryRun) return null;

      const attempt = syncRequest.sync_attempts[0];

      const {
        successful: { total: successful },
        rejected: { total: rejected },
      } = getSyncRunOperations({
        attempt,
        syncRequest,
        queryRun,
      });

      const syncBehavior = syncRequest.sync_behavior as SyncBehavior | null;

      const totalPlanned =
        syncRequest.planner_type === "all"
          ? queryRun.size
          : (syncBehavior?.add ? diff.added_count : 0) +
            (syncBehavior?.change ? diff.changed_count : 0) +
            (syncBehavior?.remove ? diff.removed_count : 0);

      const totalExecuted = successful + rejected;
      const remaining = totalPlanned - totalExecuted;

      return (
        <Column gap={1}>
          <MultiValueProgressBar
            values={[
              {
                value: (successful / totalPlanned) * 100,
                color: "success.base",
              },
              {
                value: (rejected / totalPlanned) * 100,
                color: "danger.base",
              },
            ]}
          />
          {totalPlanned === 0 ? (
            <NumericValue value={0} label="rows to send" />
          ) : (
            totalExecuted > 0 && (
              <Row justifyContent="space-between">
                <Row gap={1} alignItems="center">
                  <NumericValue
                    value={successful}
                    label="rows sent successfully"
                  />
                  <Link fontSize="sm" href="../successful">
                    (View)
                  </Link>
                  <Text size="sm" color="text.secondary">
                    ·
                  </Text>
                  <NumericValue value={rejected} label="rows rejected" />
                  <Link fontSize="sm" href="../rejected">
                    (View)
                  </Link>
                </Row>
                {phase.status === MergedSyncRequestEventStatus.Active ? (
                  <NumericValue value={remaining} label="rows remaining" />
                ) : null}
              </Row>
            )
          )}
        </Column>
      );
    },
  },
  [MergedSyncRequestEventSpan.Report]: {
    phaseGroupName: PhaseGroup.Finalize,
    phaseDisplayName: "Logs & reporting",
  },
};
