import { useFlags } from "launchdarkly-react-client-sdk";
import { isNil } from "lodash";
import { LinkButton, useOutletContext, useNavigate } from "src/router";
import {
  MergedSyncRequestEventSpan,
  MergedSyncRequestEventStatus,
  useSyncRunPhasesQuery,
} from "src/graphql";
import syncPlaceholder from "src/assets/placeholders/sync.svg";
import * as time from "src/utils/time";

import { Context } from "..";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Column,
  EmptyState,
  Row,
  Skeleton,
  SkeletonBox,
  Text,
} from "@hightouchio/ui";
import { Card } from "src/components/card";
import { SyncRunStatus, syncRunStatusOrUnknownStatus } from "src/utils/syncs";

import {
  EMPTY_SYNC_PHASE,
  GroupedPhaseData,
  isSyncPhase,
  phaseFailedWithErrors,
  SyncRequest,
  type SyncRunPhase,
} from "./types";
import { PhaseDisplay } from "./phase-display";
import { PhaseError } from "./phase-error";
import { RunStatusHeader } from "./run-status-header";
import { PHASE_DISPLAY_CONFIG } from "./phase-display-config";
import { newPylonMessage } from "src/lib/pylon";
import { mergeSyncRequestAndPhaseErrors } from "./utils";

const groupPhases = (phases: SyncRunPhase[]): GroupedPhaseData => {
  const syncPhase = phases.find(isSyncPhase) ?? EMPTY_SYNC_PHASE;

  // Find any errors in spans that we won't be displayed directly in the UI
  const topLevelErrors = phases
    .filter((phase) => PHASE_DISPLAY_CONFIG[phase.span] === null)
    .filter(phaseFailedWithErrors)
    .flatMap((phase) => phase.errors.filter((e) => !isNil(e)));

  const groups = phases.reduce<{
    [key: string]: { name: string; phases: SyncRunPhase[] };
  }>((acc, phase) => {
    const groupName = PHASE_DISPLAY_CONFIG[phase.span]?.phaseGroupName;
    if (!groupName) return acc;

    const phases = acc[groupName]?.phases ?? [];

    return {
      ...acc,
      [groupName]: {
        name: groupName,
        phases: [...phases, phase],
      },
    };
  }, {});

  return { syncPhase, topLevelErrors, groups: Object.values(groups) };
};

const groupHasProgress = ({ phases }: { phases: SyncRunPhase[] }) =>
  phases.some(phaseHasProgress);

const phaseHasProgress = (phase: SyncRunPhase) =>
  phase.status !== MergedSyncRequestEventStatus.NotStarted;

const formatTimestamp = (timestamp: number) =>
  time.formatDatetime(new Date(timestamp).toISOString(), "MMM d, yyyy 'at' p");

export const SyncRunSummary = () => {
  const { sync, syncRequest } = useOutletContext<Context>();
  const syncId = sync.id;
  const runId = syncRequest.id;

  const { appSyncRunSummaryPageEnabled } = useFlags();
  const navigate = useNavigate();

  const { data: groupedPhaseData, isLoading } = useSyncRunPhasesQuery(
    {
      syncId: syncId ?? "",
      syncRequestId: runId ?? "",
    },
    {
      select: ({ getSyncRequestEvents: phases }) => groupPhases(phases),
      refetchInterval: 5000,
    },
  );

  if (!appSyncRunSummaryPageEnabled) {
    navigate(`/syncs/${syncId}/runs/${runId}`);
  }

  const syncPhase = groupedPhaseData?.syncPhase;
  const syncHasProgress =
    syncPhase?.status !== MergedSyncRequestEventStatus.NotStarted;

  // If there are no phases with any progress, and the attempt is finished,
  // then the sync run was before we started collecting run phase data
  if (!isLoading && !syncHasProgress && syncRequest.finished_at) {
    return (
      <Column gap={6}>
        <RunStatusHeader syncRequest={syncRequest} />
        <EmptyState
          title="Run phase details not available"
          message="This sync run may be too old to display run phase information. Try selecting a more recent run."
          imageUrl={syncPlaceholder}
          actions={
            <LinkButton href={`/syncs/${syncId}/runs`}>
              View all runs
            </LinkButton>
          }
        />
      </Column>
    );
  }

  // Note: we must use sync request timestamps here. Spans are not collected until the sync run is started (after being queued)
  const startTime = new Date(syncRequest.created_at).getTime();
  const endTime = syncRequest.finished_at
    ? new Date(syncRequest.finished_at).getTime()
    : undefined;

  const elapsedMillis = (endTime ?? Date.now()) - startTime;

  const runStatus = syncRunStatusOrUnknownStatus(syncRequest.status_computed);

  // Show a warning if the sync run has been enqueued for more than 1 minute
  const showQueuedSyncAlert =
    runStatus === SyncRunStatus.ENQUEUED && elapsedMillis > 60_000;

  const topLevelErrors = groupedPhaseData
    ? mergeSyncRequestAndPhaseErrors(
        syncRequest,
        groupedPhaseData.topLevelErrors,
      )
    : [];

  const hasFailedGroupedPhases = groupedPhaseData?.groups.some((group) =>
    group.phases.some(phaseFailedWithErrors),
  );

  return (
    <Skeleton isLoading={isLoading}>
      <Column gap={6}>
        <Alert
          type="info"
          title="PRIVATE PREVIEW"
          message={
            <Column gap={2}>
              <Text>
                This is an early version of a feature that breaks down each sync
                run into individual phases. We’d love your feedback—what’s
                useful, what’s missing, and what could be clearer?
              </Text>
              <Text>
                Please note that the durations reported for “Query source” and
                “Detect changes” include time spent waiting for your warehouse
                to execute queries. If warehouse capacity is limited (such as
                when multiple syncs run concurrently), these queries may be
                queued, leading to longer sync times.
              </Text>
              <Text>
                Fair warning: This feature is in beta, so accuracy may vary.
                Reach out to support if you have any questions.
              </Text>
            </Column>
          }
        />
        <RunStatusHeader syncRequest={syncRequest} />
        {showQueuedSyncAlert && (
          <Alert
            type="subtle"
            title="This sync run is queued"
            message="This sync run will start once other syncs finish. You can increase your concurrency limits by contacting support, or adjust your sync schedules so they do not all run at the same time."
            actions={
              <ButtonGroup>
                <Button
                  onClick={() =>
                    newPylonMessage(
                      "I would like to increase the sync concurrency limits for my workspace.",
                    )
                  }
                >
                  Contact support
                </Button>
                <LinkButton href={`/syncs/${syncId}/schedule`}>
                  Edit sync schedule
                </LinkButton>
              </ButtonGroup>
            }
          />
        )}
        {/* Note: it's slightly unexpected to have parent phase errors that are not caught elsewhere,
        if we see a lot of these it probably means we need to add additional / more granular spans in the backend */}
        {topLevelErrors.length > 0 && !hasFailedGroupedPhases && (
          <PhaseError
            span={MergedSyncRequestEventSpan.Sync}
            errors={topLevelErrors}
            syncRequest={syncRequest}
            variant="card"
          />
        )}
        <Column gap={4} borderLeft="2px" borderColor="base.divider" pl={4}>
          <Text color="text.secondary">
            Triggered {formatTimestamp(startTime)}
          </Text>
          {isLoading && (
            <>
              <SkeletonBox height="100px" borderRadius="md" />
              <SkeletonBox height="100px" borderRadius="md" />
              <SkeletonBox height="100px" borderRadius="md" />
            </>
          )}
          {groupedPhaseData?.groups.map(({ name, phases }, index) => {
            const subsequentGroups = groupedPhaseData.groups.slice(index + 1);

            return (
              <SyncRunPhaseGroup
                key={name}
                name={name}
                phases={phases}
                syncRequest={syncRequest}
                subsequentPhasesInProgress={subsequentGroups.some(
                  groupHasProgress,
                )}
              />
            );
          })}
          {endTime && (
            <Text color="text.secondary">
              Attempt completed {formatTimestamp(endTime)}
            </Text>
          )}
        </Column>
      </Column>
    </Skeleton>
  );
};

type SyncRunPhaseGroupProps = {
  name: string;
  phases: SyncRunPhase[];
  syncRequest: SyncRequest;
  subsequentPhasesInProgress: boolean;
};

const SyncRunPhaseGroup = ({
  name,
  phases,
  syncRequest,
  subsequentPhasesInProgress,
}: SyncRunPhaseGroupProps) => {
  // TODO: we should show specific phases as "skipped" if they were not started but future phases have progress
  const phasesWithProgress = phases.filter(
    (phase) => phase.status !== MergedSyncRequestEventStatus.NotStarted,
  );

  return (
    <Card p={0}>
      <Column>
        <Row
          p={4}
          alignItems="center"
          justifyContent="space-between"
          _notLast={{ borderBottom: "1px", borderColor: "base.divider" }}
        >
          <Text
            fontWeight="medium"
            color={phases.length === 0 ? "text.secondary" : "inherit"}
          >
            {name}
          </Text>
          {phasesWithProgress.length === 0 && (
            <Text size="sm" color="text.tertiary">
              {subsequentPhasesInProgress ? "Skipped" : "Not started"}
            </Text>
          )}
        </Row>
        {phasesWithProgress.map((phase, index) => (
          <Box
            key={index}
            _notLast={{ borderBottom: "1px", borderColor: "base.divider" }}
          >
            <PhaseDisplay phase={phase} syncRequest={syncRequest} />
          </Box>
        ))}
      </Column>
    </Card>
  );
};
